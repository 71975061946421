import React, { useEffect, useState } from 'react';
import { App, Form, InputNumber } from 'antd';

export const Price = props => {
    const { notification } = App.useApp();
    const { formik, isDisabled } = props;
    const [value, setValue] = useState(formik?.values?.price || null);
    const [numberError, setNumberError] = useState(false);

    const onChange = (value: number) => {
        /* const priceToString = value.toString().length;
        if (priceToString > 6) {
            return notification.error({
                message: `Цена не может привышать ${priceToString}`,
            });
        }*/

        if (Number.isNaN(value)) {
            return;
        } else {
            setValue(value);
            formik.setFieldValue('price', Number(value));
        }
        if (value && !Number.isSafeInteger(value)) {
            setNumberError(true);
            setValue(Math.trunc(value));
            formik.setFieldValue('price', Number(Math.trunc(value)));
        }
    };

    useEffect(() => {
        if (value && numberError) {
            return notification.info({
                message: 'Значение поля price должно быть целым числом',
            });
        }
    }, [value, numberError]);

    const formatterNumber = val => {
        if (!val) return 0;
        return `${val}`
            .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
            .replace(/\.(?=\d{0,2}$)/g, ',');
    };

    const parserNumber = val => {
        if (!val) return 0;
        return Number.parseFloat(
            val.replace(/\$\s?|(\.*)/g, '').replace(/(\,{1})/g, '.')
        ).toFixed(2);
    };

    return (
        <Form layout={'vertical'}>
            <Form.Item label={'Цена, руб:'} style={{ marginBottom: 0 }}>
                <InputNumber
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    formatter={value => formatterNumber(value)}
                    parser={value => parserNumber(value)}
                    maxLength={8}
                    value={value}
                    style={{ width: props.width }}
                    controls={false}
                    placeholder={'Укажите цену в рублях'}
                    onChange={value => onChange(value)}
                    disabled={isDisabled}
                />
            </Form.Item>
        </Form>
    );
};
