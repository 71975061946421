import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

export const Container = (props: PropsWithChildren) => {
    const { children } = props;

    return <StyledContainer>{children}</StyledContainer>;
};

const StyledContainer = styled.div`
    display: flex;
    margin: 0 auto;
    max-width: 1200px;
    width: calc(100% - 1rem);

    @media (max-width: 992px) {
        display: flex;
        justify-content: space-between;
    }
`;
