import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import qs from 'query-string';

import getEnvConfig from 'utils/config/env';

import { IPostsResponse, TFilters } from './interfaces';

export const analyticsPostsApi = createApi({
    reducerPath: 'analyticsPosts',
    baseQuery: fetchBaseQuery({
        baseUrl: getEnvConfig().env.API_URL,
        prepareHeaders: headers => {
            const token = localStorage.getItem('accessToken');
            headers.set('Authorization', `Bearer ${token}`);
            return headers;
        },
    }),
    tagTypes: ['AnalyticsPosts'],
    endpoints: builder => ({
        readPosts: builder.query<IPostsResponse, TFilters>({
            query: args => {
                const queryArgs = qs.stringify(args, {
                    arrayFormat: 'bracket-separator',
                    skipNull: true,
                });
                return {
                    url: `/statistic/publications/?${queryArgs}`,
                    method: 'GET',
                };
            },
            transformResponse: (response: { data: IPostsResponse }) => {
                return response.data;
            },
            providesTags: ['AnalyticsPosts'],
        }),
    }),
});
export const { useLazyReadPostsQuery } = analyticsPostsApi;
