import { arrayEach } from 'utils/common/arrayEach';

export const arrayUnflatten = function (array, parent = undefined, tree = []) {
    if (parent && parent.id === parent.parent_id) return parent;
    parent = typeof parent !== 'undefined' ? parent : { id: 0 };

    const children = array.filter(child => child.parent_id == parent.id);

    if (children.length > 0) {
        if (parent.id == 0 || parent.id == null) {
            tree = children;
        } else {
            parent.childCategoryList = children;
        }
        arrayEach(children, function (child) {
            arrayUnflatten(array, child);
        });
    }

    return tree;
};
