import React, { useEffect, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Col, Image, Upload, UploadProps } from 'antd';
import ImgCrop from 'antd-img-crop';

import { getSrc } from '../../../../utils/common/getSrc';
import { ProductImage } from '../Product/ProductImage';

export const BannerImageUpload = props => {
    const { form, isEdit, values } = props;
    const [imageUrl, setImageUrl] = useState<string>(values?.image);

    useEffect(() => {
        if (values) {
            setImageUrl(values.image);
        }
    }, [values?.image]);

    const dummyRequest = async onSuccess => {
        setTimeout(() => {
            onSuccess('ok');
        }, 0);
    };

    const getBase64 = (img, callback: (url: string) => void) => {
        const reader = new FileReader();
        reader.addEventListener('load', () =>
            callback(reader.result as string)
        );
        reader.readAsDataURL(img);
    };

    const handleChange: UploadProps['onChange'] = info => {
        if (info.file.status === 'uploading') {
            return;
        }
        if (info.file.status === 'done') {
            getBase64(info.file.originFileObj, url => {
                setImageUrl(url);
            });
        }
    };

    return (
        <ImgCrop
            rotationSlider
            quality={0.75}
            aspect={3}
            showReset
            showGrid
            modalTitle={'Отредактируйте изображение'}
            modalOk={'Применить'}
            resetText={'Отменить изменения'}
        >
            <Upload
                name='avatar'
                maxCount={1}
                customRequest={file => {
                    void dummyRequest(file.onSuccess);
                }}
                beforeUpload={file => {
                    form.setFieldValue(`image`, file);
                }}
                onChange={handleChange}
                listType='picture-card'
                showUploadList={false}
            >
                {imageUrl ? (
                    <Col span={24}>
                        <Image
                            preview={false}
                            src={
                                isEdit && typeof values?.image !== 'object'
                                    ? getSrc(`/storage/${imageUrl}`)
                                    : imageUrl
                            }
                        />
                    </Col>
                ) : (
                    <div>
                        <PlusOutlined />
                        <div style={{ marginTop: 8 }}>Загрузить</div>
                    </div>
                )}
            </Upload>
        </ImgCrop>
    );
};
