import React, { useEffect } from 'react';
import { Skeleton, App, Tabs, Flex, Button } from 'antd';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

import { useToggle } from '../../../../hooks/useToggle';
import {
    useDeleteShopMutation,
    useReadShopByIdQuery,
} from '../../services/shops';
import getEnvConfig from '../../../../utils/config/env';
import { ShopEditContainer } from './ShopEditContainer';
import { ShopCard } from '../../views/Shops/ShopCard';
import { items } from '../../utils/tabs';
import Buyable from '../../components/Buyables';

export const ShopContainer = () => {
    const { notification } = App.useApp();
    const { id } = useParams();
    const navigate = useNavigate();

    const { open, setOpen } = useToggle(false);
    const toggle = () => setOpen((x: boolean) => !x);

    const [
        deleteShop,
        { isSuccess: isSuccessDelete, isLoading: isLoadingDelete },
    ] = useDeleteShopMutation();

    const {
        data: shop,
        isLoading,
        isSuccess,
    } = useReadShopByIdQuery(+id, {
        skip: isLoadingDelete || isSuccessDelete,
    });

    useEffect(() => {
        if (isSuccessDelete) {
            navigate(`${getEnvConfig().PUBLIC_PATH}/shops`);
            notification.success({
                message: 'Магазин успешно удален',
            });
        }
    }, [isSuccessDelete]);

    const onChange = (key: string) => {
        navigate(`${getEnvConfig().PUBLIC_PATH}/shops/${id}/${key}`);
    };

    const activeTabKey =
        items.find(t => location.pathname.includes(t.key))?.key || items[0].key;

    return (
        <Flex vertical gap={'1.5rem'}>
            <ShopEditContainer
                shopId={id}
                isSuccess={isSuccess}
                open={open}
                setOpen={setOpen}
            />
            {isLoading ? (
                <Skeleton />
            ) : (
                <ShopCard
                    source={shop}
                    loading={isLoading}
                    toggle={toggle}
                    deleteShop={deleteShop}
                />
            )}

            <Buyable {...shop} />

            <Tabs activeKey={activeTabKey} items={items} onChange={onChange} />

            <Outlet />
        </Flex>
    );
};
