import React, { FC } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Button, Col, Row, Space } from 'antd';

import Image54 from 'static/landing/image 54.png';
import StaticText from 'shared/layouts/LandingLayout/content.json';

import { Title } from '../ui/Title';
import { Container } from '../ui/Container';
import { Size, useWindowSize } from '../../../../hooks/useWindowSize';
import { ScreenProps } from '../types/landing.interface';

export const Heading: FC<ScreenProps> = ({ refProp }) => {
    const { size } = useWindowSize();

    return (
        <Container>
            <Row ref={refProp} id={'header'} style={{ padding: '4rem 0' }}>
                <Col xs={24} md={9}>
                    <Title level={1} text={StaticText.heading.title} />

                    <Space direction={'vertical'}>
                        <StyledList>
                            <ul style={{ listStyle: 'disc inside' }}>
                                {StaticText.heading.options.map(
                                    (option, index) => (
                                        <li key={index}>{option}</li>
                                    )
                                )}
                            </ul>
                        </StyledList>

                        <Space
                            style={{ margin: '1.5rem 0' }}
                            direction={'horizontal'}
                            size={'middle'}
                        >
                            <Link to={'app/auth/sign-up'}>
                                <Button type='primary'>
                                    {StaticText.heading.actions.buttonText1}
                                </Button>
                            </Link>
                            <Button
                                onClick={() =>
                                    refProp.current.scrollIntoView({
                                        block: 'center',
                                        inline: 'center',
                                    })
                                }
                            >
                                {StaticText.heading.actions.buttonText2}
                            </Button>
                        </Space>
                    </Space>
                </Col>

                <Col xs={24} md={8} push={size === Size.small ? 0 : 3}>
                    <StyledImage>
                        <img
                            width={'100%'}
                            src={Image54}
                            alt={
                                'Spodial - профессиональный инструмент комьюнити менеджера'
                            }
                        />
                    </StyledImage>
                </Col>
            </Row>
        </Container>
    );
};

const StyledList = styled.div`
    ul {
        list-style-position: inside;
        margin-block-start: 0;
        margin-block-end: 0;
        margin-inline-start: 0;
        margin-inline-end: 0;
        padding-inline-start: 0;
    }
`;

const StyledImage = styled(Space)`
    justify-content: flex-end;
    @media (max-width: 768px) {
        margin-top: 1rem;
    }
`;
