import React from 'react';
import { Flex, Typography } from 'antd';

const { Text, Link } = Typography;

export const BannerLink = ({ url }: { url: string }) => {
    return (
        <Flex
            vertical={false}
            align={'center'}
            gap={8}
            style={{ maxWidth: '100%' }}
        >
            {url && (
                <>
                    <Text style={{ minWidth: 'fit-content' }} strong>
                        Ссылка:
                    </Text>
                    <Link ellipsis>{url}</Link>
                </>
            )}
        </Flex>
    );
};
