import { saveAs } from 'file-saver';
import { notification } from 'antd';

import getEnvConfig from '../config/env';

export const downloadFile = async (
    url: string,
    fileType: string,
    params?: any
) => {
    try {
        const token = localStorage.getItem('accessToken');
        const _url = new URL(getEnvConfig().env.API_URL + url);
        if (params) {
            Object.keys(params).forEach(key =>
                _url.searchParams.append(key, params[key])
            );
        }
        const res = await fetch(_url, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        const body = await res.json();
        const downloadUrl =
            new URL(getEnvConfig().env.API_URL).origin + body.data.file_path;
        saveAs(downloadUrl);
    } catch {
        notification.error({
            message: 'Ошибка чтения файла',
            placement: 'top',
        });
    }
};
