import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, Flex, Typography } from 'antd';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { LegalForm } from '../../../user/components/LegalEntity/views/LegalForm';
import {
    useCreateLegalInfoMutation,
    useLazyReadLegalInfoQuery,
} from '../../../user/services/legalEntity';
import { usePaySubscriptionMutation } from '../../services';
import { LegalThanksContainer } from '../../../user/components/LegalEntity/containers/LegalThanksContainer';
import getEnvConfig from '../../../../utils/config/env';

export const LegalHandler = () => {
    const initial = {
        name: '',
        inn: '',
        kpp: '',
        email: '',
        phone: '',
        ogrn: '',
        address: '',
        additionally: '',
    };
    const navigate = useNavigate();

    const [createLegal, { isSuccess: isSuccessCreate }] =
        useCreateLegalInfoMutation();

    const [paySubscription, { isSuccess: isSuccessPay, data: payData }] =
        usePaySubscriptionMutation();

    const [getLegal, { data, isSuccess }] = useLazyReadLegalInfoQuery();
    const [checked, setChecked] = useState(false);
    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
        void getLegal(null, true);
    }, []);

    useEffect(() => {
        if (isSuccess && data) {
            formik.setValues({ ...data }).then(() => {
                setDisabled(true);
                setChecked(true);
            });
        }
    }, [isSuccess, data]);

    const getSuccessUrl = () => {
        const domain = getEnvConfig().env.API_URL;
        const isDev = domain.includes('dev');

        return isDev
            ? 'https://spodial-dev.fitdev.ru/pay/pending'
            : 'https://spodial.com/pay/pending';
    };

    const handleSave = async values => {
        const payload = {
            subscription_id: 3,
            payment_method: 1,
            success_url: getSuccessUrl(),
        };

        if (!data) {
            await createLegal({ ...values }).then(() =>
                paySubscription(payload)
            );
        } else {
            await paySubscription(payload);
        }
    };

    const handleCancel = () => {
        formik.setValues(initial);
        return navigate(-1);
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Введите название'),
        email: Yup.string().required('Введите почту'),
        inn: Yup.string().required('Должен быть указан действующий номер ИНН'),
        phone: Yup.string().required(
            'Должен быть действительный номер телефон'
        ),
        ogrn: Yup.string().required('Должен быть действительный ОГРН'),
        address: Yup.string().required('Должен быть действительный адрес'),
    });

    const formik = useFormik({
        initialValues: initial,
        onSubmit: handleSave,
        validateOnBlur: true,
        validationSchema: validationSchema,
    });

    const globalSuccess = isSuccessPay || isSuccessCreate;

    return (
        <>
            <Wrapper align={'center'} justify={'center'}>
                <StyledForm
                    style={{
                        width: globalSuccess ? 380 : 760,
                    }}
                    vertical
                    justify={'center'}
                    align={'flex-start'}
                    gap={32}
                >
                    {globalSuccess ? (
                        <LegalThanksContainer link={payData?.redirect} />
                    ) : (
                        <>
                            <Flex vertical gap={16}>
                                <Typography.Title
                                    style={{ margin: 0 }}
                                    level={4}
                                >
                                    Укажите сведения
                                </Typography.Title>

                                <LegalForm
                                    disabled={disabled}
                                    checked={checked}
                                    setChecked={setChecked}
                                    formik={formik}
                                />
                            </Flex>

                            <Flex
                                vertical={false}
                                style={{ width: '100%' }}
                                gap={55}
                            >
                                <Button block onClick={handleCancel}>
                                    Отмена
                                </Button>
                                <Button
                                    block
                                    type={'primary'}
                                    onClick={() => handleSave(formik.values)}
                                >
                                    Сохранить
                                </Button>
                            </Flex>
                        </>
                    )}
                </StyledForm>
            </Wrapper>
        </>
    );
};

const Wrapper = styled(Flex)`
    height: 100%;
    width: 100%;
`;

const StyledForm = styled(Flex)`
    padding: 24px;
    background: #ffffff;
    border-radius: 8px;
`;
