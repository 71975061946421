import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import qs from 'query-string';

import getEnvConfig from '../../../utils/config/env';
import { PaginatedResponse } from '../../communities/interfaces/PaginatedResponse';
import {
    CategoryPositionProps,
    IProducts,
} from '../interfaces/products.interface';
import {
    CreateCategoryItem,
    IProductCategory,
    IProductCategoryFilters,
} from '../interfaces/categories.interface';
import { IQueryCategory } from '../../communities/interfaces/category.interface';

export const productsCategoriesApi = createApi({
    reducerPath: 'productsCategories',
    baseQuery: fetchBaseQuery({
        baseUrl: getEnvConfig().env.API_URL,
        prepareHeaders: headers => {
            const token = localStorage.getItem('accessToken');
            headers.set('Authorization', `Bearer ${token}`);
            return headers;
        },
    }),
    tagTypes: ['ProductsCategories'],

    endpoints: builder => ({
        createProductCategory: builder.mutation<IProducts, any>({
            query: body => ({
                url: '/products/category',
                method: 'POST',
                body: body,
            }),
            transformResponse: (response: { data: IProducts }) => {
                return response.data;
            },
            invalidatesTags: ['ProductsCategories'],
        }),

        readProductCategory: builder.query<
            PaginatedResponse<IProductCategory>,
            IProductCategoryFilters
        >({
            query: params => {
                const queryArgs = qs.stringify(params, {
                    arrayFormat: 'bracket-separator',
                    skipNull: true,
                });
                return {
                    url: `/products/category?${queryArgs}`,
                };
            },
            transformResponse: (
                res: { data: Array<IProductCategory> },
                meta
            ) => {
                return {
                    items: res.data || [],
                    total:
                        Number(meta.response.headers.get('Items-Count')) || 0,
                };
            },
            providesTags: ['ProductsCategories'],
        }),

        updateProductCategory: builder.mutation<IQueryCategory, any>({
            query: ({ id, body }) => {
                return {
                    url: `/products/category/${id}`,
                    method: 'POST',
                    body: body,
                };
            },
            invalidatesTags: ['ProductsCategories'],
        }),

        deleteProductCategory: builder.mutation<void, number>({
            query: id => ({
                url: `/products/category/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['ProductsCategories'],
        }),

        updateProductCategoryPosition: builder.mutation<
            CategoryPositionProps,
            { shop_id: number; name: string; parent_id?: number }
        >({
            query: body => ({
                url: '/categories/update-positions',
                method: 'POST',
                body: body,
            }),
            invalidatesTags: ['ProductsCategories'],
        }),
    }),
});

export const {
    useCreateProductCategoryMutation,
    useLazyReadProductCategoryQuery,
    useReadProductCategoryQuery,
    useUpdateProductCategoryMutation,
    useDeleteProductCategoryMutation,
    useUpdateProductCategoryPositionMutation,
} = productsCategoriesApi;
