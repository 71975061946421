import { App, Button, Flex, Input } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import styled from 'styled-components';
import { LoadingOutlined } from '@ant-design/icons';

import { Page } from '../../../../shared/ui/Page';
import {
    useReadShopByIdQuery,
    useUpdateShopDetailsMutation,
} from '../../services/shops';
import { ShopDetailsType, ShopValues } from '../../interfaces/shop.interface';
import { getShopState } from '../../store/shopSlice';
import { IntegrationEmailExample } from '../../components/IntegrationEmail/IntegrationEmailExample';

const initialValues = {
    value: '',
};

export const IntegrationEmailEditContainer = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const {
        details: { integration_email },
    } = useSelector(getShopState);
    const { notification } = App.useApp();

    const { isLoading: isShopLoading } = useReadShopByIdQuery(+id);

    const handleSave = async values => {
        const payload = {
            type: ShopDetailsType.integrationEmail,
            ...values,
        };

        await updateShopDetails({
            id: +id,
            body: payload,
        });
    };

    const formik = useFormik({
        initialValues: initialValues,
        onSubmit: handleSave,
    });

    const [updateShopDetails, { isLoading, isSuccess, isError, error }] =
        useUpdateShopDetailsMutation();

    useEffect(() => {
        if (integration_email) {
            formik.setFieldValue('value', integration_email);
        }
    }, [integration_email]);

    useEffect(() => {
        if (isSuccess) {
            notification.success({
                message:
                    'Отправка оповещений на почту о заказах успешно настроена',
            });
        }
    }, [isSuccess]);

    useEffect(() => {
        if (isError) {
            const errors = Object?.values(error?.data?.errors);
            errors?.map((errorArray, index) =>
                errorArray?.map(error =>
                    notification.error({
                        message: error,
                    })
                )
            );
        }
    }, [isError]);

    const handleCancel = () => {
        navigate(-1);
    };

    const handleChange = (type: string, value: string) => {
        const text = value.length > 1 ? value : value.trim();
        formik.setFieldValue(type, text);
    };

    const disabled = formik?.values?.value === '';

    return (
        <Page title='Настройка рассылки заказов'>
            <Flex vertical gap={16}>
                <div>
                    <StyledLabel>Почта для получения заказов</StyledLabel>
                    <Input
                        placeholder={'qwe@qw.com'}
                        value={formik?.values?.value}
                        onChange={event => {
                            handleChange(`value`, event.target.value);
                        }}
                        addonBefore={
                            isShopLoading ? <LoadingOutlined /> : false
                        }
                    />
                </div>

                <IntegrationEmailExample />
                <Flex gap={24}>
                    <Button
                        disabled={disabled}
                        loading={isLoading}
                        type={'primary'}
                        onClick={() => handleSave(formik.values)}
                    >
                        Сохранить
                    </Button>
                    <Button onClick={handleCancel}>Отмена</Button>
                </Flex>
            </Flex>
        </Page>
    );
};

const StyledLabel = styled.label`
    display: block;
    margin-bottom: 0.5rem;
`;
