import React, { Dispatch, FC, Fragment, SetStateAction } from 'react';
import { Table } from 'antd';
import dayjs from 'dayjs';

import { ICommunity } from 'features/communities/interfaces';
import { IBasePaginationListQueryFilters } from 'features/admin/services/usersTab.service';

interface IProps {
    total: number;
    items: ICommunity[];
    setFilters: Dispatch<SetStateAction<IBasePaginationListQueryFilters>>;
}

export const CommunitiesTable: FC<IProps> = props => {
    const groupHashMap = {
        group: 'Сообщество',
        superGroup: 'Супергруппа',
    };

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            width: '66px',
            render: text => text,
        },
        {
            title: 'Название сообщества',
            dataIndex: 'title',
            key: 'id',
            width: '184px',
            ellipsis: true,
            render: text => text,
        },
        {
            title: 'Владелец',
            dataIndex: 'owner_name',
            key: 'id',
            width: '184px',
            ellipsis: true,
            render: text => text,
        },
        {
            title: 'Тип',
            dataIndex: 'telegram',
            key: 'id',
            width: '161px',
            ellipsis: true,
            render: text => groupHashMap[text] || text,
        },
        {
            title: 'Подключение',
            dataIndex: 'created_at',
            key: 'id',
            width: '184px',
            ellipsis: true,
            render: time => dayjs.unix(time).format('DD.MM.YY HH:mm'),
        },
        {
            title: 'Подписчиков',
            dataIndex: 'followers',
            key: 'id',
            width: '161px',
            ellipsis: true,
            align: 'right',
            render: text => text || 0,
        },
        {
            title: 'Сумма поступлений',
            dataIndex: 'balance',
            key: 'id',
            width: '184px',
            ellipsis: true,
            align: 'right',
            render: text => text || 0,
        },
    ];

    const pagination = {
        total: props.total,
        showTotal: () => `Сообществ: ${props.total}`,
        onChange: (page, pageSize) => {
            props.setFilters({
                offset: (page - 1) * pageSize,
                limit: pageSize,
            });
        },
        showQuickJumper: false,
        showSizeChanger: false,
    };

    return (
        <Fragment>
            <Table
                columns={columns}
                rowKey={community => community.id}
                dataSource={props.items}
                pagination={pagination}
            />
        </Fragment>
    );
};
