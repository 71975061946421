import { FeedTypes } from '../interfaces/shop.interface';

export const initial = {
    feed_frequency: 24,
    feed_url: undefined,
    is_active: true,
    type: undefined,
};

export const messages = {
    pageTitle: 'Настройка фида',
    save: 'Сохранить',
    cancel: 'Отмена',
    hint: 'Система принимает фиды в формате yml.',
};
