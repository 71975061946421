import { useNavigate, useParams } from 'react-router-dom';
import { App, Form } from 'antd';
import React, { useEffect, useState } from 'react';

import { BannerForm } from '../../views/Banners/BannerForm';
import { useCreateBannerMutation } from '../../services/banners';
import { createFormData } from '../../../../utils/common/createFormData';
import { ManageTitle } from '../../views/Banners/ManageTitle';
import { ManageControls } from '../../views/Banners/ManageControls';

export const BannerCreateContainer = () => {
    const { id } = useParams();
    const { notification } = App.useApp();
    const navigate = useNavigate();

    const [form] = Form.useForm();
    const values = Form.useWatch([], form);
    const [isSubmittable, setIsSubmittable] = useState<boolean>(false);

    const [createBanner, { isSuccess, isError, error }] =
        useCreateBannerMutation();

    useEffect(() => {
        form.validateFields({ validateOnly: true })
            .then(() => setIsSubmittable(true))
            .catch(() => setIsSubmittable(false));
    }, [form, values]);

    useEffect(() => {
        if (isSuccess) {
            notification.success({
                message: 'Баннер успешно создан',
            });
            navigate(-1);
        }
    }, [isSuccess]);

    useEffect(() => {
        if (isError) {
            const errors = Object?.values(error?.data?.errors);
            errors?.map((errorArray, index) =>
                errorArray?.map(error =>
                    notification.error({
                        message: error,
                    })
                )
            );
        }
    }, [isError]);

    const handleSave = async () => {
        const payload = {
            id: +id,
            ...values,
        };

        if (values.url === undefined) {
            delete payload.url;
        }

        await createBanner({
            id: +id,
            body: createFormData(payload),
        });
    };

    return (
        <>
            <ManageTitle id={null} />
            <BannerForm form={form} values={values} />
            <ManageControls isSubmittable={isSubmittable} onSave={handleSave} />
        </>
    );
};
