import React, { Dispatch, memo, SetStateAction } from 'react';
import { Button, Flex, Input } from 'antd';
import {
    useNavigate,
    useLocation,
    matchRoutes,
    useMatch,
} from 'react-router-dom';

import Shops from 'features/shops';

import getEnvConfig from '../../../../utils/config/env';
import { ProductsFilters } from '../../interfaces/products.interface';
import { TreeCategoryFilter } from '../Categories/TreeCategoryFilter';

type ProductTabs = {
    loading: boolean;
    shopId: number;
    filters: ProductsFilters;
    setFilters: Dispatch<SetStateAction<ProductsFilters>>;
};
export const ProductTabs = memo((props: ProductTabs) => {
    const navigate = useNavigate();
    const location = useLocation();
    // const [{ route }] = matchRoutes(Shops.routes, location);
    const isLinkTab = /\/links$/.test(location.pathname);

    const { loading, shopId, filters, setFilters } = props;

    const handleCreateProduct = () => {
        navigate(
            `${getEnvConfig().PUBLIC_PATH}/shops/${+shopId}/product-create`
        );
    };

    const handleCreateLink = () => {
        navigate(`${getEnvConfig().PUBLIC_PATH}/shops/${+shopId}/link-create`);
    };

    return (
        <Flex justify={'space-between'} wrap={'wrap'} gap={20}>
            <Flex vertical={false} gap={12}>
                <Button
                    disabled={loading}
                    type={'primary'}
                    onClick={isLinkTab ? handleCreateLink : handleCreateProduct}
                >
                    {isLinkTab ? 'Добавить витрину' : ' Добавить товар'}
                </Button>

                <div>
                    <Input
                        allowClear
                        onChange={e =>
                            setFilters({
                                ...filters,
                                title: e.target.value,
                            })
                        }
                        placeholder={'Поиск товаров'}
                    />
                </div>
            </Flex>

            <TreeCategoryFilter
                filters={filters}
                setFilters={setFilters}
                shopId={shopId}
                type={'page'}
            />
        </Flex>
    );
});
