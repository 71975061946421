import React, {
    Fragment,
    useEffect,
    useState,
    useRef,
    Dispatch,
    SetStateAction,
    useMemo,
} from 'react';
import styled from 'styled-components';
import { App, Button, Divider, Input, Select, Space, TreeSelect } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import type { InputRef } from 'antd';
import { useParams } from 'react-router-dom';
import { FormikProps } from 'formik';
import { useSelector } from 'react-redux';

import {
    useReadProductCategoryQuery,
    useCreateProductCategoryMutation,
    useLazyReadProductCategoryQuery,
} from '../../services/categories';
import { useToggle } from '../../../../hooks/useToggle';
import { IProducts } from '../../interfaces/products.interface';
import { Uncategorized } from './categoryFilters';
import { getCategoryState } from '../../store/categorySlice';
import { deepGetObjectById } from '../../../../utils/common/deepGetObjectById';

type TreeCategoryFilterProps = {
    shopId: number;
    filters?: any;
    setFilters?: Dispatch<SetStateAction<any>>;
    width?: number;
    formik?: FormikProps<IProducts>;
    type?: string;
    isDisabled: boolean;
};
export const TreeCategoryFilter: React.FC<TreeCategoryFilterProps> = props => {
    const { shopId, isDisabled } = props;
    const { open, setOpen } = useToggle();

    const [active, setActive] = useState<number | 'null'>(
        props.type === 'page' ? 'null' : 0
    );

    const { nestedCategoryList } = useSelector(getCategoryState);
    const dropdownItems = useMemo(() => {
        if (props.type === 'page') {
            return nestedCategoryList.map(category => {
                if (category.id === null) {
                    return {
                        ...category,
                        id: 'null',
                    };
                }
                return category;
            });
        } else {
            return nestedCategoryList.filter(category => category.id !== null);
        }
    }, [nestedCategoryList, props.type]);

    const [getCategories, { isLoading: isGetCategoriesLoading }] =
        useLazyReadProductCategoryQuery();
    useEffect(() => {
        if (shopId) {
            getCategories({ shop_id: +shopId });
        }
    }, [shopId]);

    useEffect(() => {
        setOpen(false);

        if (props?.formik && nestedCategoryList) {
            const active = nestedCategoryList.filter(
                elem => elem.id === props.formik.values.category_id
            );
            setActive(props.formik.values.category_id);
        }
    }, [nestedCategoryList, props?.formik]);

    const onChange = (newValueId: number | 'null') => {
        props.filters
            ? props.setFilters({
                  ...props.filters,
                  category_id: newValueId === 'null' ? null : newValueId,
              })
            : props.formik.setFieldValue('category_id', newValueId);
        setActive(newValueId);
    };

    return (
        <Fragment>
            <TreeSelect
                style={{ width: props?.width || 300 }}
                treeData={dropdownItems}
                fieldNames={{
                    label: 'name',
                    value: 'id',
                    children: 'childCategoryList',
                }}
                onDropdownVisibleChange={() => setOpen(!open)}
                open={open}
                // showSearch
                onChange={onChange}
                defaultValue={props.type === 'page' ? 'null' : 0}
                value={active}
                disabled={isDisabled}
            />
        </Fragment>
    );
};

const StyledSelect = styled(Select)`
    max-width: 300px;
`;
