import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import qs from 'query-string';

import getEnvConfig from 'utils/config/env';

import { IWebinarsResponse, TFilters } from './interfaces';

export const analyticsWebinarsApi = createApi({
    reducerPath: 'analyticsWebinars',
    baseQuery: fetchBaseQuery({
        baseUrl: getEnvConfig().env.API_URL,
        prepareHeaders: headers => {
            const token = localStorage.getItem('accessToken');
            headers.set('Authorization', `Bearer ${token}`);
            return headers;
        },
    }),
    tagTypes: ['AnalyticsWebinars'],
    endpoints: builder => ({
        readWebinars: builder.query<IWebinarsResponse, TFilters>({
            query: args => {
                const queryArgs = qs.stringify(args, {
                    arrayFormat: 'bracket-separator',
                    skipNull: true,
                });
                return {
                    url: `/webinars/analytic?${queryArgs}`,
                    method: 'GET',
                };
            },
            transformResponse: (response: { data: IWebinarsResponse }) => {
                return response.data;
            },
            providesTags: ['AnalyticsWebinars'],
        }),
    }),
});
export const { useLazyReadWebinarsQuery } = analyticsWebinarsApi;
