import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

import getEnvConfig from 'utils/config/env';

export const tariffService = createApi({
    reducerPath: 'tariff',
    baseQuery: fetchBaseQuery({
        baseUrl: getEnvConfig().env.API_URL,
        prepareHeaders: headers => {
            const token = localStorage.getItem('accessToken');
            headers.set('Authorization', `Bearer ${token}`);
            return headers;
        },
    }),
    tagTypes: ['tariffList', 'readTariff'],
    endpoints: build => ({
        create: build.mutation<any, any>({
            query: body => ({
                method: 'POST',
                url: 'tariff',
                body,
            }),
            invalidatesTags: ['tariffList'],
        }),
        payTariff: build.mutation<any, any>({
            query: body => ({
                method: 'POST',
                url: 'pay/tariff',
                body,
            }),
            transformResponse: res => res.data,
        }),
        deleteById: build.mutation<any, any>({
            query: id => ({
                method: 'DELETE',
                url: `tariff/${id}`,
            }),
            invalidatesTags: ['tariffList'],
        }),
        update: build.mutation<any, any>({
            query: params => ({
                method: 'PUT',
                url: `tariff/${params.id}`,
                body: params.body,
            }),
            invalidatesTags: ['tariffList', 'readTariff'],
        }),
        getList: build.query({
            query: params => ({
                method: 'GET',
                url: 'tariffs',
                params,
            }),
            providesTags: ['tariffList'],
            transformResponse: (res: any, meta) => {
                return {
                    list: res?.data || [],
                    total:
                        Number(meta.response.headers.get('Items-Count')) || 0,
                };
            },
        }),
        getByID: build.query<any, any>({
            query: params => ({
                method: 'GET',
                url: `show/tariff`,
                params,
            }),
            providesTags: ['readTariff'],
            transformResponse: res => res.data,
        }),
        tariffPayed: build.query<any, any>({
            query: params => ({
                method: 'GET',
                url: `show/tariff_payed`,
                params,
            }),
            transformResponse: res => res.data,
        }),
    }),
});

export const {
    useCreateMutation,
    useUpdateMutation,
    useDeleteByIdMutation,
    useGetByIDQuery,
    useGetListQuery,
    usePayTariffMutation,
    useTariffPayedQuery,
} = tariffService;
