import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import qs from 'query-string';

import getEnvConfig from 'utils/config/env';

export const recommendationApi = createApi({
    reducerPath: 'recommendation',
    baseQuery: fetchBaseQuery({
        baseUrl: getEnvConfig().env.API_URL,
        prepareHeaders: headers => {
            const token = localStorage.getItem('accessToken');
            headers.set('Authorization', `Bearer ${token}`);
            return headers;
        },
    }),
    tagTypes: ['recommendation'],
    endpoints: builder => ({
        readRecommendation: builder.query({
            query: args => {
                const queryArgs = qs.stringify(args, {
                    arrayFormat: 'bracket-separator',
                    skipNull: true,
                });
                return {
                    url: `/lms_recommendation?${queryArgs}`,
                    method: 'GET',
                };
            },
            transformResponse: (response: { data }) => {
                return response.data;
            },
            providesTags: ['recommendation'],
        }),
        readPublicationList: builder.query({
            query: () => {
                return {
                    url: `/publication_and_webinar_list`,
                    method: 'GET',
                };
            },
            transformResponse: (response: { data }) => {
                return response.data;
            },
            providesTags: ['recommendation'],
        }),
    }),
});
export const { useReadRecommendationQuery, useReadPublicationListQuery } =
    recommendationApi;
