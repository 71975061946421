import { Dispatch, SetStateAction } from 'react';
import { FormikProps } from 'formik';
import { FormInstance } from 'antd';

import { ProductProps } from './products.interface';

export interface Shop {
    name?: string | null;
    limit: number;
    offset: number;
}

export interface IShop extends ShopValues {
    readonly id?: number;
    shop_inline?: string;
    shop_link?: string;
    product?: Array<ProductProps>;
    product_id?: number;
}

export interface ShopValues extends ShopKeys {
    readonly shop_id?: number;
    readonly name: string;
    readonly about: string;
    readonly buyable: boolean;
    readonly photo: string | File | null;
    readonly price_from: boolean;
}

export interface ShopKeys {
    project_id?: number;
    secretKey?: string;
}

export enum ShopOrderType {
    pay = 'Покупка',
    preorder = 'Предзаказ',
}

export interface ShopForm {
    readonly open: boolean;
    readonly setOpen: Dispatch<SetStateAction<boolean>>;
    readonly title: string;
    readonly initialValues: ShopValues;
    readonly onSave: ({ ...IShopCreate }) => void;
    readonly isSuccess?: boolean;
    readonly isEdit?: boolean;
    readonly loadingReadKeys?: boolean;
    readonly shopKeys?: ShopKeys;
    readonly successReadKeys?: boolean;
    readonly formik?: FormikProps<ShopValues>;
}

export enum FeedTypes {
    yml = 1,
    csvGoogleMerchant = 2,
}

export interface FeedProps {
    readonly id?: number;
    readonly feed_url: string;
    readonly feed_frequency: number;
    readonly is_active: boolean;
    readonly type: FeedTypes;
}

export enum ShopDetailsType {
    contactInfo = 3,
    config = 4,
    integrationEmail = 5,
}

export interface ShopDetailsProps {
    readonly id?: number;
    readonly body: {
        type: ShopDetailsType;
        value: string;
    };
}

export interface FeedResponseProps {
    readonly frequency: number;
    readonly is_active: boolean;
    readonly next_updated_at: Date;
    readonly shop_id: number;
    readonly url: string;
}

export interface FeedFormProps {
    readonly form: FormInstance<FeedProps>;
    readonly initial: FeedProps;
}

export interface ShopState
    extends Pick<
        ShopValues,
        'name' | 'about' | 'buyable' | 'photo' | 'price_from'
    > {
    id: number | null;
    user_id?: number | null;
    shop_inline: string;
    shop_link: string;
    payment_system?: string | null;
    details: {
        config: string;
        integration_email: string;
        policy?: string | null;
        offer?: string | null;
    };
}
