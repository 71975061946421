import React, { FC } from 'react';
import styled from 'styled-components';
import { Col, Row } from 'antd';

import StaticText from 'shared/layouts/LandingLayout/content.json';

import { SubTitle } from '../ui/Title';
import { Text } from '../ui/Text';
import { Container } from '../ui/Container';
import { ScreenProps } from '../types/landing.interface';

export const Intro: FC<ScreenProps> = ({ refProp }) => {
    return (
        <Container>
            <StyledSectionSecond>
                <Row ref={refProp}>
                    <Col md={12}>
                        <SubTitle text={StaticText.intro.title} level={4} />
                        <SectionSecondSubTitle>
                            {StaticText.intro.subTitle}
                        </SectionSecondSubTitle>
                    </Col>

                    <Col md={12}>
                        <Text color={'white'}>
                            {StaticText.intro.description}
                        </Text>
                    </Col>
                </Row>
            </StyledSectionSecond>
        </Container>
    );
};

const SectionSecondSubTitle = styled.div`
    font-family: 'Unbounded', sans-serif;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 300;
    line-height: normal;

    color: #ffffff;
    text-align: start;

    @media (max-width: 1400px) {
        font-size: 16px;
    }

    @media (max-width: 576px) {
        margin-bottom: 0;
    }
`;

const StyledSectionSecond = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: #6257d2;
    border-radius: 40px;
    padding: 64px;
    margin-bottom: 64px;

    @media (max-width: 1400px) {
        padding: 64px;
        margin-bottom: 64px;
    }

    @media (max-width: 992px) {
        padding: 40px;
        margin-bottom: 32px;
    }

    @media (max-width: 768px) {
        padding: 24px;
    }

    @media (max-width: 576px) {
        width: auto;
        flex-direction: column;
        gap: 24px;
        padding: 16px;
        margin-left: -16px;
        margin-right: -16px;
        border-radius: 0;
    }
`;
