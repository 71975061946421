import React, { FC, Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { App, Button, Input, Typography } from 'antd';

import {
    useAnswerFeedbackMutation,
    useGetFeedbackByIdQuery,
} from '../../services/feedbackTab.service';

export const AnswerPage: FC = () => {
    const { notification } = App.useApp();
    const navigate = useNavigate();
    const params = useParams();
    const { data: feedback } = useGetFeedbackByIdQuery(params.id);
    const [answerFeedback, { isSuccess, error }] = useAnswerFeedbackMutation();
    const [message, setMessage] = useState('');

    useEffect(() => {
        if (isSuccess) {
            notification.success({
                message: 'Успешно',
                placement: 'top',
            });
            navigate(-1);
        }
    }, [isSuccess]);

    useEffect(() => {
        if (error) {
            notification.error({
                message: (error as any).data.message || 'Что-то пошло не так',
                placement: 'top',
            });
        }
    }, [error]);

    if (!feedback) {
        return <></>;
    }

    return (
        <Fragment>
            <StyledWrap>
                <StyledHeader>
                    <ButtonBack onClick={() => navigate(-1)}>
                        <ArrowLeftOutlined />
                    </ButtonBack>
                    <div style={{ marginLeft: 17 }}>
                        <StyledTitle level={4}>Вопрос клиента</StyledTitle>
                    </div>
                </StyledHeader>
                <StyledContent>
                    <StyledBlock>
                        <StyledTitle level={5}>ID обращения:</StyledTitle>
                        <div>
                            <Typography.Text>{feedback.id}</Typography.Text>
                        </div>
                    </StyledBlock>

                    <StyledBlock>
                        <StyledTitle level={5}>Имя участника:</StyledTitle>
                        <div>
                            <Typography.Text>{feedback.name}</Typography.Text>
                        </div>
                    </StyledBlock>

                    <StyledBlock>
                        <StyledTitle level={5}>Статус обращения:</StyledTitle>
                        <div>
                            <Typography.Text>{feedback.status}</Typography.Text>
                        </div>
                    </StyledBlock>

                    <StyledBlock>
                        <StyledTitle level={5}>Текст обращения:</StyledTitle>
                        <div>
                            <Typography.Text>{feedback.text}</Typography.Text>
                        </div>
                    </StyledBlock>

                    {feedback.status !== 'Закрыт' && (
                        <StyledBlock>
                            <StyledTitle level={5}>Ваш ответ:</StyledTitle>
                            <div>
                                {feedback.status === 'Отвечен' && (
                                    <Typography.Text>
                                        {feedback.answer}
                                    </Typography.Text>
                                )}
                                {feedback.status === 'Новый' && (
                                    <Input.TextArea
                                        rows={4}
                                        value={message}
                                        onChange={event =>
                                            setMessage(event.target.value)
                                        }
                                    />
                                )}
                            </div>
                        </StyledBlock>
                    )}

                    {feedback.status === 'Новый' && (
                        <StyledActions>
                            <Button
                                type='primary'
                                onClick={() => {
                                    answerFeedback({
                                        id: params.id,
                                        message,
                                    });
                                }}
                            >
                                Ответить
                            </Button>
                            <Button type='default' onClick={() => navigate(-1)}>
                                Назад
                            </Button>
                        </StyledActions>
                    )}
                </StyledContent>
            </StyledWrap>
        </Fragment>
    );
};

const StyledTitle = styled(Typography.Title)<{ contentEditable?: boolean }>`
    margin-block-start: 0;
    margin-block-end: 0;
    margin-bottom: 0 !important;
`;

const StyledActions = styled.div`
    display: flex;
    gap: 24px;
`;

const StyledBlock = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

const StyledContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

const StyledHeader = styled.div`
    display: flex;
    margin-bottom: 24px;
`;

const ButtonBack = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 17px;
    cursor: pointer;
`;

const StyledWrap = styled.div`
    margin-top: 60px;
    width: 686px;
`;
