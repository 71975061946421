import React, { useEffect, useState } from 'react';
import { App, InputNumber } from 'antd';
import styled from 'styled-components';

import { GenericModal } from '../../../../../shared/ui/GenericModal';
import { useUpdateCommissionMutation } from '../../../services/usersTab.service';

export const CommissionModal = props => {
    const { notification } = App.useApp();
    const { open, setOpen, id, refetch, commission } = props;

    const [value, setValue] = useState(null);
    const [isDisabled, setIsDisabled] = useState(false);

    const [updateCommission, { isSuccess, data: commissionData }] =
        useUpdateCommissionMutation();

    useEffect(() => {
        if (commission) {
            setValue(commission);
        }
    }, [commission]);

    useEffect(() => {
        if (isSuccess) {
            refetch();
            setOpen(false);
            return notification.success({
                message: commissionData.message,
                duration: 2,
            });
        }
    }, [isSuccess]);

    useEffect(() => {
        setIsDisabled(!value || value < 1 || value > 100);
    }, [value]);

    const handleSubmit = () => {
        if (value === commission) {
            return setOpen(false);
        }

        updateCommission({
            id: id,
            commission: value,
        });
    };

    return (
        <GenericModal
            title={'Изменить процент'}
            destroyOnClose
            width={360}
            open={open}
            okText={commission === value ? 'Закрыть' : 'Изменить'}
            onCancel={() => {
                setOpen(false);
            }}
            onConfirm={handleSubmit}
            okButtonProps={{
                disabled: isDisabled,
                danger: false,
            }}
        >
            <StyledInput
                status={isDisabled ? 'error' : null}
                value={value}
                onChange={(value: number) => {
                    setValue(value);
                }}
                controls={false}
                min={0}
                placeholder={'Укажите новый процент'}
            />
        </GenericModal>
    );
};

const StyledInput = styled(InputNumber)`
    margin-top: 1rem;
    width: 100%;
`;
