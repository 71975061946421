import React from 'react';
import { Button, Flex, Image, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';

import { validateUrl } from '../../../../../utils/common/validateUrl';
import getEnvConfig from '../../../../../utils/config/env';
import Logo from '../../../../../static/images/logo.png';

const { Title, Text, Link } = Typography;
export const LegalThanksContainer = props => {
    const navigate = useNavigate();
    const { link } = props;

    const navigateAuthPage = () => {
        try {
            if (localStorage.getItem('accessToken')) {
                localStorage.removeItem('accessToken');
            }
        } catch (e) {
            /* empty */
        }
        return navigate(`${getEnvConfig().PUBLIC_PATH}/auth/sign-in`);
    };

    return (
        <Flex vertical gap={24}>
            <Image
                preview={false}
                style={{ width: '50%' }}
                src={Logo}
                alt='Logo'
            />

            <Flex vertical gap={8}>
                <Title level={3}>Отправили счет на почту</Title>
                <Text>
                    Мы отправили счет на указанную вами почту, он будет
                    действителен в течение 14 дней.
                </Text>
                {link && validateUrl(link) ? (
                    <Link href={link} target={'_blank'}>
                        Просмотреть счет
                    </Link>
                ) : null}
            </Flex>

            <Flex vertical gap={16}>
                <Button block onClick={navigateAuthPage} type={'primary'}>
                    Вернуться на экран авторизации
                </Button>
                <Button block onClick={() => navigate('/pay/subscription')}>
                    Выбрать другой способ оплаты
                </Button>
            </Flex>
        </Flex>
    );
};
