import { donateService } from './donate.service';
import { tariffService } from './tariff.service';
import { financeService } from './finance.service';

export const monetizationApi = [
    donateService.middleware,
    tariffService.middleware,
    financeService.middleware,
];

export const monetizationReducers = {
    [donateService.reducerPath]: donateService.reducer,
    [tariffService.reducerPath]: tariffService.reducer,
    [financeService.reducerPath]: financeService.reducer,
};
