import { Flex, Typography } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import React from 'react';
import styled from 'styled-components';

import { useTheme } from '../../../hooks/useTheme';

interface StepTitleProps {
    isChecked: boolean;
    number: string;
    title: string;
}

export const StepTitle = (props: StepTitleProps) => {
    const { isChecked, number, title } = props;
    const token = useTheme();

    return (
        <Flex gap={16} style={{ flexGrow: 1 }}>
            <StyledNumber color={token.colorPrimary}>
                <Typography.Text
                    style={{
                        color: token.colorPrimary,
                    }}
                >
                    {isChecked ? (
                        <CheckOutlined
                            style={{
                                color: token.colorPrimary,
                                fontSize: 12,
                            }}
                        />
                    ) : (
                        number
                    )}
                </Typography.Text>
            </StyledNumber>
            <Typography.Text>{title}</Typography.Text>
        </Flex>
    );
};

const StyledNumber = styled.div<{ color: string }>`
    max-width: 24px;
    height: 24px;
    flex: 1 0 24px;

    display: flex;
    justify-content: center;
    align-items: center;

    color: ${props => props.color};
    border: 1px solid ${props => props.color};
    border-radius: 12px;
`;
