import React, { Fragment } from 'react';
import { Link, NavLink, Outlet, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Button } from 'antd';

import ErrorBoundary from 'shared/ui/ErrorBoundary';
import { Container } from 'shared/ui/Container';
import Logo from 'static/images/logo.png';
import getEnvConfig from 'utils/config/env';

import { Navbar } from '../AppLayout/components/Navbar';
import { Footer } from '../../ui/Footer';

export const PublicLayout: React.FC = () => {
    const isAuthenticated = !!localStorage.getItem('accessToken');
    const navigate = useNavigate();

    return (
        <Fragment>
            <StyledLayout>
                {!isAuthenticated && (
                    <StyledWrapper>
                        <Container>
                            <StyledNav>
                                <NavLink to='/'>
                                    <img height={22} alt='logo' src={Logo} />
                                </NavLink>

                                <div>
                                    <Button
                                        onClick={() =>
                                            navigate('/pay/subscription')
                                        }
                                        type='primary'
                                    >
                                        Войти
                                    </Button>
                                </div>
                            </StyledNav>
                        </Container>
                    </StyledWrapper>
                )}
                {isAuthenticated && <Navbar />}

                <StyledContent>
                    <Outlet />
                </StyledContent>
                <Footer />
            </StyledLayout>
        </Fragment>
    );
};

const StyledLayout = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const StyledContent = styled(Container)`
    flex: 1;
    margin-top: 40px;
    margin-bottom: 48px;
`;

const StyledWrapper = styled.div`
    border-bottom: 1px solid rgba(37, 33, 41, 0.06);
`;

const StyledNav = styled.nav`
    user-select: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 24px;
    padding-bottom: 6px;
`;
