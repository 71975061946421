import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

import getEnvConfig from 'utils/config/env';

export const donateService = createApi({
    reducerPath: 'donate',
    baseQuery: fetchBaseQuery({
        baseUrl: getEnvConfig().env.API_URL,
        prepareHeaders: headers => {
            const token = localStorage.getItem('accessToken');
            headers.set('Authorization', `Bearer ${token}`);
            return headers;
        },
    }),
    tagTypes: ['donateList', 'readDonate'],
    endpoints: build => ({
        create: build.mutation<any, any>({
            query: body => ({
                method: 'POST',
                url: 'donate',
                body,
            }),
            invalidatesTags: ['donateList'],
        }),
        arbitraryAmountPay: build.mutation<any, any>({
            query: body => ({
                method: 'POST',
                url: 'pay/donate',
                body,
            }),
        }),
        deleteById: build.mutation<any, any>({
            query: id => ({
                method: 'DELETE',
                url: `donate/${id}`,
            }),
            invalidatesTags: ['donateList'],
        }),
        update: build.mutation<any, any>({
            query: params => ({
                method: 'PUT',
                url: `donate/${params.id}`,
                body: params.body,
            }),
            invalidatesTags: ['donateList', 'readDonate'],
        }),
        getList: build.query({
            query: params => ({
                method: 'GET',
                url: 'donates',
                params,
            }),
            providesTags: ['donateList'],
            transformResponse: (res: any, meta) => {
                return {
                    list: res?.data || [],
                    total:
                        Number(meta.response.headers.get('Items-Count')) || 0,
                };
            },
        }),
        getByID: build.query<any, any>({
            query: id => ({
                method: 'GET',
                url: `donate/${id}`,
            }),
            providesTags: ['readDonate'],
            transformResponse: (res: any) => res.data,
        }),
    }),
});

export const {
    useCreateMutation,
    useUpdateMutation,
    useDeleteByIdMutation,
    useGetByIDQuery,
    useGetListQuery,
    useArbitraryAmountPayMutation,
} = donateService;
