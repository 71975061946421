import React from 'react';
import { Tooltip, Typography } from 'antd';
import { CopyOutlined } from '@ant-design/icons';

import { Size, useWindowSize } from '../../../../hooks/useWindowSize';
import { useTheme } from '../../../../hooks/useTheme';
import { useToggle } from '../../../../hooks/useToggle';

type Copy = {
    readonly text: string;
    readonly isHideText?: boolean;
};
export const Copy: React.FC<Copy> = props => {
    const { open, setOpen } = useToggle();
    const { size } = useWindowSize();
    const { colorPrimary } = useTheme();

    const { text, isHideText } = props;

    const onCopy = () => {
        navigator.clipboard.writeText(text);
    };

    const handleTooltip = () => {
        setOpen(true);
        setTimeout(() => {
            setOpen(false);
        }, 1000);
    };
    return (
        <Tooltip
            placement={'topRight'}
            open={open}
            onOpenChange={handleTooltip}
            title='Скопировано'
            trigger={['click']}
        >
            {!isHideText && (
                <Typography.Link
                    onClick={onCopy}
                    style={{
                        maxWidth: size === Size.small ? '45%' : '100%',
                    }}
                    ellipsis={true}
                >
                    {text}
                </Typography.Link>
            )}
            <CopyOutlined
                onClick={onCopy}
                style={{
                    color: colorPrimary,
                    marginInlineStart: 4,
                }}
            />
        </Tooltip>
    );
};
