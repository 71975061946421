import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { GlobalToken, Typography } from 'antd';
import styled from 'styled-components';

import { useTheme } from 'hooks/useTheme';
import { useProfileData } from 'hooks/useProfileData';
import getEnvConfig from 'utils/config/env';

import { ISubscription } from '../../../../../../../features/subscriptions/interfaces';
import { formatLeftTime } from '../../../../../../../lib/date';

export const SubscriptionTag = () => {
    const [currentSubscription, setCurrentSubscription] =
        useState<ISubscription>(null);
    const [expirationDate, setExpirationDate] = useState<string>();

    const { user, subscriptions, subscription } = useProfileData();

    useEffect(() => {
        if (user) {
            setExpirationDate(
                formatLeftTime(user.subscription?.expiration_date)
            );
        }
    }, [user]);

    useEffect(() => {
        if (user && subscriptions) {
            const userSubscription = subscriptions.find(
                s => s.id === user.subscription?.subscription_id
            ) || { name: 'Подписка' };
            setCurrentSubscription(userSubscription);
        }
    }, [user, subscriptions]);

    const token = useTheme();

    return (
        <StyledLink
            $token={token}
            to={`${getEnvConfig().PUBLIC_PATH}/subscriptions`}
        >
            <StyledTitle $token={token}>
                {currentSubscription?.name}
            </StyledTitle>
            <StyledDescribtion $token={token}>
                {expirationDate}
            </StyledDescribtion>
        </StyledLink>
    );
};

const StyledLink = styled(NavLink)<{ $token: GlobalToken; $active?: boolean }>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2px;
    padding: 0px 12px 6px 12px;

    text-align: center;
    position: relative;
    color: ${props => props.$token.colorPrimary};
    background: #f3f0ff;
    border-radius: 20px;
`;

const StyledTitle = styled.h2<{ $token: GlobalToken }>`
    position: relative;
    top: 2px;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    margin: 0;
    color: ${props => props.$token.colorPrimary};
`;

const StyledDescribtion = styled(Typography.Text)<{ $token: GlobalToken }>`
    font-size: 9px;
    line-height: 7px;
    color: ${props => props.$token.colorPrimary};
`;
