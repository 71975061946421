import React, { useEffect, useState } from 'react';

import { Page } from 'shared/ui/Page';
import { SubscriptionCard } from 'features/subscriptions/components';

import {
    SubscriptionProps,
    SubscriptionSlug,
} from '../../interfaces/subscription.interface';
import { useProfileData } from '../../../../hooks/useProfileData';

export const Subscriptions: React.FC = () => {
    const { subscriptions, isLoadingSubscription } = useProfileData();

    const [subscription, setSubscription] = useState<SubscriptionProps>(null);

    useEffect(() => {
        if (!isLoadingSubscription && subscriptions) {
            const paidSubscription = subscriptions.find(
                elem => elem.slug === SubscriptionSlug.payJuriPlan
            );
            setSubscription(paidSubscription);
        }
    }, [isLoadingSubscription, subscriptions]);

    return (
        <Page title='Подписка'>
            <SubscriptionCard {...subscription} />
        </Page>
    );
};
