import React from 'react';
import { Col, Divider, Row } from 'antd';

import { rub } from '../../../../shared/contants/styles';

export const OrdersProducts = props => {
    const { items } = props;

    return (
        <div style={{ marginTop: '32px' }}>
            {items?.map(item => (
                <Row key={item.id}>
                    <Col span={12}>
                        {item.title} ({item.quantity} шт.)
                    </Col>
                    <Col span={12}>
                        {item.price}
                        {rub}
                    </Col>
                    <Divider style={{ margin: '16px 0' }} />
                </Row>
            ))}
        </div>
    );
};
