import React, { FC, Fragment } from 'react';
import styled from 'styled-components';
import { FloatButton } from 'antd';

import { Heading } from 'shared/layouts/LandingLayout/screen/Heading';
import { Intro } from 'shared/layouts/LandingLayout/screen/Intro';
import { Collapses } from 'shared/layouts/LandingLayout/screen/Collapses';
import { Tariff } from 'shared/layouts/LandingLayout/screen/Tariff';
import { Faq } from 'shared/layouts/LandingLayout/screen/Faq';
import Bg1 from 'static/landing/bg1.png';
import Bg2 from 'static/landing/bg2.png';
import Bg3 from 'static/landing/bg3.png';
import Bg4 from 'static/landing/bg4.png';
import Bg5 from 'static/landing/bg5.png';

import { CreateShop } from '../screen/CreateShop';
import { RefsProps } from '../types/landing.interface';

export const Content: FC<RefsProps> = ({
    headingRef,
    createShopRef,
    tariffRef,
    collapsesRef,
    faqRef,
}) => {
    return (
        <Fragment>
            <StyledContainer>
                <BackgroundContainer>
                    <ContainerBg1>
                        <img alt={'background image 1'} src={Bg1} />
                    </ContainerBg1>
                    <ContainerBg2>
                        <img alt={'background image 2'} src={Bg2} />
                    </ContainerBg2>
                    <ContainerBg3>
                        <img alt={'background image 3'} src={Bg3} />
                    </ContainerBg3>
                    <ContainerBg4>
                        <img alt={'background image 4'} src={Bg4} />
                    </ContainerBg4>
                    <ContainerBg5>
                        <img alt={'background image 5'} src={Bg5} />
                    </ContainerBg5>
                </BackgroundContainer>

                <Heading refProp={headingRef} />
                <Intro refProp={headingRef} />
                <CreateShop refProp={createShopRef} />
                <Tariff refProp={tariffRef} />
                <Collapses refProp={collapsesRef} />
                <Faq refProp={faqRef} />
            </StyledContainer>
            <FloatButton.BackTop />
        </Fragment>
    );
};

const ContainerBg5 = styled.div`
    position: absolute;
    bottom: 80px;
    left: 0;

    img {
        width: 100%;
    }
`;

const ContainerBg4 = styled.div`
    position: absolute;
    bottom: 1260px;
    right: 0;

    @media (max-width: 992px) {
        border: 1200px;
    }

    img {
        width: 100%;
    }
`;

const ContainerBg3 = styled.div`
    position: absolute;
    top: 1360px;
    left: 0;

    @media (max-width: 1400px) {
        top: 1000px;
    }

    @media (max-width: 992px) {
        display: none;
    }

    img {
        width: 100%;
    }
`;

const ContainerBg2 = styled.div`
    position: absolute;
    top: 970px;
    right: 0;

    @media (max-width: 1400px) {
        top: 840px;
    }

    @media (max-width: 992px) {
        top: 600px;
    }

    @media (max-width: 768px) {
        top: 600px;
    }

    img {
        width: 100%;
    }
`;

const ContainerBg1 = styled.div`
    position: absolute;
    top: 250px;

    img {
        width: 100%;
    }
`;

const BackgroundContainer = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
`;

const StyledContainer = styled.div`
    position: relative;
    width: 100%;
    max-width: 1600px;
    height: auto;
    padding: 0 50px;
    background: #ffffff;
    margin: 0 auto;
    z-index: 1;

    @media (max-width: 992px) {
        padding: 0 16px;
    }
`;
