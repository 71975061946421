import React from 'react';
import { Button, Col, Modal, ModalProps, Row } from 'antd';
import styled from 'styled-components';

type TProps = React.PropsWithChildren<ModalProps> & {
    readonly onConfirm?: () => void | any;
    readonly onCancel?: () => void;
};

export const GenericModal: React.FC<TProps> = props => {
    return (
        <StyledModal
            destroyOnClose
            width={props.width || 380}
            onCancel={props.onCancel}
            footer={props.footer || null}
            open={props.open}
            title={props.title}
            centered={props?.centered}
        >
            {props.children}
            {props.footer !== null && (
                <StyledActions>
                    <Row gutter={48} style={{ flexGrow: 1, rowGap: '24px' }}>
                        <Col md={+props.width > 380 ? 12 : 24} xs={24}>
                            <Button
                                {...props.okButtonProps}
                                block
                                onClick={() => {
                                    props.onConfirm && props.onConfirm();
                                }}
                                type='primary'
                            >
                                {props.okText || 'Ок'}
                            </Button>
                        </Col>
                        <Col md={+props.width > 380 ? 12 : 24} xs={24}>
                            <Button
                                onClick={() => {
                                    props.onCancel && props.onCancel();
                                }}
                                block
                            >
                                {props.cancelText || 'Отмена'}
                            </Button>
                        </Col>
                    </Row>
                </StyledActions>
            )}
        </StyledModal>
    );
};

const StyledModal = styled(Modal)``;
const StyledActions = styled.div`
    margin-top: 32px;
`;
