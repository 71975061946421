import React, { useEffect, useState } from 'react';
import { Flex, Button, Tree, Typography, Tag } from 'antd';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import {
    DownOutlined,
    LoadingOutlined,
    ShareAltOutlined,
} from '@ant-design/icons';
import type { TreeProps } from 'antd';
import { BasicDataNode, DataNode } from 'antd/es/tree';

import { ConfirmationDialog } from '../../../../shared/ui/ConfirmationDialog';
import { useTheme } from '../../../../hooks/useTheme';
import { CategoryPositionProps } from '../../interfaces/products.interface';
import { Copy } from '../../components/Copy';
import { botName, webAppName } from '../../../../utils/common/botInfo';

interface CategoryTreeNode extends DataNode {
    id: number;
    name: string;
    parent_id: number;
    childCategoryList: CategoryTreeNode[];
}

interface CategoriesTreeListProps {
    readonly categoryItems: CategoryTreeNode[];
    readonly onUpdatePosition: (item: CategoryPositionProps) => void;
    readonly onSelectCategory: (categoryList: number[]) => void;
    readonly onDeleteCategory: () => void;
    readonly onRenameCategory: (event) => void;
    readonly updatingCategoryId: number;
    readonly isListUpdating: boolean;
    readonly selectedCategoryId: number;
}

export const CategoriesTreeList = (props: CategoriesTreeListProps) => {
    const { boxShadow } = useTheme();
    const { id } = useParams();

    const {
        categoryItems,
        onUpdatePosition,
        onDeleteCategory,
        onSelectCategory,
        onRenameCategory,
        updatingCategoryId,
        isListUpdating,
        selectedCategoryId,
    } = props;

    const [items, setItems] = useState<CategoryTreeNode[]>(categoryItems);

    useEffect(() => {
        if (categoryItems?.length > 0) {
            setItems(categoryItems);
        }
    }, [categoryItems]);

    const onDrop: TreeProps['onDrop'] = info => {
        const draggableItem = info.dragNode;
        onUpdatePosition({
            parent_id: draggableItem.parent_id,
            category_id: draggableItem.id,
            position: info.dropPosition,
        });
    };

    return (
        <StyledTree>
            <Tree
                style={{
                    width: '100%',
                    position: 'relative',
                    // paddingLeft: '54px',
                }}
                switcherIcon={
                    <StyledDropdown>
                        <DownOutlined />
                    </StyledDropdown>
                }
                onSelect={onSelectCategory}
                selectedKeys={[selectedCategoryId]}
                treeData={items as unknown as CategoryTreeNode[]}
                draggable
                fieldNames={{
                    title: 'name',
                    key: 'id',
                    children: 'childCategoryList',
                }}
                titleRender={nodeData => {
                    return (
                        <StyledCategoryRow>
                            <StyledTitleColumn>
                                <Typography.Text>
                                    {nodeData.name}{' '}
                                </Typography.Text>
                                {updatingCategoryId === nodeData.id &&
                                    isListUpdating && <LoadingOutlined />}
                                {nodeData.is_slider ? (
                                    <Tag color='purple'>Hа главной</Tag>
                                ) : (
                                    <div></div>
                                )}
                            </StyledTitleColumn>
                            {selectedCategoryId === nodeData.id && (
                                <StyledActionsColumn>
                                    <Flex gap={10} align={'center'}>
                                        <div
                                            onClick={event => {
                                                event.stopPropagation();
                                            }}
                                        >
                                            <ConfirmationDialog
                                                activator={
                                                    <Button type='link'>
                                                        Удалить
                                                    </Button>
                                                }
                                                onConfirm={onDeleteCategory}
                                                title='Удалить?'
                                                description={`Внимание! Товары с категорией "${
                                                    nodeData.name
                                                }" останутся без категории. ${
                                                    nodeData.childCategoryList
                                                        ? 'Удалятся вложенные подкатегории.'
                                                        : ''
                                                }`}
                                                withCheckbox
                                            />
                                        </div>

                                        <Button
                                            type='link'
                                            onClick={onRenameCategory}
                                        >
                                            Редактировать
                                        </Button>

                                        <Flex
                                            align={'center'}
                                            onClick={event => {
                                                event.stopPropagation();
                                            }}
                                        >
                                            <Copy
                                                isHideText
                                                text={`https://t.me/${botName}/${webAppName()}?startapp=${id}_c=${selectedCategoryId}`}
                                            />
                                        </Flex>
                                    </Flex>
                                </StyledActionsColumn>
                            )}
                        </StyledCategoryRow>
                    );
                }}
                onDrop={onDrop}
                allowDrop={({ dropNode, dragNode, dropPosition }) => {
                    return (
                        dropNode.parent_id === dragNode.parent_id &&
                        dropPosition === 1
                    );
                }}
            />
        </StyledTree>
    );
};

const StyledTree = styled.div`
    .ant-tree-treenode-draggable {
        cursor: grab;
        width: 100%;
    }
    .ant-tree-node-content-wrapper {
        flex: 1 1 0px;
    }
    .ant-tree-draggable-icon {
        display: none;
    }
`;

const StyledCategoryRow = styled.div`
    display: flex;
    gap: 16px;
    flex: 1 1 0px;
    padding: 16px;

    cursor: grab;
`;

const StyledDropdown = styled.div`
    padding: 16px 0;
`;

const StyledTitleColumn = styled.div`
    display: flex;
    flex: 1 1 0px;
    justify-content: space-between;
    padding: 5px 0;
`;

const StyledActionsColumn = styled.div`
    flex: 0 0 264px;
`;
