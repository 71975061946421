import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import qs from 'query-string';

import getEnvConfig from 'utils/config/env';

import { IStatisticModeration, IStatisticsMembers } from './interfaces';

export const analyticsAdapterApi = createApi({
    reducerPath: 'analytics-adapter',
    baseQuery: fetchBaseQuery({
        baseUrl: getEnvConfig().ADAPTER_API,
        prepareHeaders: headers => {
            const token = localStorage.getItem('accessToken');
            headers.set('Authorization', `Bearer ${token}`);
            return headers;
        },
    }),
    tagTypes: ['Members'],
    endpoints: builder => ({}),
});
