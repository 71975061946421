import React, { useEffect, useState } from 'react';
import { Avatar, Button, Checkbox, Modal } from 'antd';
import styled from 'styled-components';

import { useReadChatsQuery } from 'features/communities/services';
import { useDetachTelegramMutation } from 'features/user/services';

import { getSrc } from '../../../../../../utils/common/getSrc';

type TProps = {
    readonly name: string;
    readonly telegram_id: number;
    readonly photo_url: string;
};

export const RemoveTelegramAccount: React.FC<TProps> = props => {
    const [isOpen, setOpen] = useState<boolean>(false);
    const [isChecked, setChecked] = useState<boolean>(false);

    const { data: source = { items: [], total: 0 } } = useReadChatsQuery({
        telegram_id: props.telegram_id,
    });

    const [detachAccount, { isLoading, isSuccess }] =
        useDetachTelegramMutation();

    const handleClose = () => {
        setOpen(false);
        setChecked(false);
    };

    useEffect(() => {
        if (isSuccess) {
            handleClose();
        }
    }, [isSuccess]);

    return (
        <React.Fragment>
            <StyledButton type='link' onClick={() => setOpen(true)}>
                Отвязать
            </StyledButton>
            <Modal
                footer={false}
                width={380}
                title='Отвязать аккаунт?'
                open={isOpen}
                onCancel={handleClose}
            >
                <Account>
                    <Avatar src={props.photo_url} />
                    <div>{props.name}</div>
                </Account>
                <WarningMessage>
                    Вы не сможете использовать Spodial для управления
                    сообществами:
                </WarningMessage>

                {source.items.slice(0, 3).map((chat, index) => (
                    <Account key={index}>
                        <Avatar src={getSrc(chat.image)} />
                        <div style={{ display: 'flex', flex: 1 }}>
                            {chat.title}
                        </div>
                    </Account>
                ))}
                <p>
                    {source.items.length > 3
                        ? `и еще ${source.items.length - 3}.`
                        : null}
                </p>

                <Checkbox
                    checked={isChecked}
                    onChange={({ target: { checked } }) => {
                        setChecked(checked);
                    }}
                >
                    Я понимаю последствия
                </Checkbox>
                <Actions>
                    <Button
                        loading={isLoading}
                        disabled={!isChecked}
                        type='primary'
                        block
                        onClick={() => {
                            detachAccount(props.telegram_id);
                        }}
                    >
                        Отвязать
                    </Button>
                    <Button block onClick={handleClose}>
                        Отмена
                    </Button>
                </Actions>
            </Modal>
        </React.Fragment>
    );
};

const Account = styled.div`
    margin-bottom: 8px;
    display: flex;
    gap: 12px;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
`;

const WarningMessage = styled.div`
    margin: 16px 0px;
`;

const Actions = styled.div`
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

const StyledButton = styled(Button)`
    padding: 0;
`;
