import React, { useState } from 'react';

import { UsersTable } from './components/UsersTable';
import {
    IBasePaginationListQueryFilters,
    useUsersListQuery,
} from '../../services/usersTab.service';
import { CommissionModal } from './components/CommissionModal';

export const UsersContainer = () => {
    const [filters, setFilters] = useState<IBasePaginationListQueryFilters>({
        offset: 0,
        limit: 10,
    });
    const [open, setOpen] = useState(false);
    const [id, setId] = useState(null);
    const [commission, setCommission] = useState(null);

    const {
        data,
        isSuccess: isSuccessRead,
        refetch,
    } = useUsersListQuery(filters);

    const handleOpen = (id, commission) => {
        setOpen(true);
        setId(id);
        setCommission(commission);
    };

    return (
        <>
            <UsersTable
                total={data?.total || 0}
                items={data?.list || []}
                setFilters={setFilters}
                setOpen={handleOpen}
            />
            <CommissionModal
                open={open}
                setOpen={setOpen}
                id={id}
                commission={commission}
                refetch={refetch}
            />
        </>
    );
};
