import { createSlice } from '@reduxjs/toolkit';

import { RootState } from '../../../store';
import { ShopState } from '../interfaces/shop.interface';
import { shopsApi } from '../services/shops';

const initialState: ShopState = {
    id: null,
    name: '',
    about: '',
    buyable: false,
    photo: '',
    price_from: false,
    user_id: null,
    shop_inline: '',
    shop_link: '',
    payment_system: null,
    details: {
        config: '',
        integration_email: '',
        policy: '',
        offer: '',
    },
};

export const shopSlice = createSlice({
    name: 'shop',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addMatcher(
            shopsApi.endpoints.readShopById.matchFulfilled,
            (state, { payload }) => {
                Object.assign(state, {
                    ...state,
                    ...payload,
                });
            }
        );
    },
});

export const getShopState = (state: RootState): ShopState => state.shop;

export const shopActions = shopSlice.actions;
export const shopReducer = shopSlice.reducer;
