import React from 'react';
import { Typography } from 'antd';

export const ManageTitle = ({ id }) => {
    return (
        <Typography.Title level={1} style={{ margin: 0, marginBottom: '40px' }}>
            {id ? ' Редактирование баннера' : 'Создание баннера'}
        </Typography.Title>
    );
};
