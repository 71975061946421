const phoneRegex = /^(\d{1})?(\d{3})(\d{3})(\d{2})(\d{2})$/;

export const normalizePhone = (phone: string) =>
    phone.replace(phoneRegex, '+7$2$3$4$5');

export const prettifyPhone = (phone: string) =>
    phone.replace(phoneRegex, '+7 $2 $3-$4-$5');

export const prettifyPhoneWithCode = (phone: string) =>
    phone.replace(phoneRegex, '+7 ($2) $3 $4 $5');
