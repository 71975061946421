import React, { useEffect } from 'react';
import { ConfigProvider, App as Antd } from 'antd';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import ru_RU from 'antd/es/locale/ru_RU';
import { YMInitializer } from 'react-yandex-metrika';
import ReactGA from 'react-ga';
import * as amplitude from '@amplitude/analytics-browser';

import { store } from 'store';
import { router } from 'routes';
import { GlobalNotification } from 'shared/ui/GlobalNotification';

import themeConfig from './antd.theme-config';
import { NormalizeStyle } from './normalize.style';
import { GlobalStyle } from './global.style';
import { LandingStyle } from '../Landing/fontStyles';

export const App: React.FC = () => {
    const domain = new URL(window.ENV.API_URL).origin;
    const isDev = domain === 'https://api-spodial-dev.fitdev.ru';

    useEffect(() => {
        if (!isDev) {
            amplitude.init('2078ec84bcd0fd262dbac55fb50efec3');
        }
    }, [isDev]);

    useEffect(() => {
        if (!isDev) {
            ReactGA.initialize('G-0SQ2F5X9KX');
        }
    }, [isDev]);

    return (
        <Provider store={store}>
            <ConfigProvider locale={ru_RU} theme={themeConfig}>
                <Antd>
                    {isDev ? null : (
                        <YMInitializer
                            accounts={[94593994]}
                            options={{
                                clickmap: true,
                                trackLinks: true,
                                accurateTrackBounce: true,
                                webvisor: true,
                                defer: false,
                            }}
                            version='2'
                        />
                    )}
                    <GlobalNotification />
                    <NormalizeStyle />
                    <GlobalStyle />
                    <LandingStyle />
                    <RouterProvider router={router} />
                </Antd>
            </ConfigProvider>
        </Provider>
    );
};
