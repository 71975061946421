import React from 'react';
import { Button, Flex } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';

import { ConfirmationDialog } from '../../../../shared/ui/ConfirmationDialog';
import { useDeleteBannerMutation } from '../../services/banners';

export const ListControls = ({ bannerId }: { bannerId: number }) => {
    const { id } = useParams();
    const navigate = useNavigate();

    const [deleteBanner] = useDeleteBannerMutation();
    const handleDelete = async () => {
        const payload = {
            shopId: +id,
            bannerId: +bannerId,
        };

        await deleteBanner(payload);
    };

    const handleEdit = () => {
        navigate(`edit/${bannerId}`);
    };

    return (
        <Flex
            align={'flex-start'}
            vertical={false}
            gap={8}
            style={{ marginLeft: 'auto' }}
        >
            <ConfirmationDialog
                activator={
                    <Button size={'small'}>
                        <DeleteOutlined />
                    </Button>
                }
                onConfirm={handleDelete}
                title='Удалить баннер?'
                description={
                    'Внимание! Восстановить баннер после удаления будет невозможно.'
                }
                withCheckbox
            />

            <Button size={'small'}>
                <EditOutlined onClick={handleEdit} />
            </Button>
        </Flex>
    );
};
