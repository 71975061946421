import { Col } from 'antd';
import React from 'react';

import { TextEditor } from '../../../../../shared/ui/TextEditor/TextEditor';

export const DescriptionEditor = props => {
    const { formik, isDisabled } = props;

    return (
        <Col xs={24} sm={18} md={12}>
            <TextEditor
                containerHeight={67}
                placeholder={'О товаре (не обязательно)'}
                value={formik?.values?.description || ''}
                onChange={value => {
                    formik.setFieldValue('description', value);
                }}
                isDisabled={isDisabled}
            />
        </Col>
    );
};
