import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Container } from 'shared/ui/Container';
import ErrorBoundary from 'shared/ui/ErrorBoundary';

import { Navbar } from './components/Navbar';
import { useCheckSubscriptionQuery } from '../../../features/subscriptions/services';
import { PayLayout } from '../PayLayout';
import { Footer } from '../../ui/Footer';

export const AppLayout: React.FC = () => {
    const navigate = useNavigate();

    const [checkSubscription, setCheckSubscription] = useState<boolean>(false);
    const { data } = useCheckSubscriptionQuery();

    useEffect(() => {
        if (data?.expired_status === true) {
            setCheckSubscription(true);
            navigate('/pay/subscription');
        }
    }, [data]);

    return checkSubscription ? (
        <PayLayout />
    ) : (
        <StyledLayout>
            <Navbar />
            <StyledContent>
                <ErrorBoundary>
                    <Outlet />
                </ErrorBoundary>
            </StyledContent>
            <Footer />
        </StyledLayout>
    );
};

const StyledLayout = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-x: hidden;
`;

const StyledContent = styled(Container)`
    flex: 1;
    margin-top: calc(1.5rem + 1.5vw);
    margin-bottom: 48px;
`;
