import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import qs from 'query-string';

import getEnvConfig from 'utils/config/env';

import {
    IWebinarCreate,
    IWebinarResponse,
    IWebinarsResponse,
} from '../interfaces/webinars.interface';

export const webinarsApi = createApi({
    reducerPath: 'webinars',
    baseQuery: fetchBaseQuery({
        baseUrl: getEnvConfig().env.API_URL,
        prepareHeaders: headers => {
            const token = localStorage.getItem('accessToken');
            headers.set('Authorization', `Bearer ${token}`);
            return headers;
        },
    }),
    tagTypes: ['Webinars'],
    endpoints: builder => ({
        createWebinar: builder.mutation({
            query: body => ({
                url: '/webinars',
                method: 'POST',
                body: body,
            }),
            transformResponse: (response: { data: IWebinarCreate }) => {
                return response.data;
            },
            invalidatesTags: ['Webinars'],
        }),

        readWebinars: builder.query({
            query: args => {
                const queryArgs = qs.stringify(args, {
                    arrayFormat: 'bracket-separator',
                    skipNull: true,
                });
                return {
                    url: `/webinars?${queryArgs}`,
                    method: 'GET',
                };
            },
            transformResponse: (response: { data: [] }, meta) => {
                return {
                    webinars: response.data,
                    total: Number(meta.response.headers.get('Items-Count')),
                };
            },
            providesTags: ['Webinars'],
        }),

        readWebinarById: builder.query<any, number | string>({
            query: id => ({
                url: `/webinars/${id}`,
                method: 'GET',
            }),
            transformResponse: (response: IWebinarResponse) => {
                return response.data;
            },
            providesTags: ['Webinars'],
        }),
        readWebinarByUuid: builder.query({
            query: uuid => ({
                url: `/webinar/${uuid}`,
                method: 'GET',
            }),
            transformResponse: (response: IWebinarResponse) => {
                return response.data;
            },
            providesTags: ['Webinars'],
        }),
        readWebinarFavourite: builder.query({
            query: args => {
                const queryArgs = qs.stringify(args, {
                    arrayFormat: 'bracket-separator',
                    skipNull: true,
                });
                return {
                    url: `/webinars/favourite?${queryArgs}`,
                    method: 'GET',
                };
            },
            transformResponse: (response: { data: [] }, meta) => {
                return {
                    webinars: response.data,
                    total: Number(meta.response.headers.get('Items-Count')),
                };
            },
            providesTags: ['Webinars'],
        }),

        createWebinarFavourite: builder.mutation({
            query: id => ({
                url: '/webinars/favourite',
                method: 'POST',
                body: { webinar_id: id },
            }),
            transformResponse: (response: IWebinarResponse) => {
                return response.data;
            },
            invalidatesTags: ['Webinars'],
        }),

        deleteWebinarFavourite: builder.mutation({
            query: id => ({
                url: `/webinars/favourite/${id}`,
                method: 'DELETE',
            }),
            transformResponse: (response: { data: any }) => {
                return response.data;
            },
            invalidatesTags: ['Webinars'],
        }),

        updateWebinar: builder.mutation({
            query: ({ id, body }) => ({
                url: `/webinars/${id}`,
                method: 'POST',
                body: body,
            }),
            invalidatesTags: ['Webinars'],
        }),

        deleteWebinar: builder.mutation({
            query: id => ({
                url: `/webinars/${id}`,
                method: 'DELETE',
            }),
            transformResponse: (response: { data: any }) => {
                return response.data;
            },
            invalidatesTags: ['Webinars'],
        }),

        readWebinarsPublic: builder.query({
            query: ({ id, ...args }) => {
                const queryArgs = qs.stringify(args, {
                    arrayFormat: 'bracket-separator',
                    skipNull: true,
                });
                return {
                    url: `/public/webinars/${id}?${queryArgs}`,
                    method: 'GET',
                };
            },
            transformResponse: (response: { data: IWebinarsResponse }) => {
                return response.data;
            },
            providesTags: ['Webinars'],
        }),

        createWebinarUser: builder.mutation({
            query: uuid => ({
                url: `/webinars/register-user/${uuid}`,
                method: 'GET',
            }),
            transformResponse: (response: { data: IWebinarCreate }) => {
                return response.data;
            },
            invalidatesTags: ['Webinars'],
        }),
        createWebinarPay: builder.mutation({
            query: ({ uuid, ...body }) => ({
                url: `/webinar/pay/${uuid}`,
                method: 'POST',
                body: { uuid, ...body },
            }),
            transformResponse: response => {
                return response;
            },
            invalidatesTags: ['Webinars'],
        }),

        readWebinarsVisited: builder.query({
            query: args => {
                const queryArgs = qs.stringify(args, {
                    arrayFormat: 'bracket-separator',
                    skipNull: true,
                });
                return {
                    url: `/visited/webinars?${queryArgs}`,
                    method: 'GET',
                };
            },
            transformResponse: (response: { data: [] }, meta) => {
                return {
                    webinars: response.data,
                    total: Number(meta.response.headers.get('Items-Count')),
                };
            },
            providesTags: ['Webinars'],
        }),
    }),
});
export const {
    useReadWebinarByUuidQuery,
    useCreateWebinarMutation,
    useReadWebinarsQuery,
    useReadWebinarByIdQuery,
    useUpdateWebinarMutation,
    useDeleteWebinarMutation,
    useReadWebinarsPublicQuery,
    useReadWebinarFavouriteQuery,
    useCreateWebinarFavouriteMutation,
    useDeleteWebinarFavouriteMutation,
    useCreateWebinarUserMutation,
    useCreateWebinarPayMutation,
    useReadWebinarsVisitedQuery,
} = webinarsApi;
