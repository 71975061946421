import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
}


* {
    box-sizing: border-box;
}

a {
    text-decoration: none;
    color: #6257d2;
}

#root {
    height: 100%;

    .ant-app {
        height: 100%;
    }
}

.ant-select-tree-list-holder-inner {
    padding: 6px 6px 0 0;
}
`;
