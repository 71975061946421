import React, {
    Dispatch,
    FC,
    Fragment,
    SetStateAction,
    useEffect,
} from 'react';
import { App, Button, Table } from 'antd';
import dayjs from 'dayjs';
import { DownOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { Dropdown } from 'shared/ui/Dropdown';
import { IBasePaginationListQueryFilters } from 'features/admin/services/usersTab.service';

import { prettifyPhone } from '../../../../../utils/common/phones';
import { useCloseFeedbackMutation } from '../../../services/feedbackTab.service';

interface IProps {
    total: number;
    items: any;
    setFilters: Dispatch<SetStateAction<IBasePaginationListQueryFilters>>;
}

export const FeedbackTable: FC<IProps> = props => {
    const { notification } = App.useApp();
    const navigate = useNavigate();
    const [closeFeedback, { isSuccess, error }] = useCloseFeedbackMutation();

    useEffect(() => {
        if (isSuccess) {
            notification.success({
                message: 'Успешно',
                placement: 'top',
            });
            navigate(-1);
        }
    }, [isSuccess]);

    useEffect(() => {
        if (error) {
            notification.error({
                message: (error as any).data.message || 'Что-то пошло не так',
                placement: 'top',
            });
        }
    }, [error]);

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            width: '66px',
            render: text => text,
        },
        {
            title: 'Сообщение',
            dataIndex: 'text',
            key: 'id',
            width: '110px',
            ellipsis: true,
            render: text => text,
        },
        {
            title: 'Имя',
            dataIndex: 'name',
            key: 'id',
            width: '110px',
            ellipsis: true,
            render: text => text || 'none',
        },
        {
            title: 'Создан',
            dataIndex: 'created_at',
            key: 'id',
            width: '110px',
            ellipsis: true,
            render: time => dayjs.unix(time).format('DD.MM.YY HH:mm'),
        },
        {
            title: 'Почта',
            dataIndex: 'email',
            key: 'id',
            width: '110px',
            ellipsis: true,
            render: text => text,
        },
        {
            title: 'Телефон',
            dataIndex: 'phone',
            key: 'id',
            width: '110px',
            ellipsis: true,
            render: (text: number | null) =>
                text ? prettifyPhone(text.toString()) : '',
        },
        {
            title: 'Статус',
            dataIndex: 'status',
            key: 'id',
            width: '89px',
            ellipsis: true,
            render: text => text || 0,
        },
        {
            title: 'Действие',
            dataIndex: '',
            key: 'id',
            width: '110px',
            render: feedback => (
                <>
                    {feedback.status === 'Новый' && (
                        <StyledDropdown
                            dropdownContent={() => (
                                <Fragment>
                                    <Button
                                        type='text'
                                        onClick={() =>
                                            navigate(`${feedback.id}`)
                                        }
                                    >
                                        Ответить
                                    </Button>
                                    <Button
                                        type='text'
                                        onClick={() =>
                                            closeFeedback(feedback.id)
                                        }
                                    >
                                        Закрыть обращение
                                    </Button>
                                </Fragment>
                            )}
                        >
                            <Button type='default'>
                                Действие <DownOutlined />
                            </Button>
                        </StyledDropdown>
                    )}
                    {feedback.status !== 'Новый' && (
                        <Button
                            type='text'
                            onClick={() => navigate(`${feedback.id}`)}
                        >
                            Просмотреть
                        </Button>
                    )}
                </>
            ),
        },
    ];

    const pagination = {
        total: props.total,
        showTotal: () => `Отзывов: ${props.total}`,
        onChange: (page, pageSize) => {
            props.setFilters({
                offset: (page - 1) * pageSize,
                limit: pageSize,
            });
        },
        showQuickJumper: false,
        showSizeChanger: false,
    };

    return (
        <Fragment>
            <Table
                columns={columns}
                rowKey={user => user.id}
                dataSource={props.items}
                pagination={pagination}
            />
        </Fragment>
    );
};

const StyledDropdown = styled(Dropdown)`
    margin-left: auto;
    cursor: pointer;

    button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        border: none;
        box-shadow: none;
        padding: 0;

        span:nth-child(1) {
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
`;
