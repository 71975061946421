import React, { Fragment, useEffect, useState } from 'react';
import { App, Button, Modal } from 'antd';
import { QuestionOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import { useCreateFeedbackMutation } from 'features/user/services';
import { VirtualTourProvider } from 'shared/ui/VirtualTour/VirtualTourProvider';

import FeedbackForm from './form';
import { useProfileData } from '../../../../../../../hooks/useProfileData';

export const Feedback: React.FC = () => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const { notification } = App.useApp();

    const { user } = useProfileData();

    const [createFeedback, { isLoading, isSuccess }] =
        useCreateFeedbackMutation();

    const onCancel = () => {
        setIsOpen(false);
    };
    const onSubmit = (fb_message: string) => {
        createFeedback({
            fb_name: user.name,
            fb_phone: user.phone,
            fb_email: user.email,
            fb_message,
        });
    };

    useEffect(() => {
        if (isSuccess) {
            onCancel();
            notification.success({
                message: 'Успешно',
                description: 'Ваше обращение отправлено.',
                placement: 'top',
            });
        }
    }, [isSuccess]);

    return (
        <Fragment>
            <FeedbackButton
                size='small'
                shape='circle'
                onClick={() => setIsOpen(true)}
                ref={VirtualTourProvider.get()?.feedbackRef}
            >
                <QuestionOutlined />
            </FeedbackButton>
            <Modal
                destroyOnClose
                width={380}
                onCancel={onCancel}
                footer={null}
                title='Обратиться в поддержку'
                open={isOpen}
            >
                <FeedbackForm
                    onSubmit={onSubmit}
                    onCancel={onCancel}
                    email={user?.email}
                    isLoading={isLoading}
                />
            </Modal>
        </Fragment>
    );
};

const FeedbackButton = styled(Button)`
    color: white;
    background-color: #6257d2;
    opacity: 0.4;
`;
