export interface SubscriptionProps {
    readonly badge: string;
    readonly commission: number;
    readonly deleted_at: Date | null;
    readonly description: string;
    readonly file_id: null;
    readonly id: number;
    readonly is_active: boolean;
    readonly name: string;
    readonly period_days: number;
    readonly price: number;
    readonly price_old: number;
    readonly slug: SubscriptionSlug;
    readonly sort_order?: number;
}

export interface SubscriptionPayload {
    readonly subscription_id: number;
    readonly payment_method: number; //1 - bill 2 - card
    readonly success_url: string;
}

export interface SubscriptionResponse {
    readonly redirect: string;
}

export interface ICheckSubscription {
    readonly expired_status: boolean;
}

export interface ISubscriptions {
    data: {
        readonly commissions: number;
        readonly id: number;
        readonly name: string;
        readonly is_active: boolean;
        readonly price: number;
        slug: string;
    };
}

export enum SubscriptionSlug {
    trial = 'trial_plan',
    payPlan = 'pay_plan',
    payJuriPlan = 'pay_juri_plan',
}

export interface ActivatedSubscription {
    readonly name: string;
    readonly description: string;
    readonly price: number;
}
