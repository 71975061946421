import React from 'react';
import styled from 'styled-components';

import { TitleProps } from '../types/landing.interface';

export const Title: React.FC<TitleProps> = props => {
    return <StyledTitle id={props.id}>{props.text}</StyledTitle>;
};

export const SubTitle: React.FC<TitleProps> = props => {
    return <StyledSubTitle type={props.type}>{props.text}</StyledSubTitle>;
};

const StyledTitle = styled.h1`
    font-family: 'Unbounded', sans-serif;
    font-size: 2.375rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: #6257d2;
`;

const StyledSubTitle = styled.p<{ type: string }>`
    font-family: 'Unbounded', sans-serif;
    font-size: 2rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0;
    color: ${props => (props.type === 'primary' ? '#6257d2' : '#ffffff')};
`;
