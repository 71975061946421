import { SubscriptionProps } from 'features/subscriptions/interfaces';
import { IUser } from 'features/user/interfaces';

export const getUserSubscription = (
    user: IUser,
    subscriptions: SubscriptionProps[]
): SubscriptionProps =>
    subscriptions.find(
        subscription => subscription.id === user.subscription.subscription_id
    );
