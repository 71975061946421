import React, { useEffect, useMemo, useState } from 'react';
import { App, Checkbox, Flex, Input, Switch } from 'antd';
import { useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import Secondary from 'shared/ui/SecondaryText';

import { GenericModal } from '../../../../shared/ui/GenericModal';
import {
    useCreateProductCategoryMutation,
    useUpdateProductCategoryMutation,
} from '../../services/categories';
import {
    CreateCategoryItem,
    EditCategoryItem,
} from '../../interfaces/categories.interface';
import { createFormData } from '../../../../utils/common/createFormData';
import { ShopImageUpload } from '../../components/ShopImageUpload';
import { deepGetObjectById } from '../../../../utils/common/deepGetObjectById';
import { getCategoryState } from '../../store/categorySlice';
import { CategoryImageUploader } from '../../components/Category/CategoryImageUploader';

const initialValues: CreateCategoryItem = {
    name: '',
    description: '',
    image: null,
    is_active: false,
    shop_id: null,
    parent_id: 0,
    is_slider: false,
};

const categoryLength = 100;
export const CategoryEditorModal = props => {
    const { id } = useParams();
    const { notification } = App.useApp();
    const {
        open,
        setOpen,
        setCategoryName,
        categoryName,
        categoryId,
        isEdit,
        parentId,
        children,
    } = props;

    const [isCategoryExist, setIsCategoryExist] = useState(false);
    const { nestedCategoryList } = useSelector(getCategoryState);
    const [createCategory, { isLoading, isSuccess }] =
        useCreateProductCategoryMutation();
    const [
        updateCategory,
        { isSuccess: isSuccessUpdate, isLoading: isLoadingUpdate },
    ] = useUpdateProductCategoryMutation();

    const onSave = async (values: CreateCategoryItem) => {
        const data = Object.fromEntries(
            Object.entries(values).filter(([_, value]) => value != null)
        );

        data.shop_id = +id;
        if (!data.parent_id) {
            data.parent_id = parentId || 0;
        }
        if (data.description === '') {
            delete data.description;
        }
        if (!(data.image instanceof File)) {
            delete data.image;
        }

        (await isEdit)
            ? updateCategory({
                  id: Number(data.id),
                  body: createFormData({ ...data, _method: 'PUT' }),
              })
            : createCategory(createFormData(data));
    };

    const formik = useFormik({
        initialValues: initialValues,
        onSubmit: onSave,
    });

    useEffect(() => {
        const categoryObject = deepGetObjectById(
            nestedCategoryList,
            categoryId
        );
        if (categoryObject && open && isEdit) {
            formik.setValues(
                {
                    ...categoryObject,
                    shop_id: +id,
                },
                true
            );
        }
        if (!open) {
            formik.resetForm();
        }
    }, [open, categoryId]);

    const sameLevelCategoryNames = useMemo(() => {
        if (open) {
            const selectedCategoryParentId = isEdit
                ? deepGetObjectById(nestedCategoryList, categoryId)?.parent_id
                : categoryId;

            const sameLevelCategories =
                selectedCategoryParentId === 0
                    ? nestedCategoryList
                    : deepGetObjectById(
                          nestedCategoryList,
                          selectedCategoryParentId
                      )?.childCategoryList;

            const categoryNames = Array.from(
                new Set(
                    sameLevelCategories?.map(category =>
                        category.name !== categoryName ? category.name : null
                    )
                )
            );
            return categoryNames;
        }
        return [];
    }, [nestedCategoryList, categoryId, isEdit, open]);

    useEffect(() => {
        if (formik.values.name && sameLevelCategoryNames) {
            if (sameLevelCategoryNames.includes(formik.values.name)) {
                setIsCategoryExist(true);
                notification.error({
                    message: 'Категория с таким названием уже существует',
                });
            } else setIsCategoryExist(false);
        }

        if (formik.values?.name.length >= categoryLength) {
            notification.error({
                message: 'Слишком длинное название',
            });
        }
    }, [formik.values.name]);

    useEffect(() => {
        if (isSuccess) {
            notification.success({
                message: 'Категория успешно добавлена',
                duration: 1,
            });
            setOpen(false);
        }
    }, [isSuccess]);

    useEffect(() => {
        if (isSuccessUpdate) {
            notification.success({
                message: 'Категория успешно изменена',
            });
            setOpen(false);
        }
    }, [isSuccessUpdate]);

    useEffect(() => {
        if (!open) {
            setCategoryName('');
        }
    }, [open]);

    const modalTitle = () => {
        switch (true) {
            case isEdit:
                return 'Изменить категорию';
            case !isEdit && parentId !== null:
                return 'Добавить подкатегорию';
            case !isEdit && parentId === null:
            default:
                return 'Добавить категорию';
        }
    };

    const handleChange = (type: string, value: string) => {
        const text = value.length > 1 ? value : value.trim();
        formik.setFieldValue(type, text);
    };

    const disabled =
        formik.values.name === '' ||
        formik.values.name?.length >= categoryLength ||
        isCategoryExist ||
        isLoading ||
        isLoadingUpdate;

    return (
        <GenericModal
            okText={isEdit ? 'Сохранить' : 'Добавить'}
            title={modalTitle()}
            width={360}
            open={open}
            onConfirm={() => onSave(formik.values)}
            onCancel={() => {
                setOpen(false);
            }}
            okButtonProps={{
                loading: isLoading || isLoadingUpdate,
                disabled: disabled,
            }}
            cancelButtonProps={{
                loading: isLoading || isLoadingUpdate,
                disabled: isLoading || isLoadingUpdate,
            }}
        >
            <Flex vertical gap={16}>
                {children}

                <div>
                    <StyledLabel>Название*</StyledLabel>
                    <Input
                        value={formik?.values?.name}
                        placeholder={'Название категории'}
                        onChange={event => {
                            handleChange(`name`, event.target.value);
                        }}
                        count={{
                            show: true,
                            max: categoryLength,
                        }}
                    />
                </div>

                <Flex gap={16}>
                    <Switch
                        checked={formik?.values?.is_slider}
                        onChange={() =>
                            formik.setFieldValue(
                                'is_slider',
                                !formik?.values.is_slider
                            )
                        }
                    />
                    <StyledLabel>Показывать на главном экране</StyledLabel>
                </Flex>

                {formik?.values.is_slider && (
                    <div>
                        <StyledLabel>Фото категории</StyledLabel>
                        <CategoryImageUploader
                            isEdit={isEdit}
                            formik={formik}
                            values={formik.values}
                        />
                        <Secondary>
                            Тип файлов: PNG, JPG, JPEG, GIF, вес до 10 МБ
                        </Secondary>
                    </div>
                )}
            </Flex>
        </GenericModal>
    );
};

const StyledLabel = styled.label`
    display: block;
    margin-bottom: 0.5rem;
`;
