import React, { Fragment, PropsWithChildren } from 'react';
import { Typography } from 'antd';
import styled from 'styled-components';

import { renderSplitPrice } from '../../../../utils/common/renderSpiltPrice';
import { rub } from '../../../../shared/contants/styles';
import { useTheme } from '../../../../hooks/useTheme';

const { Text } = Typography;

export const Price = (props: PropsWithChildren) => {
    const { children } = props;

    const subComponents = React.Children.map(children, child => {
        return React.isValidElement(child) ? React.cloneElement(child) : null;
    });

    return (
        <>
            {subComponents.map((component, index) => (
                <Fragment key={index}>{component}</Fragment>
            ))}
        </>
    );
};

const Old = ({ price }) => {
    return (
        <>
            {price && (
                <StyledOldPrice type={'secondary'} delete>
                    {renderSplitPrice(+price)}
                    {rub}
                </StyledOldPrice>
            )}
        </>
    );
};

const StyledOldPrice = styled(Text)`
    font-size: 12px;
    white-space: nowrap;
`;

Price.Old = Old;

const Current = ({ isPriceFrom = false, price }) => {
    const token = useTheme();

    return (
        <Text
            strong
            style={{
                color: token.colorPrimary,
                whiteSpace: 'nowrap',
            }}
        >
            {isPriceFrom ? 'от ' : ''}
            {renderSplitPrice(+price)}
            {rub}
        </Text>
    );
};

Price.Current = Current;
