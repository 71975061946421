import { Collapse, Flex, Typography, Image } from 'antd';
import React from 'react';
import styled from 'styled-components';

import IntegrationEmailImage from 'static/images/integration-email-image.png';

export const IntegrationEmailExample = () => {
    const renderEmail = () => (
        <Flex vertical>
            <Typography.Paragraph>Статус: Оформлен</Typography.Paragraph>
            <Typography.Paragraph>
                Содержимое заказа:
                <br />
                Код товара: 596407, lock343s - 5 шт.
            </Typography.Paragraph>
            <Typography.Paragraph>
                Итого:
                <br />
                Сумма товаров: 1245 руб.
                <br />
                Сумма доставки: 20 руб.
                <br />
                Общая сумма: 1265 руб.
            </Typography.Paragraph>

            <Typography.Paragraph>
                Адрес доставки: <br />
                Alexander Alexandrovich
                <br />
                ru
                <br />
                Russia, Dudinka, Krishov st., 61, 58
                <br />
                241014
            </Typography.Paragraph>
            <Typography.Paragraph>
                Комментарий: Пришлите самого красивого курьера.
            </Typography.Paragraph>
            <StyledImage preview={false} src={IntegrationEmailImage} />
        </Flex>
    );

    return (
        <Collapse
            size='middle'
            defaultActiveKey={1}
            items={[
                {
                    key: '1',
                    label: 'Пример письма',
                    children: renderEmail(),
                },
            ]}
        />
    );
};

const StyledImage = styled(Image)`
    max-width: 353px;
`;
