import React, { ReactNode } from 'react';
import { Flex, Typography } from 'antd';
import { Link } from 'react-router-dom';

import { CopyWithTooltip } from '../../../../../../shared/ui/CopyWithTooltip/CopyWithTooltip';

export const Step_1 = () => {
    return (
        <Flex vertical>
            <Typography.Paragraph>
                Шаг 1. Регистрация CloudPayments
            </Typography.Paragraph>
            <Typography.Text>
                Для подключения оплаты необходимо:
            </Typography.Text>
            <Typography.Text>
                1. перейти по{' '}
                <Link to={'https://cloudpayments.ru/'} target={'_blank'}>
                    партнерской ссылке
                </Link>{' '}
                и зарегистрироваться в системе CloudPayments
            </Typography.Text>
            <Typography.Text>
                2. в настройках сайта получить “Public ID” и “Пароль для API”
            </Typography.Text>
            <Typography.Text>
                3. перейти во вкладку «Уведомления» и включить уведомление «Pay»
            </Typography.Text>
            <Typography.Text>
                4. в поле «URL сайта» указать ссылку:{' '}
                <CopyWithTooltip
                    text={'https://api.spodial.com/cloud-payments/notify'}
                />
            </Typography.Text>
        </Flex>
    );
};
