import React, { FC } from 'react';
import styled from 'styled-components';

import Rectangle from 'static/landing/Rectangle.png';
import StaticText from 'shared/layouts/LandingLayout/content.json';

import { ScreenProps } from '../types/landing.interface';

export const Faq: FC<ScreenProps> = ({ refProp }) => {
    return (
        <StyledSectionSixth ref={refProp}>
            {StaticText.faq.options.map((faq, index) => (
                <SectionSixthRow key={index}>
                    <SectionSixthRowLeft>
                        <div>
                            <img alt={'rectangle'} src={Rectangle} />
                        </div>
                    </SectionSixthRowLeft>
                    <SectionSixthRowRight>
                        <SectionSixthTitle>{faq.title}</SectionSixthTitle>
                        <SectionSixthDescription>
                            {faq.description}
                        </SectionSixthDescription>
                    </SectionSixthRowRight>
                </SectionSixthRow>
            ))}
        </StyledSectionSixth>
    );
};

const SectionSixthRowLeft = styled.div`
    display: flex;
    min-width: 32px;
    padding-top: 15px;

    @media (max-width: 992px) {
        display: none;
        min-width: 0;
        padding-top: 0;
    }

    img {
        width: 32px;
        transform: rotate(45deg);

        @media (max-width: 992px) {
            display: none;
            width: 0;
            padding-top: 0;
        }
    }
`;

const SectionSixthRowRight = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`;

const SectionSixthRow = styled.div`
    display: flex;
    gap: 60px;
    width: 100%;
    margin-bottom: 40px;

    @media (max-width: 992px) {
        gap: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }
`;

const SectionSixthDescription = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: rgba(37, 33, 41, 0.88);
`;

const SectionSixthTitle = styled.div`
    font-family: 'Unbounded', sans-serif;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    color: #6257d2;
    margin-bottom: 16px;

    @media (max-width: 992px) {
        font-size: 16px;
    }
`;

const StyledSectionSixth = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    width: 100%;
    background: #ffffff;
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.15);
    border-radius: 40px;
    padding: 78px;
    margin-bottom: 80px;

    @media (max-width: 1400px) {
        padding: 64px;
        margin-bottom: 64px;
    }

    @media (max-width: 992px) {
        padding: 40px;
        margin-bottom: 32px;
    }

    @media (max-width: 768px) {
        padding: 24px;
    }

    @media (max-width: 576px) {
        width: auto;
        flex-direction: column;
        padding: 16px;
        margin-left: -16px;
        margin-right: -16px;
        border-radius: 0;
        margin-bottom: 24px;
    }
`;
