import React from 'react';
import { Navigate } from 'react-router';

import getEnvConfig from 'utils/config/env';

type TProps = {
    children: React.ReactNode;
};

export const ProtectedRoute: React.FC<TProps> = props => {
    const { children } = props;

    const accessToken = localStorage.getItem('accessToken');

    const isAllowedToVisit = (): boolean => {
        return !!accessToken;
    };

    if (isAllowedToVisit()) {
        return <React.Fragment>{children}</React.Fragment>;
    }

    return <Navigate to={`${getEnvConfig().PUBLIC_PATH}/auth/sign-in`} />;
};
