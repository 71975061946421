import { FormInstance } from 'antd';

export enum PaymentSystemsNames {
    unitpay = 'unitpay',
    yookassa = 'yookassa',
    robokassa = 'robokassa',
    cloudPayments = 'cloudPayments',
}
export interface PaymentSystems {
    readonly id?: number;
    readonly brand: string;
    readonly name: PaymentSystemsNames;
    readonly connect: boolean;
    readonly active: boolean;
}

export interface CloudPaymentsFormProps {
    publicId: number;
    apiSecret: string;
}

export interface CloudPaymentsProps extends CloudPaymentsFormProps {
    shop_id: number;
}
