import {
    ProductsFilters,
    ProductsTypes,
} from '../interfaces/products.interface';
import { Shop } from '../interfaces/shop.interface';

export const initialFilters: ProductsFilters = {
    title: '',
    offset: 0,
    limit: 8,
    category_id: null,
    status: [2],
    page: 1,
    type: ProductsTypes.product,
};

export const InitialFiltersArchived: ProductsFilters = {
    title: '',
    offset: 0,
    limit: 8,
    category_id: null,
    status: [1, 3],
    page: 1,
    type: ProductsTypes.product,
};
export const InitialFiltersLinks: ProductsFilters = {
    title: '',
    offset: 0,
    limit: 8,
    category_id: null,
    status: [],
    page: 1,
    type: ProductsTypes.link,
};

export const authorsInitialFilters: Shop = {
    name: '',
    offset: 0,
    limit: 6,
};
