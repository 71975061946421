import { UploadFile } from 'antd';

export const blobToBase64 = (
    blob: File | UploadFile<any>
): Promise<string | ArrayBuffer> => {
    return new Promise(resolve => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob as File);
    });
};
