import React, { FC, Fragment, useState } from 'react';
import { Button } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import { PaymentsTable } from 'features/admin/components/PaymentsTab/components/PaymentsTable';
import { usePaymentsListQuery } from 'features/admin/services/paymentsTab.service';
import { IBasePaginationListQueryFilters } from 'features/admin/services/usersTab.service';

import { Dropdown } from '../../../../shared/ui/Dropdown';
import { downloadFile } from '../../../../utils/common/downloadFile';

export const PaymentsTab: FC = () => {
    const [filters, setFilters] = useState<IBasePaginationListQueryFilters>({
        offset: 0,
        limit: 10,
    });
    const { data, isFetching } = usePaymentsListQuery(filters);

    return (
        <Fragment>
            <StyledActions>
                <StyledDropdown
                    dropdownContent={() => (
                        <Fragment>
                            <Button
                                type='text'
                                onClick={() => {
                                    downloadFile(
                                        '/manager/export/payments',
                                        'xlsx',
                                        { type: 'xlsx' }
                                    );
                                }}
                            >
                                XLSX
                            </Button>
                            <Button
                                type='text'
                                onClick={() => {
                                    downloadFile(
                                        '/manager/export/payments',
                                        'csv',
                                        { type: 'csv' }
                                    );
                                }}
                            >
                                CSV
                            </Button>
                        </Fragment>
                    )}
                >
                    <Button type='default'>
                        Скачать <DownOutlined />
                    </Button>
                </StyledDropdown>
            </StyledActions>
            <PaymentsTable
                total={data?.total || 0}
                items={data?.list || []}
                setFilters={setFilters}
            />
        </Fragment>
    );
};

const StyledDropdown = styled(Dropdown)`
    cursor: pointer;
`;

const StyledActions = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
`;
