import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import getEnvConfig from 'utils/config/env';
import { IBasePaginationListQueryFilters } from 'features/admin/services/usersTab.service';

export const communitiesTabService = createApi({
    reducerPath: 'adminCommunitiesTab',
    baseQuery: fetchBaseQuery({
        baseUrl: getEnvConfig().env.API_URL + '/manager',
        prepareHeaders: headers => {
            const token = localStorage.getItem('accessToken');
            headers.set('Authorization', `Bearer ${token}`);
            return headers;
        },
    }),
    endpoints: build => ({
        communitiesList: build.query<any, IBasePaginationListQueryFilters>({
            query: params => ({
                method: 'GET',
                url: 'communities',
                params,
            }),
            transformResponse: (res: any, meta) => {
                return {
                    list: res?.data || [],
                    total:
                        Number(meta.response.headers.get('items-count')) || 0,
                };
            },
        }),
        getCommunityById: build.query<any, any>({
            query: (id: number) => ({
                url: `communities/${id}`,
            }),
        }),
    }),
});

export const { useCommunitiesListQuery, useGetCommunityByIdQuery } =
    communitiesTabService;
