import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

import getEnvConfig from '../../../utils/config/env';
import { BannerProps, BannerRequest } from '../interfaces/banners.interface';

export const bannersApi = createApi({
    reducerPath: 'banners',
    baseQuery: fetchBaseQuery({
        baseUrl: getEnvConfig().env.API_URL,
        prepareHeaders: headers => {
            const token = localStorage.getItem('accessToken');
            headers.set('Authorization', `Bearer ${token}`);
            return headers;
        },
    }),
    tagTypes: ['Banners'],

    endpoints: builder => ({
        createBanner: builder.mutation({
            query: ({ id, body }) => ({
                url: `/shops/${id}/banners`,
                method: 'POST',
                body: body,
            }),
            transformResponse: (response: { data: BannerProps[] }) => {
                return response.data;
            },
            invalidatesTags: ['Banners'],
        }),

        updateBanner: builder.mutation({
            query: ({ id, bannerId, body }) => ({
                url: `/shops/${id}/banners/${bannerId}`,
                method: 'POST',
                body: body,
            }),
            transformResponse: (response: { data: BannerProps[] }) => {
                return response.data;
            },
            invalidatesTags: ['Banners'],
        }),

        readBanners: builder.query({
            query: id => {
                return {
                    url: `/shops/${id}/banners`,
                };
            },
            transformResponse: (res: { data: BannerProps[] }) => {
                return res.data;
            },
            providesTags: ['Banners'],
        }),

        readBanner: builder.query<BannerProps, BannerRequest>({
            query: body => {
                return {
                    url: `/shops/${body.shopId}/banners/${body.bannerId}`,
                };
            },
            transformResponse: (res: { data: BannerProps }) => {
                return res.data;
            },
        }),

        deleteBanner: builder.mutation<
            void,
            { shopId: number; bannerId: number }
        >({
            query: ({ shopId, bannerId }) => ({
                url: `/shops/${shopId}/banners/${bannerId}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Banners'],
        }),

        updateBannerPosition: builder.mutation({
            query: ({ shopId, list }) => ({
                url: `/shops/${shopId}/banners/update-positions`,
                method: 'POST',
                body: { list: list },
            }),
            transformResponse: (response: { data: BannerProps[] }) => {
                return response.data;
            },
        }),
    }),
});

export const {
    useLazyReadBannersQuery,
    useCreateBannerMutation,
    useDeleteBannerMutation,
    useUpdateBannerMutation,
    useLazyReadBannerQuery,
    useUpdateBannerPositionMutation,
} = bannersApi;
