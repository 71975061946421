import React from 'react';
import { Button, Col, Flex, Row, Space, Typography } from 'antd';
import { UpOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import telegramApplicationImage from 'static/images/telegram_application.png';

import { AddTelegramAccount } from '../../../user/components/TelegramAccounts/components/AddTelegramAccount';
import { IUser } from '../../../user/interfaces';
import { useReadUserQuery } from '../../../user/services';
import { OnboardingStepLegal } from './OnboardingStepLegal';
import TelegramAccount from '../../../user/components/TelegramAccounts/components/TelegramAccount';
import { StepTitle } from '../../../../shared/ui/StepTitle';

export const OnboardingStepsList = props => {
    const { loadingReadLegal, isOpenCard, setIsOpenCard, ...legalInfo } = props;

    const {
        data: user = {
            telegram_accounts: [],
        } as IUser,
    } = useReadUserQuery();
    const isTelegramAccount = user?.telegram_accounts?.length > 0;
    const isLegalInfoFilled = legalInfo && legalInfo.id !== undefined;

    return (
        <Row gutter={16} style={{ flexGrow: 1 }}>
            <Col md={{ span: 8, order: 1 }} xs={{ span: 24, order: 3 }}>
                <img
                    src={telegramApplicationImage}
                    alt='telegramm_application'
                    style={{ width: '100%' }}
                />
            </Col>
            <Col md={16} xs={24} order={2}>
                <Flex vertical gap={16}>
                    <Flex justify={'space-between'}>
                        <StepTitle
                            isChecked={isTelegramAccount}
                            number={'1'}
                            title={
                                'Привяжите свой аккаунт в Telegram для создания магазина'
                            }
                        />
                        <StyledButton onClick={() => setIsOpenCard(false)}>
                            <UpOutlined style={{ fontSize: 20 }} />
                        </StyledButton>
                    </Flex>

                    {!!user?.telegram_accounts?.length &&
                        user?.telegram_accounts?.map((account, index) => (
                            <TelegramAccount account={account} key={index} />
                        ))}

                    <Space>
                        <AddTelegramAccount
                            isAccountPrimary={!isTelegramAccount}
                        />
                    </Space>

                    <StepTitle
                        isChecked={isLegalInfoFilled}
                        number={'2'}
                        title={
                            'Заполните сведения о своей организации, чтобы принимать оплату через Telegram'
                        }
                    />

                    <OnboardingStepLegal
                        loadingReadLegal={loadingReadLegal}
                        {...legalInfo}
                    />
                </Flex>
            </Col>
        </Row>
    );
};

const StyledButton = styled(Button)`
    border: none;
    width: 24px;
    height: 24px;
    padding: 0;
`;
