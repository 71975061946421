import React, { ReactNode } from 'react';
import { Button, Col, Flex, Row, Typography } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import styled from 'styled-components';

interface OnboardingBaseProps {
    isOpenCard: boolean;
    setIsOpenCard: (isOpen: boolean) => void;
    icon: ReactNode;
    title: string;
    description: string;
}

export const OnboardingNotification = (props: OnboardingBaseProps) => {
    const { isOpenCard, setIsOpenCard, title, icon, description } = props;

    return (
        <Flex
            gap={16}
            style={{ flexGrow: 1, cursor: 'pointer' }}
            onClick={() => setIsOpenCard(true)}
        >
            <div>{icon}</div>
            <Flex vertical style={{ flexGrow: 1 }}>
                <Row wrap={false}>
                    <Col flex='auto'>
                        <Typography.Text>{title}</Typography.Text>
                    </Col>
                    <Col flex='none'>
                        <StyledButton onClick={() => setIsOpenCard(true)}>
                            <DownOutlined style={{ fontSize: 20 }} />
                        </StyledButton>
                    </Col>
                </Row>
                <Typography.Text>{description}</Typography.Text>
            </Flex>
        </Flex>
    );
};

const StyledButton = styled(Button)`
    border: none;
    width: 24px;
    height: 24px;
    padding: 0;
`;
