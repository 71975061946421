import React from 'react';
import { Card, Col, Flex, Typography } from 'antd';
import styled from 'styled-components';

import ExampleShopImage from 'static/images/example_shop_image.png';

import { useTheme } from '../../../../hooks/useTheme';
import { ProductImage } from '../../components/Product/ProductImage';
import { Size, useWindowSize } from '../../../../hooks/useWindowSize';
import { Copy } from '../../components/Copy';
import { AddTelegramAccount } from '../../../user/components/TelegramAccounts/components/AddTelegramAccount';

export const ExampleShopCard = () => {
    const { boxShadow, colorPrimary } = useTheme();
    const { size } = useWindowSize();

    return (
        <Card
            type='inner'
            style={{ boxShadow, marginBottom: '1.5rem', position: 'relative' }}
        >
            <StyledOverlay>
                <AddTelegramAccount isAccountPrimary={true} />
            </StyledOverlay>
            <Flex wrap={'wrap'} justify={'space-between'} gap={'1.5rem'}>
                <Col md={4} xs={24}>
                    <ProductImage gutter={0} src={ExampleShopImage} />
                </Col>

                <Col
                    md={19}
                    xs={24}
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexDirection: 'column',
                    }}
                >
                    <Flex
                        vertical
                        style={{
                            marginBottom: size === Size.small ? '1.5rem' : 0,
                        }}
                    >
                        <Typography.Title style={{ margin: 0 }} level={2}>
                            Айва
                        </Typography.Title>

                        <Typography.Text>
                            Проявите свою индивидуальность
                        </Typography.Text>
                    </Flex>

                    <Flex
                        vertical={false}
                        justify={'space-between'}
                        align={'center'}
                        wrap={'wrap'}
                    >
                        <div
                            style={{
                                marginBottom:
                                    size === Size.small ? '1.5rem' : 0,
                            }}
                        >
                            <Flex align={'center'} gap={5}>
                                <Typography.Text
                                    style={{
                                        minWidth: 'fit-content',
                                    }}
                                    strong
                                >
                                    Общая ссылка:
                                </Typography.Text>
                                <Copy text='https://t.me/Aiwa' />
                            </Flex>

                            <Flex vertical={false} align={'center'} gap={5}>
                                <Typography.Text
                                    style={{
                                        minWidth: 'fit-content',
                                    }}
                                    strong
                                >
                                    Ссылка на магазин:
                                </Typography.Text>
                                <Copy text='@SpodialBot_Aiwa' />
                            </Flex>
                        </div>
                    </Flex>
                </Col>
            </Flex>
        </Card>
    );
};

const StyledOverlay = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: #eaeaea99;
    z-index: 999;

    display: flex;
    justify-content: center;
    align-items: center;
`;

const StyledActions = styled(Col)`
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
`;
