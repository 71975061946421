import PaymentTinkoff from '../../../../static/images/payment_tinkoff.svg';
import PaymentMC from '../../../../static/images/payment_mc.svg';
import PaymentMIR from '../../../../static/images/payment_mir.svg';
import PaymentVISA from '../../../../static/images/payment_visa.svg';
import telegramOutlined from '../../../../static/images/telegram-outlined.svg';
import { telegramHelper } from '../../../contants/settings';
import mailOutlined from '../../../../static/images/mail-outlined.svg';

export const links = [
    {
        name: 'Пользовательское соглашение',
        href: '/user_agreement',
    },

    {
        name: 'Политика обработки персональных данных и файлов Cookie',
        href: '/privacy_policy',
    },

    {
        name: 'Согласие на обработку моих персональных данных',
        href: '/personal-data-agreement',
    },

    {
        name: 'Правила совершения платежей и возврата оплаты',
        href: '/payment_rules',
    },

    {
        name: 'Оферта',
        href: '/oferta',
    },
];

export const payments = [
    {
        src: PaymentTinkoff,
        alt: 'Tinkoff',
    },

    {
        src: PaymentMC,
        alt: 'Master Card',
    },

    {
        src: PaymentMIR,
        alt: 'MIR',
    },

    {
        src: PaymentVISA,
        alt: 'VISA',
    },
];

export const contacts = [
    {
        name: 'Telegram помощник',
        src: telegramOutlined,
        alt: 'Telegram',
        href: telegramHelper,
    },
    {
        name: 'contact@spodial.com',
        src: mailOutlined,
        alt: 'Telegram',
        href: 'mailto:info@spodial.com',
    },
    /*{
        name: '8 800 101-42-62',
        src: phoneOutlined,
        alt: 'Phone',
        href: 'tel:8 800 101-42-62',
    },*/
];
