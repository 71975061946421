import React, { useEffect, useState } from 'react';
import { Typography } from 'antd';

export const Benefits = props => {
    const { source } = props;
    const [benefits, setBenefits] = useState([]);

    useEffect(() => {
        if (source) {
            setBenefits(JSON.parse(source));
        }
    }, [source]);

    return (
        <>
            {benefits?.map((benefit, index) => (
                <Typography.Text key={index}>{benefit.name}</Typography.Text>
            ))}
        </>
    );
};

/*[{"name": "Создайте в своем магазине до 50 товаров","description" : null},{"name": "Модули модерация, платный доступ, донаты включены в тариф ограничение до 3-х чатов", "description": null},
{"name": "Размещайте данные объёмом трафика для хранения медиа - 10 ГБ и 10 постов в мес.","description": null},
{ "name" : "Комиссия при выводе денежных средств: ", "description": "7%" } ]*/
