import React from 'react';
import { Form, Input, InputNumber, Select, Switch } from 'antd';

import { FeedFormProps, FeedTypes } from '../../interfaces/shop.interface';

const options: Array<{ value: FeedTypes; label: string }> = [
    {
        value: FeedTypes.yml,
        label: 'Яндекс',
    },
    {
        value: FeedTypes.csvGoogleMerchant,
        label: 'Google Merchant',
    },
];

export const FeedForm = (props: FeedFormProps) => {
    const { form, initial } = props;

    return (
        <Form
            wrapperCol={{
                xs: 24,
                md: 16,
            }}
            form={form}
            name='feeds'
            initialValues={initial}
            layout={'horizontal'}
            autoComplete='off'
            requiredMark={false}
        >
            <Form.Item
                labelCol={{
                    span: 24,
                }}
                label='Тип фида'
                name='type'
                rules={[
                    {
                        required: true,
                        message: '',
                    },
                ]}
            >
                <Select
                    style={{
                        maxWidth: '283px',
                    }}
                    placeholder='Выберите формат фида'
                    options={options}
                />
            </Form.Item>
            <Form.Item
                labelCol={{
                    span: 24,
                }}
                rules={[
                    {
                        required: true,
                        message: '',
                    },
                ]}
                label='Ссылка на товарный фид'
                name='feed_url'
            >
                <Input required placeholder={'Укажите ссылку на фид'} />
            </Form.Item>

            <Form.Item
                labelCol={{
                    span: 24,
                }}
                rules={[
                    {
                        required: true,
                        message: '',
                    },
                ]}
                label='Частота обновления (часы)'
                name='feed_frequency'
            >
                <InputNumber required maxLength={5} max={99999} min={1} />
            </Form.Item>

            <Form.Item
                label={'Активировать фид'}
                valuePropName='checked'
                style={{ marginBottom: 0 }}
                name='is_active'
            >
                <Switch defaultChecked />
            </Form.Item>
        </Form>
    );
};
