import { usersTabService } from 'features/admin/services/usersTab.service';
import { feedbackTabService } from 'features/admin/services/feedbackTab.service';
import { paymentsTabService } from 'features/admin/services/paymentsTab.service';
import { communitiesTabService } from 'features/admin/services/communitiesTab.service';

export const adminApi = [
    usersTabService.middleware,
    feedbackTabService.middleware,
    paymentsTabService.middleware,
    communitiesTabService.middleware,
];

export const adminReducers = {
    [usersTabService.reducerPath]: usersTabService.reducer,
    [feedbackTabService.reducerPath]: feedbackTabService.reducer,
    [paymentsTabService.reducerPath]: paymentsTabService.reducer,
    [communitiesTabService.reducerPath]: communitiesTabService.reducer,
};
