import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import qs from 'query-string';
import dayjs from 'dayjs';

import getEnvConfig from 'utils/config/env';

import {
    IStatisticFinance,
    IStatisticFinanceHistory,
    IStatisticMessages,
    IStatisticModeration,
    IStatisticsMembers,
} from './interfaces';

export const analyticsApi = createApi({
    reducerPath: 'analytics',
    baseQuery: fetchBaseQuery({
        baseUrl: getEnvConfig().env.API_URL,
        prepareHeaders: headers => {
            const token = localStorage.getItem('accessToken');
            headers.set('Authorization', `Bearer ${token}`);
            return headers;
        },
    }),
    tagTypes: ['Subscription'],
    endpoints: builder => ({
        getMembers: builder.query<
            IStatisticsMembers,
            {
                period: string;
                community_id?: number;
            }
        >({
            query: args => {
                const queryArgs = qs.stringify(args, {
                    arrayFormat: 'bracket-separator',
                    skipEmptyString: true,
                    skipNull: true,
                });
                return {
                    url: `/statistic/members?${queryArgs}`,
                    method: 'GET',
                };
            },

            transformResponse: (response: {
                data: Omit<IStatisticsMembers, 'series'> & { series: any };
            }) => {
                const { series, members, ...rest } = response.data;
                return {
                    ...rest,
                    members: members.map(m => ({
                        ...m,
                        image: m.image
                            ? `${getEnvConfig().env.API_URL.replace(
                                  /api\/v3/,
                                  ''
                              )}${m.image}`
                            : null,
                    })),
                    series: series[0].map(s => ({
                        users: s.users,
                        timestamp: dayjs.unix(s.scale_unix).valueOf(),
                    })),
                };
            },
        }),
        getMessages: builder.query<IStatisticMessages, any>({
            query: args => {
                const queryArgs = qs.stringify(args, {
                    arrayFormat: 'bracket',
                    skipNull: true,
                });
                return {
                    url: `/statistic/messages/charts?${queryArgs}`,
                    method: 'GET',
                };
            },
            transformResponse: (response: { data: IStatisticMessages }) => {
                return response.data;
            },
        }),
        getSemantics: builder.query<IStatisticMessages, any>({
            query: args => {
                const queryArgs = qs.stringify(args);
                return {
                    url: `/statistic/semantic/charts?${queryArgs}`,
                    method: 'GET',
                };
            },
            transformResponse: (response: { data: IStatisticMessages }) => {
                return response.data;
            },
        }),
        getModeration: builder.query<
            IStatisticModeration,
            {
                period: string;
                community_id?: number;
            }
        >({
            query: args => {
                const queryArgs = qs.stringify(args, {
                    arrayFormat: 'bracket-separator',
                    skipEmptyString: true,
                    skipNull: true,
                });
                return {
                    url: `/statistic/moderation/users?${queryArgs}`,
                    method: 'GET',
                };
            },
            transformResponse: (response: any) => {
                return response.data;
            },
        }),
        getFinancePaymentHistory: builder.query<
            IStatisticFinanceHistory[],
            any
        >({
            query: args => {
                const queryArgs = qs.stringify(args, {
                    skipEmptyString: true,
                    skipNull: true,
                });
                return {
                    url: `/statistic/payments-list?${queryArgs}`,
                    method: 'GET',
                };
            },
            transformResponse: (response: {
                data: IStatisticFinanceHistory[];
            }) => {
                return response.data.map(d => ({
                    ...d,
                    photo_url: d.photo_url
                        ? `${getEnvConfig().env.API_URL.replace(
                              /api\/v3/,
                              ''
                          )}${d.photo_url}`
                        : null,
                    buy_date: dayjs(
                        d.buy_date,
                        'YYYY-MM-DD HH:mm:ss'
                    ).valueOf(),
                }));
            },
        }),
        getFinance: builder.query<
            IStatisticFinance,
            {
                period: string;
                community_id?: number;
            }
        >({
            query: args => {
                const queryArgs = qs.stringify(args, {
                    arrayFormat: 'bracket-separator',
                    skipEmptyString: true,
                    skipNull: true,
                });
                return {
                    url: `/statistic/payments-charts?${queryArgs}`,
                    method: 'GET',
                };
            },
            transformResponse: (response: any) => {
                // return response;
                return {
                    balance: response.items.balance,
                    course: response.items.course_balance,
                    donate: response.items.donate_balance,
                    rate: response.items.tariff_balance,
                    labels: response.meta.marks.map(label =>
                        dayjs(label.split(' ')[0]).valueOf()
                    ),
                    all: response.meta.all,
                };
            },
        }),
    }),
});

export const {
    useGetMembersQuery,
    useGetFinanceQuery,
    useGetFinancePaymentHistoryQuery,
    useGetMessagesQuery,
    useGetSemanticsQuery,
    useGetModerationQuery,
} = analyticsApi;
