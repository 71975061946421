import React, { FC } from 'react';

import { UsersContainer } from './UsersContainer';
import { Download, Type } from '../Common/Download';

export const UsersTab: FC = () => {
    return (
        <>
            <Download type={Type.users} />
            <UsersContainer />
        </>
    );
};
