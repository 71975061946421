import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Button } from 'antd';
import { DownOutlined } from '@ant-design/icons';

import { Dropdown } from '../../../../shared/ui/Dropdown';
import { downloadFile } from '../../../../utils/common/downloadFile';

type TDownload = {
    type: Type;
};

export enum Type {
    users = 'users',
    payments = 'payments',
    communities = 'communities',
    feedback = 'feedback',
}
export const Download: React.FC<TDownload> = props => {
    return (
        <StyledActions>
            <StyledDropdown
                dropdownContent={() => (
                    <Fragment>
                        <Button
                            type='text'
                            onClick={() => {
                                downloadFile(
                                    `/manager/export/${props.type}`,
                                    'xlsx',
                                    {
                                        type: 'xlsx',
                                    }
                                );
                            }}
                        >
                            XLSX
                        </Button>
                        <Button
                            type='text'
                            onClick={() => {
                                downloadFile(
                                    `/manager/export/${props.type}`,
                                    'csv',
                                    {
                                        type: 'csv',
                                    }
                                );
                            }}
                        >
                            CSV
                        </Button>
                    </Fragment>
                )}
            >
                <Button type='default'>
                    Скачать <DownOutlined />
                </Button>
            </StyledDropdown>
        </StyledActions>
    );
};

const StyledActions = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
`;

const StyledDropdown = styled(Dropdown)`
    cursor: pointer;
`;
