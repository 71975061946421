import React, { useEffect, useState } from 'react';
import { App } from 'antd';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';

import { ShopValues } from '../../interfaces/shop.interface';
import { createFormData } from '../../../../utils/common/createFormData';
import { useUpdateShopMutation } from '../../services/shops';
import { SAVE } from '../../../../shared/contants/actions';
import { ShopForm } from '../../views/Shops/ShopForm';
import {
    useCreateUnitPayKeysMutation,
    useDeleteUnitPayKeysMutation,
    useLazyReadUnitPayKeysQuery,
} from '../../../user/services';
import { getShopState } from '../../store/shopSlice';

export const ShopEditContainer = props => {
    const { notification } = App.useApp();

    const { open, setOpen, isSuccess, shopId } = props;
    const shopInfo = (({ name, about, buyable, price_from, photo }) => ({
        name,
        about,
        buyable,
        photo,
        price_from,
    }))(useSelector(getShopState));

    const [updateShop, { isSuccess: isSuccessUpdate }] =
        useUpdateShopMutation();
    const [
        readShopKeys,
        {
            data: shopKeys,
            isSuccess: successReadKeys,
            isLoading: loadingReadKeys,
        },
    ] = useLazyReadUnitPayKeysQuery();
    const [createShopKeys] = useCreateUnitPayKeysMutation();
    const [deleteShopKeys] = useDeleteUnitPayKeysMutation();

    const onSave = async (values: ShopValues) => {
        const data = {
            id: +shopId,
            name: values.name,
            about: values.about,
            buyable: values.buyable,
            photo: values.photo,
            price_from: values.price_from,
        };
        if (!(values.photo instanceof File)) {
            delete data.photo;
        }
        if (values.about === null) {
            delete data.about;
        }

        if (values.buyable) {
            const data = {
                project_id: values.project_id,
                secretKey: values.secretKey,
                shop_id: +shopId,
            };
            await createShopKeys(data);
        } else await deleteShopKeys();

        await updateShop({ id: +shopId, body: createFormData(data) });
    };

    useEffect(() => {
        if (isSuccessUpdate) {
            notification.success({
                message: 'Магазин успешно изменен',
            });
            setOpen(false);
        }
    }, [isSuccessUpdate]);

    useEffect(() => {
        if (open) {
            readShopKeys({ shop_id: +shopId }, false);
        }
    }, [open]);

    if (!shopInfo?.name && !isSuccess && !successReadKeys) {
        return <></>;
    }

    return (
        isSuccess &&
        open && (
            <ShopForm
                isEdit
                title={SAVE}
                setOpen={setOpen}
                open={open}
                initialValues={{
                    ...shopInfo,
                    ...shopKeys,
                }}
                onSave={onSave}
                loadingReadKeys={loadingReadKeys}
                shopKeys={shopKeys}
                successReadKeys={successReadKeys}
            />
        )
    );
};
