import React, { FC, Fragment } from 'react';
import styled from 'styled-components';
import { Badge, Button, List } from 'antd';
import { Link } from 'react-router-dom';

import getEnvConfig from 'utils/config/env';
import Percent from 'static/landing/percent.png';
import StaticText from 'shared/layouts/LandingLayout/content.json';

import { ScreenProps } from '../types/landing.interface';
import { getCurrentMonth, getLastDay } from '../utils/dateCalculate';

export const Tariff: FC<ScreenProps> = ({ refProp }) => {
    const data = [
        'Управление магазином в Telegram',
        '(декабрь 2024) Telegram-витрина для вашего магазина Wildberries',
        'Возможность напрямую отправлять сообщения покупателям',
    ];

    return (
        <Fragment>
            <StyledSectionFourth ref={refProp}>
                <div>
                    <SectionFourthTitle>
                        {StaticText.tariff.title}
                        <StyledTitleSpan>
                            до {getLastDay()} {getCurrentMonth()}
                        </StyledTitleSpan>
                    </SectionFourthTitle>
                </div>
                <StyledCardsWrapper>
                    <StyledCard>
                        <StyledInnerWrap>
                            <div style={{ paddingRight: 50 }}>
                                <StyledBadge>Акция</StyledBadge>
                                <CardTitle>Гарантийный период!</CardTitle>

                                <CardText>
                                    Если в течении 10 дней использования вам не
                                    понравится наш продукт, мы вернём вам деньги
                                </CardText>
                            </div>
                            <div>
                                <StyledImg src={Percent} />
                            </div>
                        </StyledInnerWrap>

                        <StyledInnerWrap>
                            <div>
                                <CardGrayText>
                                    Акция действует до {getLastDay()}{' '}
                                    {getCurrentMonth()}
                                </CardGrayText>
                            </div>
                        </StyledInnerWrap>
                    </StyledCard>
                    <StyledCard>
                        <CardTitle>Тариф &quot;Стандарт&quot;</CardTitle>

                        <List>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 4,
                                }}
                            >
                                {data.map((elem, index) => (
                                    <StyledListRow key={index}>
                                        <Badge color={'purple'} />
                                        <CardText>{elem}</CardText>
                                    </StyledListRow>
                                ))}
                            </div>
                        </List>

                        <ActionBlock>
                            <div>
                                <Link
                                    to={`${
                                        getEnvConfig().PUBLIC_PATH
                                    }/auth/sign-up?reason=price`}
                                >
                                    <Button>Купить</Button>
                                </Link>
                            </div>
                        </ActionBlock>
                    </StyledCard>
                </StyledCardsWrapper>
            </StyledSectionFourth>
        </Fragment>
    );
};

const StyledImg = styled.img`
    height: 255px;
`;

const StyledInnerWrap = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 8px;

    @media (max-width: 768px) {
        justify-content: center;
        flex-direction: column;
    }
`;

const StyledBadge = styled.div`
    padding: 4px 8px;
    border-radius: 40px;
    border: 1px solid #6257d2;
    color: #6257d2;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    width: 100px;
    height: 32px;
    margin-bottom: 16px;
`;

const StyledListRow = styled.div`
    display: flex;
    gap: 16px;
`;

const Price = styled.div`
    color: rgba(37, 33, 41, 0.88);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
`;

const CardGrayText = styled.div`
    color: rgba(37, 33, 41, 0.45);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
`;

const ActionBlock = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: auto;
    gap: 8px;
`;

const CardText = styled.div`
    display: flex;
    justify-content: space-between;
    color: rgba(37, 33, 41, 0.88);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    width: 100%;
`;

const CardTitle = styled.div`
    color: #6257d2;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    margin-bottom: 24px;
`;

const StyledCard = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 40px;
    background: #fff;
    box-shadow: 0 9px 28px 8px rgba(0, 0, 0, 0.05),
        0 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px 0px rgba(0, 0, 0, 0.08);
    padding: 32px 24px 32px 24px;
    width: 570px;
    height: 360px;

    @media (max-width: 768px) {
        width: 100%;
        height: auto;
    }
`;

const StyledCardsWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
    flex-wrap: wrap;
`;

const StyledTitleSpan = styled.span`
    font-family: 'Unbounded', sans-serif;
    border-radius: 40px;
    background: #fff;
    padding: 9px 16px;
    color: #6257d2;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 23px;
    margin-left: 5px;
    @media (max-width: 768px) {
        margin-top: 1rem;
    }
`;

const SectionFourthTitle = styled.div`
    font-family: 'Unbounded', sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 38px;
    line-height: 46px;
    color: #fff;
    margin-bottom: 50px;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

const StyledSectionFourth = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: #6257d2;
    border-radius: 40px;
    margin-bottom: 80px;
    padding: 70px;
`;
