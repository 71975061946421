import React, { useEffect } from 'react';
import { App, Button, Flex, Spin, Typography } from 'antd';
import { useSearchParams } from 'react-router-dom';

import { useLazyReadYookassaQuery } from '../../services/shops';
import { Page } from '../../../../shared/ui/Page';
import { botName, webAppName } from '../../../../utils/common/botInfo';

const UkassaContainer = () => {
    const { notification } = App.useApp();
    const [searchParams] = useSearchParams();
    const [getYookassa, { data, isSuccess, isError, isLoading, error }] =
        useLazyReadYookassaQuery();

    const payload = {
        code: searchParams.get('code'),
        state: searchParams.get('state'),
    };

    useEffect(() => {
        if (payload) getYookassa(payload, true);
    }, []);

    useEffect(() => {
        if (isError) {
            return notification.error({
                message: error?.data?.message || 'Ошибка',
            });
        }
    }, [isError]);

    const onClick = () => {
        window.location.href = `https://t.me/${botName}/${webAppName()}/?startapp=my-shop`;
    };

    return (
        <Page
            title={
                isLoading
                    ? null
                    : isError
                    ? 'Ошибка при настройке'
                    : 'Юкасса настроена!'
            }
        >
            {isLoading ? (
                <Spin />
            ) : (
                <Flex vertical gap={'1.5rem'}>
                    {isError ? null : (
                        <Typography.Text>
                            Вы успешно интегрировали Юкассу для оплаты в
                            магазине.
                        </Typography.Text>
                    )}
                    <div>
                        <Button
                            onClick={onClick}
                            disabled={!isSuccess || isLoading || isError}
                            type={'primary'}
                        >
                            Завершить
                        </Button>
                    </div>
                </Flex>
            )}
        </Page>
    );
};

export default UkassaContainer;
