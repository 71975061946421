import path from 'path';

import { RouteObject } from 'react-router';
import { Outlet } from 'react-router-dom';

import { ShopsContainer } from './containers/Shops/ShopsContainer';
import { ShopContainer } from './containers/Shops/ShopContainer';
import { ProductCreateContainer } from './containers/Products/ProductCreateContainer';
import { ProductEditContainer } from './containers/Products/ProductEditContainer';
import { ProductsContainer } from './containers/Products/ProductsContainer';
import { ProductsArchiveContainer } from './containers/Products/ProductsArchiveContainer';
import UkassaContainer from './containers/Payments/UkassaContainer';
import OrdersContainer from './containers/Orders/OrdersContainer';
import { FeedsContainer } from './containers/Feeds/FeedsContainer';
import { FeedsHistoryContainer } from './containers/Feeds/FeedsHistoryContainer';
import { FeedsSettingsContainer } from './containers/Feeds/FeedsSettingsContainer';
import { PromocodesContainer } from './containers/Promocodes/PromocodesContainer';
import { OrdersModal } from './containers/Orders/OrdersModal';
import { BannersListContainer } from './containers/Banners/BannersListContainer';
import { BannerCreateContainer } from './containers/Banners/BannerCreateContainer';
import { BannerEditContainer } from './containers/Banners/BannersEditContainer';
import { CategoriesListContainer } from './containers/Categories/CategoriesListContainer';
import { ProductsLinksContainer } from './containers/Products/ProductsLinksContainer';
import { IntegrationEmailEditContainer } from './containers/IntegrationEmail/IntegrationEmailEditContainer';
import { PaymentSettingsContainer } from './containers/Payments/PaymentSettingsContainer';

const routes: RouteObject[] = [
    {
        path: 'shops',
        element: <ShopsContainer />,
    },
    {
        path: 'shops/:id',
        element: <ShopContainer />,
        children: [
            {
                path: 'products',
                element: <ProductsContainer />,
            },
            {
                path: 'archive',
                element: <ProductsArchiveContainer />,
            },
            {
                path: 'links',
                element: <ProductsLinksContainer />,
            },
            {
                path: 'banners',
                element: <BannersListContainer />,
            },
            {
                path: 'orders',
                element: <OrdersContainer />,
                children: [
                    {
                        path: ':orderId',
                        element: <OrdersModal />,
                    },
                ],
            },
            {
                path: 'categories',
                element: <CategoriesListContainer />,
                children: [
                    {
                        path: ':orderId',
                        element: <OrdersModal />,
                    },
                ],
            },
        ],
    },
    {
        path: 'shops/:id/feeds',
        element: <Outlet />,
        children: [{ path: '', element: <FeedsContainer /> }],
    },
    {
        path: 'shops/:id/promocodes',
        element: <Outlet />,
        children: [{ path: '', element: <PromocodesContainer /> }],
    },
    {
        path: 'shops/:id/payment-settings',
        element: <Outlet />,
        children: [{ path: '', element: <PaymentSettingsContainer /> }],
    },
    {
        path: 'shops/:id/banners/create',
        element: <BannerCreateContainer />,
    },
    {
        path: 'shops/:id/banners/edit/:bannerId',
        element: <BannerEditContainer />,
    },
    {
        path: 'shops/:id/product-create',
        element: <ProductCreateContainer />,
    },
    {
        path: 'shops/:id/link-create',
        element: <ProductCreateContainer />,
    },
    {
        path: 'shops/:id/product-edit/:id',
        element: <ProductEditContainer />,
    },
    {
        path: 'shops/:id/link-edit/:id',
        element: <ProductEditContainer />,
    },
    {
        path: 'shops/:id/integration-email',
        element: <IntegrationEmailEditContainer />,
    },
];
export default routes;
