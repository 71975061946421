import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Checkbox, Flex, Typography } from 'antd';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { getCategoryState } from '../../store/categorySlice';
import {
    useDeleteProductCategoryMutation,
    useLazyReadProductCategoryQuery,
    useUpdateProductCategoryPositionMutation,
} from '../../services/categories';
import { CategoryEditorModal } from '../../views/Categories/CategoryEditorModal';
import { CategoriesTreeList } from '../../views/Categories/CategoriesTreeList';
import { deepGetObjectById } from '../../../../utils/common/deepGetObjectById';

export const CategoriesListContainer = () => {
    const { id } = useParams();

    const { nestedCategoryList } = useSelector(getCategoryState);
    const [selectedCategoryId, setSelectedCategoryId] = useState(null);
    const [updatingCategoryId, setUpdatingCategoryId] = useState(null);
    const [openEditor, setOpenEditor] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [categoryName, setCategoryName] = useState('');

    const [updateCategory, { isLoading: isUpdateCategoryLoading }] =
        useUpdateProductCategoryPositionMutation();
    const [getCategories, { isLoading: isGetCategoriesLoading }] =
        useLazyReadProductCategoryQuery();
    const [deleteCategory, { isLoading: isLoadingDelete }] =
        useDeleteProductCategoryMutation();

    useEffect(() => {
        if (id) {
            getCategories({ shop_id: +id });
        }
    }, [id]);

    const editableNestedCategoryList = useMemo(() => {
        return nestedCategoryList.filter(
            category => category.id !== null && category.id !== 0
        );
    }, [nestedCategoryList]);

    const onUpdatePosition = async body => {
        setUpdatingCategoryId(body.category_id);
        await updateCategory({
            ...body,
        }).then(data => {
            getCategories({ shop_id: +id }).then(() => {
                setUpdatingCategoryId(null);
            });
        });
    };

    const onSelectCategory = (categoryList: number[]) => {
        const [categoryId] = categoryList;
        setSelectedCategoryId(categoryId || null);
    };

    const onDeleteCategory = async () => {
        setUpdatingCategoryId(selectedCategoryId);
        if (selectedCategoryId && typeof selectedCategoryId === 'number') {
            await deleteCategory(selectedCategoryId).then(() => {
                setSelectedCategoryId(null);
                setUpdatingCategoryId(null);
            });
        }
    };
    const onRenameCategory = event => {
        event.stopPropagation();
        setCategoryName(
            deepGetObjectById(nestedCategoryList, selectedCategoryId)?.name
        );
        setOpenEditor(true);
        setIsEdit(true);
    };

    return (
        <Fragment>
            <Flex justify={'space-between'} wrap={'wrap'} gap={20}>
                <Button
                    disabled={false}
                    type={'primary'}
                    onClick={() => {
                        setIsEdit(false);
                        setOpenEditor(!openEditor);
                    }}
                >
                    {selectedCategoryId > 0
                        ? 'Добавить подкатегорию'
                        : 'Добавить категорию'}
                </Button>
            </Flex>

            <Flex>
                <StyledSelectorColumn>
                    {selectedCategoryId !== null && (
                        <Checkbox
                            indeterminate={true}
                            onChange={() => setSelectedCategoryId(null)}
                        />
                    )}
                </StyledSelectorColumn>
                <StyledTitleColumn>
                    <Typography.Text strong>Название категории</Typography.Text>
                </StyledTitleColumn>
                <StyledActionsColumn>
                    <Typography.Text strong>Действия</Typography.Text>
                </StyledActionsColumn>
            </Flex>

            <CategoriesTreeList
                categoryItems={editableNestedCategoryList}
                updatingCategoryId={updatingCategoryId}
                selectedCategoryId={selectedCategoryId}
                isListUpdating={
                    isUpdateCategoryLoading ||
                    isGetCategoriesLoading ||
                    isLoadingDelete
                }
                onUpdatePosition={onUpdatePosition}
                onSelectCategory={onSelectCategory}
                onDeleteCategory={onDeleteCategory}
                onRenameCategory={onRenameCategory}
            />

            <CategoryEditorModal
                open={openEditor}
                setOpen={setOpenEditor}
                categoryName={categoryName}
                categoryId={selectedCategoryId}
                setCategoryName={setCategoryName}
                isEdit={isEdit}
                setIsEdit={setIsEdit}
                parentId={selectedCategoryId}
            >
                <Typography.Text>
                    Внимание! Подкатегории можно перемещать в области своей
                    категории.
                </Typography.Text>
                {isEdit && (
                    <Typography.Text>
                        При наличии подключенного фида все изменения имен
                        категорий вернется к значениям в фиде.
                    </Typography.Text>
                )}
            </CategoryEditorModal>
        </Fragment>
    );
};

const StyledSelectorColumn = styled.div`
    flex: 0 0 44px;
`;

const StyledTitleColumn = styled.div`
    flex: 1 1 0px;
`;

const StyledActionsColumn = styled.div`
    flex: 0 0 252px;
`;
