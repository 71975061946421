import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

import getEnvConfig from 'utils/config/env';

import { IFinance, IFinanceResponse } from './finance.interface';

export interface IFinanceStatistic {
    course: number;
    donate: number;
    summ: number;
    tariff: number;
}

export const financeService = createApi({
    reducerPath: 'finance',
    baseQuery: fetchBaseQuery({
        baseUrl: getEnvConfig().env.API_URL,
        prepareHeaders: headers => {
            const token = localStorage.getItem('accessToken');
            headers.set('Authorization', `Bearer ${token}`);
            return headers;
        },
    }),
    endpoints: build => ({
        getPaymentsStatistic: build.query<IFinanceStatistic, any>({
            query: () => ({
                method: 'GET',
                url: '/statistic/payments-all-time',
            }),
            transformResponse: res => res.data,
        }),
        getPayoutInfo: build.query<any, any>({
            query: () => ({
                method: 'GET',
                url: '/payout/cards-and-accumulation',
            }),
            transformResponse: res => res.data,
        }),
        getPaymentsList: build.query<any, any>({
            query: params => ({
                method: 'GET',
                url: '/statistic/payments-payouts',
                params,
            }),
            transformResponse: (res: IFinance, meta) => {
                return {
                    items: res.data,
                    total:
                        Number(meta.response.headers.get('items-count')) || 0,
                };
            },
        }),
        payout: build.mutation<any, any>({
            query: body => ({
                method: 'POST',
                url: '/payout',
                body,
            }),
        }),
    }),
});

export const {
    useGetPaymentsStatisticQuery,
    useGetPaymentsListQuery,
    useGetPayoutInfoQuery,
    usePayoutMutation,
} = financeService;
