import { Drawer, GlobalToken, Space } from 'antd';
import React, { useState } from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { MenuOutlined } from '@ant-design/icons';

import Logo from 'static/images/logo.png';
import { Container } from 'shared/ui/Container';
import { useTheme } from 'hooks/useTheme';
import getEnvConfig from 'utils/config/env';

import { Feedback } from '../../AppLayout/components/Navbar/components/Feedback';
import UserMenu from '../../AppLayout/components/Navbar/components/UserMenu';
import { useProfileData } from '../../../../hooks/useProfileData';
import { Size, useWindowSize } from '../../../../hooks/useWindowSize';

export const Navbar: React.FC = () => {
    const [open, setOpen] = useState(false);

    const token = useTheme();
    const { user } = useProfileData();
    const { size } = useWindowSize();

    const path = `${getEnvConfig().COURSES_PATH}`;
    const isAuthor = !!user?.author_fields;

    const menuItems = [
        {
            name: 'Вы смотрите',
            path: `${path}/member`,
        },
        {
            name: 'Страница автора',
            path: `${
                isAuthor
                    ? `${path}/author/${user?.author_fields?.id}`
                    : 'author/create'
            }`,
        },
        isAuthor && {
            name: 'Аналитика',
            path: `${path}/analytics`,
        },
        isAuthor && {
            name: 'Рекомендации',
            path: `${path}/recommendation`,
        },
    ];

    const handleMenuToggle = () => {
        setOpen(!open);
    };

    return (
        <StyledWrapper>
            <Container>
                <StyledNav>
                    {size === Size.small ? (
                        <>
                            <StyledMobileBurger onClick={handleMenuToggle}>
                                <MenuOutlined />
                            </StyledMobileBurger>

                            <Drawer
                                placement='left'
                                onClose={handleMenuToggle}
                                open={open}
                            >
                                <StyledMobileMenu>
                                    {menuItems.map((menu, index) => (
                                        <StyledLink
                                            key={index}
                                            token={token}
                                            to={`${menu.path}`}
                                            onClick={handleMenuToggle}
                                        >
                                            {menu.name}
                                        </StyledLink>
                                    ))}
                                </StyledMobileMenu>
                            </Drawer>
                        </>
                    ) : (
                        <>
                            <StyledLogo to='/'>
                                <img height={22} alt='logo' src={Logo} />
                            </StyledLogo>
                            <StyledTabs>
                                {menuItems.map((menu, index) => (
                                    <StyledLink
                                        key={index}
                                        token={token}
                                        to={`${menu.path}`}
                                    >
                                        {menu.name}
                                    </StyledLink>
                                ))}
                            </StyledTabs>
                        </>
                    )}

                    <div>
                        <Space>
                            <Feedback />
                            <UserMenu />
                        </Space>
                    </div>
                </StyledNav>
            </Container>
        </StyledWrapper>
    );
};

const StyledWrapper = styled.div`
    border-bottom: 1px solid rgba(37, 33, 41, 0.06);
`;

const StyledNav = styled.nav`
    user-select: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 24px;
    padding-bottom: 6px;
`;

const StyledTabs = styled.div`
    font-size: 14px;
    color: rgba(37, 33, 41, 0.25);
    display: flex;
    justify-content: center;
    gap: 40px;

    @media (max-width: 600px) {
        display: none;
    }
`;

const StyledLogo = styled(NavLink)`
    display: flex;
    @media (max-width: 600px) {
        display: none;
    }
`;

const StyledLink = styled(NavLink)<{ token: GlobalToken; $active?: boolean }>`
    padding-bottom: 8px;
    position: relative;
    color: ${props => props.token.colorTextBase};

    &.active {
        &:after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: -10px;
            border-bottom: 2px solid ${props => props.token.colorPrimary};
        }
    }
`;

const StyledMobileMenu = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 26px;
`;

const StyledMobileBurger = styled.div`
    display: none;

    @media (max-width: 992px) {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
`;
