import { useEffect, useState } from 'react';

import { SubscriptionProps } from 'features/subscriptions/interfaces';
import { IUser } from 'features/user/interfaces';
import { useReadSubscriptionsQuery } from 'features/subscriptions/services';

import { getUserSubscription } from './helpers';
import { useReadUserQuery } from '../../features/user/services';

export const useProfileData = () => {
    const [user, setUser] = useState<IUser>();
    const [subscriptions, setSubscriptions] = useState<SubscriptionProps[]>();
    const [subscription, setSubscription] = useState<SubscriptionProps>();

    const {
        data: userData,
        refetch,
        error,
        isLoading: isLoadingUser,
    } = useReadUserQuery();
    const { data: subscriptionsData, isLoading: isLoadingSubscription } =
        useReadSubscriptionsQuery();

    useEffect(() => {
        if (userData && subscriptionsData) {
            setSubscription(getUserSubscription(userData, subscriptionsData));
        }
        setUser(userData);
        setSubscriptions(subscriptionsData);
    }, [userData, subscriptionsData]);

    return {
        user,
        subscriptions,
        subscription,
        refetch,
        error,
        isLoadingSubscription,
        isLoadingUser,
    };
};
