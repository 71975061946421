import React from 'react';
import { Button, Space, Typography } from 'antd';

import { useCreateLegalBillMutation } from '../../../user/services/legalEntity';
import { useToggle } from '../../../../hooks/useToggle';
import { LegalEditContainer } from '../../../user/components/LegalEntity/containers/LegalEditContainer';
import { LegalCreateContainer } from '../../../user/components/LegalEntity/containers/LegalCreateContainer';
import Secondary from '../../../../shared/ui/SecondaryText';

export const OnboardingStepLegal = props => {
    const { loadingReadLegal, ...legalInfo } = props;

    const isLegalInfoFilled = legalInfo && legalInfo.id !== undefined;
    const { open: openLegalInfo, setOpen: setOpenLegalInfo } = useToggle();
    const [
        createLegalBill,
        {
            data,
            isLoading: loadingCreateBill,
            isSuccess: successCreateBill,
            isError: errorCreateBill,
        },
    ] = useCreateLegalBillMutation();

    return (
        <>
            {isLegalInfoFilled ? (
                <div>
                    <Typography.Paragraph style={{ marginBottom: 0 }}>
                        {legalInfo.name}
                    </Typography.Paragraph>
                    <Typography.Paragraph style={{ marginBottom: 0 }}>
                        {legalInfo.inn ? `ИНН ${legalInfo.inn}   ` : ''}
                        {legalInfo.kpp ? `КПП ${legalInfo.kpp}   ` : ''}
                        {legalInfo.ogrn ? `ОГРН ${legalInfo.ogrn}` : ''}
                    </Typography.Paragraph>
                    <Typography.Paragraph style={{ marginBottom: 0 }}>
                        {legalInfo.email}
                    </Typography.Paragraph>
                    <Typography.Paragraph style={{ marginBottom: 0 }}>
                        {legalInfo.address}
                    </Typography.Paragraph>
                    <Typography.Paragraph style={{ marginBottom: 0 }}>
                        {legalInfo.phone}
                    </Typography.Paragraph>
                    <Typography.Paragraph style={{ marginBottom: 0 }}>
                        {legalInfo.additionally
                            ? `Иное: ${legalInfo.additionally}`
                            : ''}
                    </Typography.Paragraph>
                    <br />

                    <Secondary>
                        Изменить сведения об организации доступно через
                        поддержку
                    </Secondary>
                </div>
            ) : (
                <>
                    <Space>
                        <Button
                            loading={loadingReadLegal}
                            disabled={loadingReadLegal}
                            type={'default'}
                            onClick={() => setOpenLegalInfo(true)}
                        >
                            Заполнить сведения
                        </Button>
                    </Space>
                </>
            )}
            {legalInfo?.id ? (
                <LegalEditContainer
                    {...legalInfo}
                    open={openLegalInfo}
                    setOpen={setOpenLegalInfo}
                    extraAction={createLegalBill}
                    successCreateBill={successCreateBill}
                    errorCreateBill={errorCreateBill}
                    legalBillUrl={data?.url}
                />
            ) : (
                <LegalCreateContainer
                    open={openLegalInfo}
                    setOpen={setOpenLegalInfo}
                />
            )}
        </>
    );
};
