import React from 'react';
import styled from 'styled-components';

export const Text = props => {
    return <StyledText color={props.color}>{props.children}</StyledText>;
};

const StyledText = styled.p`
    font-family: 'Unbounded', sans-serif;
    font-size: 0.875rem;
    font-style: normal;
    line-height: 1.375rem;
    color: ${props => props.color};
`;
