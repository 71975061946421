import React, { Fragment, useEffect } from 'react';
import { App } from 'antd';
import { useNavigate } from 'react-router-dom';

export const FeedNotifications = props => {
    const { notification } = App.useApp();
    const navigate = useNavigate();

    const { isSuccess, isError, error } = props;

    useEffect(() => {
        if (isSuccess) {
            notification.success({
                message: 'Фид успешно подключен',
                description: 'Загрузка может занять до суток.',
            });
            navigate(-1);
        }
    }, [isSuccess]);

    useEffect(() => {
        if (isError) {
            {
                Object.entries(error?.data.errors).map(([key, messages]) => (
                    <Fragment key={key}>
                        {messages.map((message, index) =>
                            notification.error({
                                message: message,
                            })
                        )}
                    </Fragment>
                ));
            }
        }
    }, [isError]);

    return <></>;
};
