import { Drawer, Dropdown, GlobalToken, Space } from 'antd';
import React, { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import type { MenuProps } from 'antd';
import { MenuOutlined } from '@ant-design/icons';

import Logo from 'static/images/logo.png';
import { Container } from 'shared/ui/Container';
import { useTheme } from 'hooks/useTheme';
import getEnvConfig from 'utils/config/env';
import { VirtualTourProvider } from 'shared/ui/VirtualTour/VirtualTourProvider';
import { VirtualTour } from 'shared/ui/VirtualTour/VirtualTour';

import { Feedback } from './components/Feedback';
import UserMenu from './components/UserMenu';
import { useProfileData } from '../../../../../hooks/useProfileData';
import { useWindowSize } from '../../../../../hooks/useWindowSize';
import { SubscriptionTag } from './components/Subscription';

export const Navbar: React.FC = () => {
    const token = useTheme();
    const { user } = useProfileData();
    const { windowSize, size } = useWindowSize();
    const [open, setOpen] = useState<boolean>(false);
    const [expandedMenu, setExpandedMenu] = useState<null | string>(null);

    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        if (user) {
            setIsAdmin(user?.admin);
        }
    }, [user]);

    const mainMenu = [
        /* {
            slug: 'communities',
            name: 'Сообщества',
            path: `${getEnvConfig().PUBLIC_PATH}/communities`,
        },*/
        /* {
            slug: 'monetization',
            name: 'Монетизация',
            path: `${getEnvConfig().PUBLIC_PATH}/monetization`,
            children: [
                {
                    slug: 'donate',
                    name: 'Донаты',
                    path: `${getEnvConfig().PUBLIC_PATH}/monetization/donate`,
                },
                {
                    slug: 'tariff',
                    name: 'Тарифы',
                    path: `${getEnvConfig().PUBLIC_PATH}/monetization/tariff`,
                },
                {
                    slug: 'finances',
                    name: 'Финансы',
                    path: `${getEnvConfig().PUBLIC_PATH}/monetization/finances`,
                },
            ],
        },*/
        /*{
            slug: 'analitycs',
            name: 'Аналитика',
            path: `${getEnvConfig().PUBLIC_PATH}/analitycs`,
        },*/
        {
            slug: 'shops',
            name: 'Магазины',
            path: `${getEnvConfig().PUBLIC_PATH}/shops`,
        },
        {
            slug: 'admin',
            name: 'Администрирование',
            path: `${getEnvConfig().PUBLIC_PATH}/admin`,
            hide: !isAdmin,
        },
    ];

    const items: MenuProps['items'] = [
        {
            label: (
                <StyledSubmenuLink
                    token={token}
                    to={`${getEnvConfig().PUBLIC_PATH}/monetization/donate`}
                >
                    Донаты
                </StyledSubmenuLink>
            ),
            key: '1',
        },
        {
            label: (
                <StyledSubmenuLink
                    token={token}
                    to={`${getEnvConfig().PUBLIC_PATH}/monetization/tariff`}
                >
                    Тарифы
                </StyledSubmenuLink>
            ),
            key: '2',
        },
        {
            label: (
                <StyledSubmenuLink
                    token={token}
                    to={`${getEnvConfig().PUBLIC_PATH}/monetization/finances`}
                >
                    Финансы
                </StyledSubmenuLink>
            ),
            key: '3',
        },
    ];

    const handleMenuToggle = menu => {
        if (menu.children) {
            setExpandedMenu(menu.slug);
        } else setOpen(!open);
    };

    return (
        <StyledWrapper>
            {/*
            <VirtualTour />
*/}
            <Container>
                <StyledNav>
                    {size === 'small' ? (
                        <>
                            <StyledMobileBurger onClick={() => setOpen(!open)}>
                                <MenuOutlined />
                            </StyledMobileBurger>

                            <Drawer
                                placement='left'
                                onClose={() => setOpen(false)}
                                open={open}
                            >
                                <Space direction={'vertical'} size={'large'}>
                                    {mainMenu
                                        .filter(elem => !elem.hide)
                                        .map((menu, index) => (
                                            <Fragment key={menu.slug}>
                                                <StyledLink
                                                    key={menu.slug}
                                                    token={token}
                                                    to={`${menu.path}`}
                                                    onClick={() =>
                                                        handleMenuToggle(menu)
                                                    }
                                                >
                                                    {menu.name}
                                                </StyledLink>
                                                {expandedMenu === menu.slug && (
                                                    <Space
                                                        direction={'vertical'}
                                                        size={'large'}
                                                        style={{
                                                            paddingLeft: '1rem',
                                                        }}
                                                    >
                                                        {menu.children.map(
                                                            (submenu, key) => (
                                                                <StyledLink
                                                                    key={
                                                                        submenu.slug
                                                                    }
                                                                    token={
                                                                        token
                                                                    }
                                                                    to={`${submenu.path}`}
                                                                    onClick={() =>
                                                                        setOpen(
                                                                            false
                                                                        )
                                                                    }
                                                                >
                                                                    {
                                                                        submenu.name
                                                                    }
                                                                </StyledLink>
                                                            )
                                                        )}
                                                    </Space>
                                                )}
                                            </Fragment>
                                        ))}
                                </Space>
                            </Drawer>
                        </>
                    ) : (
                        <>
                            <NavLink to='/'>
                                <img height={22} alt='logo' src={Logo} />
                            </NavLink>
                            <StyledTabsContainer>
                                <StyledTabs>
                                    {/*<StyledLink
                                    token={token}
                                    ref={
                                        VirtualTourProvider.get()
                                            ?.communitiesRef
                                    }
                                    to={`${
                                        getEnvConfig().PUBLIC_PATH
                                    }/communities`}
                                >
                                    Сообщества
                                </StyledLink>*/}
                                    {/*<Dropdown menu={{ items }}>
                                    <StyledLink
                                        token={token}
                                        ref={
                                            VirtualTourProvider.get()
                                                ?.communitiesMonetizationRef
                                        }
                                        to={`${
                                            getEnvConfig().PUBLIC_PATH
                                        }/monetization/donate`}
                                    >
                                        Монетизация
                                    </StyledLink>
                                </Dropdown>*/}
                                    {/* <StyledLink
                                    token={token}
                                    ref={
                                        VirtualTourProvider.get()
                                            ?.communitiesAnalyticsRef
                                    }
                                    to={`${
                                        getEnvConfig().PUBLIC_PATH
                                    }/analitycs`}
                                >
                                    Аналитика
                                </StyledLink>*/}
                                    <StyledLink
                                        token={token}
                                        to={`${
                                            getEnvConfig().PUBLIC_PATH
                                        }/shops`}
                                    >
                                        Магазины
                                    </StyledLink>
                                    {isAdmin && (
                                        <StyledLink
                                            token={token}
                                            to={`${
                                                getEnvConfig().PUBLIC_PATH
                                            }/admin`}
                                        >
                                            Администрирование
                                        </StyledLink>
                                    )}
                                </StyledTabs>
                            </StyledTabsContainer>
                        </>
                    )}

                    <div>
                        <Space size={'middle'}>
                            <SubscriptionTag />
                            <Feedback />
                            <UserMenu />
                        </Space>
                    </div>
                </StyledNav>
            </Container>
        </StyledWrapper>
    );
};

const StyledWrapper = styled.div`
    border-bottom: 1px solid rgba(37, 33, 41, 0.06);
`;

const StyledNav = styled.nav`
    user-select: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 24px;
    padding-bottom: 6px;
`;

const StyledTabsContainer = styled.div`
    flex-grow: 1;
    padding: 0 16px;
`;

const StyledTabs = styled.div`
    font-size: 14px;
    color: rgba(37, 33, 41, 0.25);
    display: flex;
    gap: 40px;

    @media (max-width: 600px) {
        display: none;
    }
`;

const StyledLink = styled(NavLink)<{ token: GlobalToken; $active?: boolean }>`
    padding-bottom: 4px;
    position: relative;
    color: ${props => props.token.colorTextBase};

    &.active {
        &:after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: -8px;
            border-bottom: 2px solid ${props => props.token.colorPrimary};
        }
    }
`;

const StyledSubmenuLink = styled(StyledLink)`
    margin: 0 30px;
`;

const StyledMobileBurger = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;
