import React, { useEffect, useState } from 'react';
import { Button, Flex, Typography } from 'antd';
import dayjs from 'dayjs';

import { GenericModal } from '../../../../shared/ui/GenericModal';
import { useProfileData } from '../../../../hooks/useProfileData';
import {
    SubscriptionProps,
    SubscriptionSlug,
} from '../../interfaces/subscription.interface';

const { Text } = Typography;

export const SuccessModal = props => {
    const { open, setOpen } = props;
    const [subscription, setSubscription] = useState<SubscriptionProps>(null);

    const { subscriptions } = useProfileData();

    useEffect(() => {
        if (subscriptions) {
            const paidSubscription = subscriptions.find(
                elem => elem.slug === SubscriptionSlug.payJuriPlan
            );
            setSubscription(paidSubscription);
        }
    }, [subscriptions]);

    return (
        subscription && (
            <GenericModal
                centered
                open={open}
                footer={null}
                title={'Спасибо'}
                onCancel={() => setOpen(false)}
                width={380}
            >
                <Flex style={{ marginTop: 32 }} vertical gap={8}>
                    <Flex vertical={false} gap={4}>
                        <Text>Вам подключен тариф</Text>
                        {subscription && (
                            <Text strong>«{subscription?.name}».</Text>
                        )}
                    </Flex>

                    <Flex vertical={false} gap={4}>
                        <Text>Срок действия до</Text>
                        {subscription && (
                            <Text>
                                {dayjs()
                                    .add(subscription?.period_days, 'days')
                                    .format('DD.MM.YYYY')}
                            </Text>
                        )}
                    </Flex>

                    <Button
                        style={{ marginTop: 24 }}
                        type={'primary'}
                        onClick={() => setOpen(false)}
                    >
                        Продолжить
                    </Button>
                </Flex>
            </GenericModal>
        )
    );
};
