import React, { FC, Fragment, useState } from 'react';

import { FeedbackTable } from 'features/admin/components/FeedbackTab/components/FeedbackTable';
import { useFeedbackListQuery } from 'features/admin/services/feedbackTab.service';
import { IBasePaginationListQueryFilters } from 'features/admin/services/usersTab.service';

export const FeedbackTab: FC = () => {
    const [filters, setFilters] = useState<IBasePaginationListQueryFilters>({
        offset: 0,
        limit: 10,
    });
    const { data } = useFeedbackListQuery(filters);

    return (
        <Fragment>
            <FeedbackTable
                total={data?.total || 0}
                items={data?.list || []}
                setFilters={setFilters}
            />
        </Fragment>
    );
};
