export const deepGetObjectById = (object, id) => {
    let result = null;
    if (object instanceof Array) {
        for (let i = 0; i < object.length; i++) {
            result = deepGetObjectById(object[i], id);
            if (result) {
                break;
            }
        }
    } else {
        for (const prop in object) {
            if (prop == 'id') {
                if (object[prop] === id) {
                    return object;
                }
            }
            if (
                object[prop] instanceof Object ||
                object[prop] instanceof Array
            ) {
                result = deepGetObjectById(object[prop], id);
                if (result) {
                    break;
                }
            }
        }
    }
    return result;
};
