import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import getEnvConfig from 'utils/config/env';

import { IAuthor } from '../interfaces/publications.interface';

export const authorApi = createApi({
    reducerPath: 'author',
    baseQuery: fetchBaseQuery({
        baseUrl: getEnvConfig().env.API_URL,
        prepareHeaders: headers => {
            const token = localStorage.getItem('accessToken');
            headers.set('Authorization', `Bearer ${token}`);
            return headers;
        },
    }),
    tagTypes: ['Authors', 'User'],
    endpoints: builder => ({
        createAuthor: builder.mutation({
            query: body => ({
                url: '/authors',
                method: 'POST',
                body: body,
            }),
            transformResponse: (response: { data: IAuthor }) => {
                return response.data;
            },
            invalidatesTags: ['Authors', 'User'],
        }),

        readAuthorById: builder.query({
            query: id => ({
                url: `/authors/${id}`,
                method: 'GET',
            }),
            transformResponse: (response: { data: IAuthor }) => {
                return response.data;
            },
            providesTags: ['Authors'],
        }),

        readAuthorPublicById: builder.query({
            query: id => ({
                url: `/public/author/${id}`,
                method: 'GET',
            }),
            transformResponse: (response: { data: IAuthor }) => {
                return response.data;
            },
            providesTags: ['Authors'],
        }),

        deleteAuthor: builder.mutation<void, void>({
            query: () => ({
                url: `/authors`,
                method: 'DELETE',
            }),
            invalidatesTags: ['User'],
        }),

        updateAuthor: builder.mutation({
            query: ({ ...body }) => ({
                url: `/authors`,
                method: 'PUT',
                body: body,
            }),
            invalidatesTags: ['Authors'],
        }),
    }),
});
export const {
    useCreateAuthorMutation,
    useReadAuthorByIdQuery,
    useReadAuthorPublicByIdQuery,
    useDeleteAuthorMutation,
} = authorApi;
