import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Button, Flex, Spin, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';

import { useReadUserQuery } from '../../../user/services';
import getEnvConfig from '../../../../utils/config/env';

const { Title } = Typography;

export const PayPending = () => {
    const { data: user, refetch } = useReadUserQuery();
    const navigate = useNavigate();

    useEffect(() => {
        let intervalId;
        if (user) {
            if (user.subscription.subscription_id !== 3) {
                intervalId = setInterval(() => {
                    refetch();
                }, 2000);
            }

            return () => {
                clearInterval(intervalId);
            };
        }
    }, [user]);

    useEffect(() => {
        if (user && user.subscription?.subscription_id === 3) {
            navigate('/app/shops?paid=1');
        }
    }, [user]);

    const navigateAuthPage = () => {
        try {
            if (localStorage.getItem('accessToken')) {
                localStorage.removeItem('accessToken');
            }
        } catch (e) {
            /* empty */
        }
        return navigate(`${getEnvConfig().PUBLIC_PATH}/auth/sign-in`);
    };

    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh',
                width: '100%',
            }}
        >
            <StyledModal>
                <Flex vertical gap={24}>
                    <Flex vertical gap={8}>
                        <Title level={4}>Ожидание поступления средств</Title>
                        <Spin />
                    </Flex>

                    <Button type={'primary'} onClick={navigateAuthPage}>
                        Вернуться на экран авторизации
                    </Button>
                </Flex>
            </StyledModal>
        </div>
    );
};

const StyledModal = styled.div`
    width: 380px;
    border-radius: 10px;
    background: #ffffff;
    padding: 16px;

    display: flex;
    justify-content: center;
    align-items: center;
`;
