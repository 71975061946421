import React, { ReactNode } from 'react';
import {
    Button,
    Card,
    Col,
    Dropdown,
    Flex,
    MenuProps,
    Space,
    Typography,
} from 'antd';
import {
    SettingOutlined,
    DeleteOutlined,
    EditOutlined,
    UpOutlined,
    CheckOutlined,
} from '@ant-design/icons';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { getSrc } from '../../../../utils/common/getSrc';
import { ConfirmationDialog } from '../../../../shared/ui/ConfirmationDialog';
import { useTheme } from '../../../../hooks/useTheme';
import { IShop } from '../../interfaces/shop.interface';
import { ProductImage } from '../../components/Product/ProductImage';
import { Size, useWindowSize } from '../../../../hooks/useWindowSize';
import { Copy } from '../../components/Copy';
import { PurifiedHtml } from '../../../../shared/ui/PurifyHtml';
import {
    PaymentSystems,
    PaymentSystemsNames,
} from '../../interfaces/paymentSettings.interface';

type PaymentCardProps = {
    readonly loading?: boolean;
    readonly toggle?: (id: PaymentSystemsNames) => void;
    readonly isToggled?: boolean;
    readonly updatePaymentSystem?: (id: PaymentSystems) => Promise<void>;
    readonly actions?: ReactNode;
    readonly name: PaymentSystemsNames;
    readonly brand: string;
    readonly connect: boolean;
    readonly active: boolean;
    children: React.ReactNode;
};
export const PaymentSystemCard: React.FC<PaymentCardProps> = props => {
    const token = useTheme();
    const {
        brand,
        name,
        updatePaymentSystem,
        loading,
        toggle,
        isToggled,
        connect,
        active,
        children,
    } = props;
    const { boxShadow, colorPrimary } = useTheme();
    const { size } = useWindowSize();

    const onToggle = () => {
        toggle(name);
    };

    return (
        <Card
            type='inner'
            style={{ boxShadow, marginBottom: '1.5rem', width: '100%' }}
        >
            <Flex
                vertical
                wrap={'wrap'}
                justify={'space-between'}
                gap={'1.5rem'}
            >
                <Flex
                    gap={'0.5rem'}
                    align={'center'}
                    style={{
                        marginBottom: size === Size.small ? '1.5rem' : 0,
                    }}
                >
                    {active && (
                        <StyledCheck>
                            <CheckOutlined
                                style={{ color: token.colorPrimary }}
                            />
                        </StyledCheck>
                    )}
                    <StyledTitle>
                        <Typography.Title style={{ margin: 0 }} level={2}>
                            {brand}
                        </Typography.Title>
                    </StyledTitle>

                    <UpOutlined
                        rotate={isToggled ? 0 : 180}
                        onClick={onToggle}
                        style={{
                            color: token.colorTextBase,
                            cursor: 'pointer',
                        }}
                    />
                </Flex>

                {isToggled && children}

                <StyledActions>
                    {props.actions ? (
                        props.actions
                    ) : (
                        <Space align={'center'} direction={'horizontal'} wrap>
                            {!connect && !active && (
                                <Button disabled={loading} onClick={onToggle}>
                                    Настроить
                                </Button>
                            )}
                            {connect && !active && (
                                <Button
                                    disabled={loading}
                                    onClick={() => updatePaymentSystem(props)}
                                >
                                    Подключить
                                </Button>
                            )}
                            {connect && active && (
                                <Button
                                    block
                                    disabled={loading}
                                    type='primary'
                                    onClick={onToggle}
                                >
                                    Подключено
                                </Button>
                            )}
                        </Space>
                    )}
                </StyledActions>
            </Flex>
        </Card>
    );
};

const StyledCheck = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    flex: 0 1 24px;
    width: 24px;
    height: 24px;

    border: 1px solid #6257d2;
    border-radius: 50%;
`;

const StyledTitle = styled.div`
    flex: 1 1 0;
`;

const StyledActions = styled(Col)`
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
`;
