import React, { useEffect } from 'react';
import { Card } from 'antd';
import {
    CheckCircleFilled,
    ExclamationCircleFilled,
    LoadingOutlined,
} from '@ant-design/icons';

import { useTheme } from '../../../../hooks/useTheme';
import { OnboardingNotification } from '../../components/Onboarding/OnboardingNotification';
import { useProfileData } from '../../../../hooks/useProfileData';
import { useLazyReadLegalInfoQuery } from '../../../user/services/legalEntity';
import { OnboardingStepsList } from '../../components/Onboarding/OnboardingStepsList';

export const OnboardingCard = () => {
    const { boxShadow, colorPrimary } = useTheme();
    const [isOpenCard, setIsOpenCard] = React.useState(false);
    const token = useTheme();
    const { user } = useProfileData();

    const [getLegalInfo, { data: legalInfo, isLoading: loadingReadLegal }] =
        useLazyReadLegalInfoQuery();
    useEffect(() => {
        void getLegalInfo(null, true);
    }, []);

    const cardSettings = {
        isOpenCard: isOpenCard,
        setIsOpenCard: setIsOpenCard,
    };

    const isTelegramAccount = user?.telegram_accounts?.length > 0;
    const isLegalInfoFilled = legalInfo && legalInfo.id !== undefined;

    const renderOnboardingCardContent = () => {
        switch (true) {
            case isOpenCard:
                return (
                    <OnboardingStepsList
                        {...legalInfo}
                        {...cardSettings}
                        loadingReadLegal={loadingReadLegal}
                    />
                );
            case loadingReadLegal || !user:
                return (
                    <OnboardingNotification
                        {...cardSettings}
                        icon={<LoadingOutlined />}
                        title={''}
                        description={''}
                    />
                );
            case !isOpenCard && !isTelegramAccount && !isLegalInfoFilled:
                return (
                    <OnboardingNotification
                        {...cardSettings}
                        icon={
                            <ExclamationCircleFilled
                                style={{
                                    color: token.colorWarning,
                                    fontSize: 24,
                                }}
                            />
                        }
                        title={'Заполните все необходимые шаги'}
                        description={
                            'Создание магазина будет доступно после привязки Telegram. Заполнените юридические данные, чтобы принимать оплату через Telegram'
                        }
                    />
                );
            case !isOpenCard && !isTelegramAccount && isLegalInfoFilled:
                return (
                    <OnboardingNotification
                        {...cardSettings}
                        icon={
                            <ExclamationCircleFilled
                                style={{
                                    color: token.colorWarning,
                                    fontSize: 24,
                                }}
                            />
                        }
                        title={'Привяжите свой аккаунт в Telegram.'}
                        description={
                            'Создание магазина будет доступно после привязки Telegram'
                        }
                    />
                );
            case !isOpenCard && isTelegramAccount && !isLegalInfoFilled:
                return (
                    <OnboardingNotification
                        {...cardSettings}
                        icon={
                            <ExclamationCircleFilled
                                style={{
                                    color: token.colorWarning,
                                    fontSize: 24,
                                }}
                            />
                        }
                        title={'Заполните юридические данные'}
                        description={
                            'Заполните сведения о своей организации, чтобы принимать оплату через Telegram'
                        }
                    />
                );
            case isOpenCard && isTelegramAccount && isLegalInfoFilled:
            default:
                return (
                    <OnboardingNotification
                        {...cardSettings}
                        icon={
                            <CheckCircleFilled
                                style={{
                                    color: token.colorSuccess,
                                    fontSize: 24,
                                }}
                            />
                        }
                        title={'Ура!'}
                        description={'Удачных продаж '}
                    />
                );
        }
    };

    return (
        <Card type='inner' style={{ boxShadow, marginBottom: '1.5rem' }}>
            {renderOnboardingCardContent()}
        </Card>
    );
};
