export const scenariosType = rule => {
    switch (rule.type) {
        case 'if_then_rule': {
            return {
                url: `/user-community-rules`,
                method: 'DELETE',
                body: {
                    user_rule_uuid: rule.id,
                },
            };
        }
        case 'onboarding_rule': {
            return {
                url: `/onboarding/${rule.id}`,
                method: 'DELETE',
            };
        }
        case 'antispam_rule': {
            return {
                url: `/antispam/${rule.id}`,
                method: 'DELETE',
            };
        }
        case 'moderation_rule': {
            return {
                url: `/chats/rules/${rule.id}`,
                method: 'DELETE',
            };
        }
        case 'reputation_rule': {
            return {
                url: `/chats/rate/${rule.id}`,
                method: 'DELETE',
            };
        }
    }
};
