import { useNavigate, useParams } from 'react-router-dom';
import { App, Form, Skeleton } from 'antd';
import React, { useEffect, useState } from 'react';

import { BannerForm } from '../../views/Banners/BannerForm';
import {
    useLazyReadBannerQuery,
    useUpdateBannerMutation,
} from '../../services/banners';
import { createFormData } from '../../../../utils/common/createFormData';
import { ManageTitle } from '../../views/Banners/ManageTitle';
import { ManageControls } from '../../views/Banners/ManageControls';

export const BannerEditContainer = () => {
    const { id, bannerId } = useParams();
    const { notification } = App.useApp();
    const navigate = useNavigate();

    const [form] = Form.useForm();
    const values = Form.useWatch([], form);
    const [isSubmittable, setIsSubmittable] = useState<boolean>(false);

    const [updateBanner, { isSuccess, isError, error, isLoading }] =
        useUpdateBannerMutation();
    const [getBanner, { data, isLoading: isLoadingGet }] =
        useLazyReadBannerQuery();

    useEffect(() => {
        if (id && bannerId) {
            const payload = {
                shopId: +id,
                bannerId: +bannerId,
            };
            getBanner(payload, false);
        }
    }, [id, bannerId]);

    useEffect(() => {
        if (data) {
            form.setFieldsValue({ ...data });
        }
    }, [data]);

    useEffect(() => {
        form.validateFields({ validateOnly: true })
            .then(() => setIsSubmittable(true))
            .catch(() => setIsSubmittable(false));
    }, [form, values]);

    useEffect(() => {
        if (isSuccess) {
            notification.success({
                message: 'Баннер успешно изменен',
            });
            navigate(-1);
        }
    }, [isSuccess]);

    useEffect(() => {
        if (isError) {
            const errors = Object?.values(error?.data?.errors);

            errors?.map((errorArray, index) =>
                errorArray?.map(error =>
                    notification.error({
                        message: error,
                    })
                )
            );
        }
    }, [isError]);

    const handleSave = async () => {
        const payload = {
            id: +id,
            bannerId: +bannerId,
            ...values,
        };

        if (!(values.image instanceof File)) {
            delete payload.image;
        }

        if (values.url === undefined) {
            delete payload.url;
        }

        await updateBanner({
            id: +id,
            bannerId: +bannerId,
            body: createFormData(payload),
        });
    };

    return (
        <>
            <ManageTitle id={bannerId} />

            {isLoading || isLoadingGet ? (
                <Skeleton />
            ) : (
                data &&
                values &&
                form &&
                !isLoading && (
                    <BannerForm isEdit={true} form={form} values={values} />
                )
            )}
            <ManageControls
                isLoading={isLoading || isLoadingGet}
                isSubmittable={isSubmittable}
                onSave={handleSave}
            />
        </>
    );
};
