import React, { Dispatch, FC, Fragment, SetStateAction } from 'react';
import { Table } from 'antd';
import dayjs from 'dayjs';

import { IBasePaginationListQueryFilters } from 'features/admin/services/usersTab.service';

interface IProps {
    total: number;
    items: any[];
    setFilters: Dispatch<SetStateAction<IBasePaginationListQueryFilters>>;
}

export const PaymentsTable: FC<IProps> = props => {
    const statusHashMap = {
        AUTHORIZED: 'Одобрен',
        CONFIRMED: 'Подтверждён',
        NEW: 'Новый',
        REJECTED: 'Отклонен',
    };

    const typeHashMap = {
        subscription: 'Подписка',
        tariff: 'Тариф',
        publication: 'Публикация',
        donate: 'Донат',
    };

    const columns = [
        {
            title: 'ID',
            dataIndex: 'order_id',
            key: 'order_id',
            width: '152px',
            render: text => text,
        },
        {
            title: 'Время платежа',
            dataIndex: 'created_at',
            key: 'order_id',
            width: '152px',
            ellipsis: true,
            render: time => dayjs.unix(time).format('DD.MM.YY HH:mm'),
        },
        {
            title: 'Пользователь',
            dataIndex: 'telegram_user_name',
            key: 'order_id',
            width: '167px',
            ellipsis: true,
            render: text => text,
        },
        {
            title: 'Тип',
            dataIndex: 'type',
            key: 'order_id',
            width: '152px',
            ellipsis: true,
            render: text => typeHashMap[text] || text,
        },
        {
            title: 'Сообщество',
            dataIndex: 'community',
            key: 'order_id',
            width: '167px',
            ellipsis: true,
            render: text => text,
        },
        {
            title: 'Статус',
            dataIndex: 'status',
            key: 'order_id',
            width: '167px',
            ellipsis: true,
            render: text => statusHashMap[text] || text,
        },
        {
            title: 'Сумма поступлений',
            dataIndex: 'add_balance',
            key: 'order_id',
            width: '167px',
            ellipsis: true,
            align: 'right',
            render: text => text || 0,
        },
    ];

    const pagination = {
        total: props.total,
        showTotal: () => `Платежей: ${props.total}`,
        onChange: (page, pageSize) => {
            props.setFilters({
                offset: (page - 1) * pageSize,
                limit: pageSize,
            });
        },
        showQuickJumper: false,
        showSizeChanger: false,
    };

    return (
        <Fragment>
            <Table
                columns={columns}
                rowKey={user => user.id}
                dataSource={props.items}
                pagination={pagination}
            />
        </Fragment>
    );
};
