import React, { Dispatch, FC, Fragment, SetStateAction, useState } from 'react';
import { Badge, Button, Divider, Table } from 'antd';
import dayjs from 'dayjs';
import { DownOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import { IUser } from 'features/user/interfaces';
import { Dropdown } from 'shared/ui/Dropdown';

import { prettifyPhone } from '../../../../../utils/common/phones';
import {
    IBasePaginationListQueryFilters,
    useBlockUserByIdMutation,
    useSendNewPasswordMutation,
    useUnblockUserByIdMutation,
} from '../../../services/usersTab.service';

interface IProps {
    total: number;
    items: IUser[];
    setFilters: Dispatch<SetStateAction<IBasePaginationListQueryFilters>>;
    setOpen: (id, commission) => void;
}

export const UsersTable: FC<IProps> = props => {
    const { setOpen } = props;

    const [unblockUserById] = useUnblockUserByIdMutation();
    const [blockUserById] = useBlockUserByIdMutation();
    const [sendNewPassword] = useSendNewPasswordMutation();

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            width: '66px',
            render: text => text,
        },
        {
            title: 'Имя',
            dataIndex: '',
            key: 'id',
            width: '110px',
            ellipsis: true,
            render: user => (
                <>
                    {user?.is_blocked && (
                        <div>
                            <Badge status='warning' title='Заблокирован' />
                            <span style={{ marginLeft: 8 }}>{user.name}</span>
                        </div>
                    )}
                    {!user?.is_blocked && <>{user.name}</>}
                </>
            ),
        },
        {
            title: 'Юзернейм',
            dataIndex: 'telegram_user_name',
            key: 'id',
            width: '110px',
            ellipsis: true,
            render: names => names,
        },
        {
            title: 'Почта',
            dataIndex: 'email',
            key: 'id',
            width: '110px',
            ellipsis: true,
            render: text => text,
        },
        {
            title: 'Телефон',
            dataIndex: 'phone',
            key: 'id',
            width: '110px',
            ellipsis: true,
            render: (text: number | null) =>
                text ? prettifyPhone(text.toString()) : '',
        },
        {
            title: 'Регистрация',
            dataIndex: 'created_at',
            key: 'id',
            width: '110px',
            ellipsis: true,
            render: time => dayjs(time).format('DD.MM.YY HH:mm'),
        },
        {
            title: 'Сообществ',
            dataIndex: 'community_owner_num',
            key: 'id',
            width: '89px',
            ellipsis: true,
            render: text => text || 0,
        },
        {
            title: 'Последняя активность',
            dataIndex: 'updated_at',
            key: 'id',
            width: '110px',
            ellipsis: true,
            render: text => dayjs(text).format('DD.MM.YYYY, HH:mm'),
        },
        {
            title: 'Сумма поступлений',
            dataIndex: 'payins',
            key: 'id',
            width: '110px',
            ellipsis: true,
            render: text => text,
        },
        {
            title: 'Комиссия',
            dataIndex: 'commission',
            key: 'id',
            width: '89px',
            ellipsis: true,
            render: text => text + '%' || 0,
        },
        {
            title: 'Действие',
            dataIndex: '',
            key: 'id',
            width: '110px',
            ellipsis: true,
            render: user => (
                <>
                    <StyledDropdown
                        dropdownContent={() => (
                            <Fragment>
                                <Button
                                    type='text'
                                    onClick={() => {
                                        setOpen(user.id, user.commission);
                                    }}
                                >
                                    Изменить процент
                                </Button>
                                <Button
                                    type='text'
                                    onClick={() => sendNewPassword(user.id)}
                                >
                                    Выслать пароль
                                </Button>
                                <Divider style={{ margin: 0 }} />{' '}
                                {user?.is_blocked && (
                                    <Button
                                        type='text'
                                        onClick={() => unblockUserById(user.id)}
                                    >
                                        Разблокировать пользователя
                                    </Button>
                                )}
                                {!user?.is_blocked && (
                                    <Button
                                        type='text'
                                        onClick={() => blockUserById(user.id)}
                                    >
                                        Заблокировать пользователя
                                    </Button>
                                )}
                            </Fragment>
                        )}
                    >
                        <Button type='default'>
                            Действие <DownOutlined />
                        </Button>
                    </StyledDropdown>
                </>
            ),
        },
    ];
    const pagination = {
        total: props.total,
        showTotal: () => `Участников: ${props.total}`,
        onChange: (page, pageSize) => {
            props.setFilters({
                offset: (page - 1) * pageSize,
                limit: pageSize,
            });
        },
        showQuickJumper: false,
        showSizeChanger: false,
    };

    return (
        <Table
            scroll={{ x: 900 }}
            columns={columns}
            rowKey={user => user.id}
            dataSource={props.items}
            pagination={pagination}
        />
    );
};

const StyledDropdown = styled(Dropdown)`
    margin-left: auto;
    cursor: pointer;

    button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        border: none;
        box-shadow: none;
        padding: 0;

        span:nth-child(1) {
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
`;
