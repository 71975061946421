import React from 'react';

interface IProps {
    communitiesRef: React.MutableRefObject<any>;
    communitiesOverviewRef: React.MutableRefObject<any>;
    communitiesScenariosRef: React.MutableRefObject<any>;
    communitiesMembersRef: React.MutableRefObject<any>;
    communitiesEventsRef: React.MutableRefObject<any>;
    communitiesKnowledgeRef: React.MutableRefObject<any>;
    communitiesAIKnowledgeRef: React.MutableRefObject<any>;
    communitiesMonetizationRef: React.MutableRefObject<any>;
    communitiesAnalyticsRef: React.MutableRefObject<any>;
    profileRef: React.MutableRefObject<any>;
    feedbackRef: React.MutableRefObject<any>;
}
export class VirtualTourProvider {
    private static refs: Partial<IProps>;
    public static dropdownStatus: any;
    public static set(props: Partial<IProps>) {
        this.refs = props;
    }

    public static get() {
        return this.refs;
    }
}
