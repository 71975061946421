import React, {
    ChangeEventHandler,
    FC,
    useEffect,
    useRef,
    useState,
} from 'react';
import styled from 'styled-components';
import { ArrowLeftOutlined, EditOutlined } from '@ant-design/icons';
import { Input, Tooltip } from 'antd';
import { useNavigate } from 'react-router-dom';

interface HeaderScenarios {
    inputValue: string;
    onChangeHeaderInput?: ChangeEventHandler<HTMLInputElement>;
    withoutEdit?: boolean;
    onBlur?: () => void;
    goBack?: () => void;
    tooltip?: string;
    onExtraEdit: () => void;
}

export const HeaderScenarios: FC<HeaderScenarios> = ({
    onBlur,
    inputValue,
    onChangeHeaderInput,
    withoutEdit,
    goBack,
    tooltip,
    onExtraEdit,
}) => {
    const [contentEditable, setContentEditable] = useState<boolean>(false);
    const inputRef = useRef(null);

    const navigate = useNavigate();

    useEffect(() => {
        if (contentEditable) {
            inputRef.current.focus();
        }
    }, [contentEditable]);

    const inputProps = {
        onBlur: onBlur ? onBlur : null,
        ref: inputRef,
        value: inputValue,
        bordered: contentEditable,
        disabled: !contentEditable,
        onChange: onChangeHeaderInput,
        autoFocus: true,
        onKeyDown: e => {
            if (e.keyCode === 27) setContentEditable(false);
        },
    };

    return (
        <SectionHeader>
            <ButtonBack onClick={() => (goBack ? goBack() : navigate(-1))}>
                <ArrowLeftOutlined />
            </ButtonBack>
            {tooltip ? (
                <Tooltip placement='topLeft' title={tooltip}>
                    <StyledInput {...inputProps} />
                </Tooltip>
            ) : (
                <StyledInput {...inputProps} />
            )}
            {withoutEdit ? null : (
                <ButtonEdit
                    onClick={() => {
                        onExtraEdit && onExtraEdit();
                        setContentEditable(!contentEditable);
                    }}
                >
                    <EditOutlined twoToneColor='#6257D2' />
                </ButtonEdit>
            )}
        </SectionHeader>
    );
};

const StyledInput = styled(Input)`
    width: auto;
    min-width: 100px;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: rgba(37, 33, 41, 0.88);

    &[disabled] {
        color: rgba(37, 33, 41, 0.88);
    }
`;

const ButtonEdit = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 6px;
    cursor: pointer;
`;

const ButtonBack = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 17px;
    cursor: pointer;
`;

const SectionHeader = styled.div`
    display: flex;
    margin-bottom: 32px;
`;
