import React, { Fragment, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Flex } from 'antd';

import {
    useLazyReadBannersQuery,
    useUpdateBannerPositionMutation,
} from '../../services/banners';
import { BannersList } from '../../views/Banners/BannersList';

export const BannersListContainer = () => {
    const navigate = useNavigate();
    const { id } = useParams();

    const [getBanners, { data }] = useLazyReadBannersQuery();
    const [updateBannerPosition] = useUpdateBannerPositionMutation();

    useEffect(() => {
        if (id) {
            getBanners(+id, false);
        }
    }, [id]);

    const handleCreateBanner = () => {
        navigate(`create`);
    };

    const updatePosition = async items => {
        await updateBannerPosition({
            shopId: +id,
            list: items.map(item => item.id),
        });
    };

    return (
        <Fragment>
            <Flex justify={'space-between'} wrap={'wrap'} gap={20}>
                <Button
                    disabled={false}
                    type={'primary'}
                    onClick={handleCreateBanner}
                >
                    Добавить баннер
                </Button>
            </Flex>
            <BannersList updatePosition={updatePosition} banners={data || []} />
        </Fragment>
    );
};
