import React, { Fragment, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
    useDeleteProductMutation,
    useReadProductsQuery,
    useUpdateProductStatusMutation,
} from '../../services/products';
import { ProductList } from '../../views/Products/ProductList';
import {
    ProductsFilters,
    ProductsLisType,
    ProductStatus,
} from '../../interfaces/products.interface';
import { ProductTabs } from '../../views/Products/ProductTabs';
import { InitialFiltersLinks } from '../../utils/filters';

export const ProductsLinksContainer = () => {
    const { id } = useParams();

    const [filters, setFilters] = useState({
        shop_id: +id,
        ...InitialFiltersLinks,
    });

    const [
        deleteProduct,
        { isLoading: isLoadingDelete, isSuccess: isSuccessDelete },
    ] = useDeleteProductMutation();

    const [updateProductStatus] = useUpdateProductStatusMutation();
    const handleChangeStatus = async (id: number, status: number) => {
        if (status === ProductStatus.archived) {
            await updateProductStatus({
                id: id,
                status: ProductStatus.active,
            });
        } else
            updateProductStatus({
                id: id,
                status: ProductStatus.archived,
            });
    };

    const {
        data: products = { items: [], total: 0 },
        isFetching,
        isLoading,
    } = useReadProductsQuery(filters);

    const deleteProps = {
        delete: deleteProduct,
        isLoading: isLoadingDelete,
        isSuccess: isSuccessDelete,
    };

    const filtersProps = {
        filters: filters,
        setFilters: setFilters,
        shopId: +id,
    };

    const handleFiltersUpdate = (update: ProductsFilters) => {
        if (update) {
            setFilters({
                ...filters,
                ...update,
            });
        } else {
            setFilters(null);
        }
    };

    return (
        <Fragment>
            <ProductTabs loading={isFetching} {...filtersProps} />
            <ProductList
                handleChangeStatus={handleChangeStatus}
                source={products}
                loading={isFetching || isLoading}
                handleFiltersUpdate={handleFiltersUpdate}
                deleteProps={deleteProps}
                listType={ProductsLisType.archive}
                {...filtersProps}
            />
        </Fragment>
    );
};
