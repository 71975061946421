import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import getEnvConfig from 'utils/config/env';
import { IUser } from 'features/user/interfaces';

export interface IBasePaginationListQuery {
    list: IUser[];
    total: number;
}

export interface IBasePaginationListQueryFilters {
    offset: number;
    limit: number;
}

export const usersTabService = createApi({
    reducerPath: 'adminUsersTab',
    baseQuery: fetchBaseQuery({
        baseUrl: getEnvConfig().env.API_URL + '/manager',
        prepareHeaders: headers => {
            const token = localStorage.getItem('accessToken');
            headers.set('Authorization', `Bearer ${token}`);
            return headers;
        },
    }),
    tagTypes: ['usersList'],
    endpoints: build => ({
        usersList: build.query<
            IBasePaginationListQuery,
            IBasePaginationListQueryFilters
        >({
            query: params => ({
                method: 'GET',
                url: 'users',
                params,
            }),
            transformResponse: (res: any, meta) => {
                return {
                    list: res?.data || [],
                    total:
                        Number(meta.response.headers.get('items-count')) || 0,
                };
            },
            providesTags: ['usersList'],
        }),
        getUserById: build.query({
            query: (id: number) => ({
                url: `users/${id}`,
            }),
        }),
        blockUserById: build.mutation({
            query: (id: number) => ({
                method: 'GET',
                url: `users/block/${id}`,
            }),
            invalidatesTags: ['usersList'],
        }),
        userToAdmin: build.mutation({
            query: id => ({
                method: 'GET',
                url: `users/make-admin/${id}`,
            }),
        }),
        adminToUser: build.mutation({
            query: id => ({
                method: 'GET',
                url: `users/revoke-admin/${id}`,
            }),
        }),
        sendNewPassword: build.mutation({
            query: id => ({
                method: 'GET',
                url: `users/send-new-password/${id}`,
            }),
        }),
        unblockUserById: build.mutation({
            query: id => ({
                method: 'GET',
                url: `users/unblock/${id}`,
            }),
            invalidatesTags: ['usersList'],
        }),
        updateCommission: build.mutation({
            query: ({ id, commission }) => ({
                method: 'PUT',
                url: `users/${id}`,
                body: {
                    commission: commission,
                },
            }),
        }),
    }),
});

export const {
    useAdminToUserMutation,
    useUserToAdminMutation,
    useBlockUserByIdMutation,
    useGetUserByIdQuery,
    useSendNewPasswordMutation,
    useUnblockUserByIdMutation,
    useUsersListQuery,
    useUpdateCommissionMutation,
} = usersTabService;
