import React, { useEffect, useState } from 'react';
import { App, Button, Flex, Input, Typography } from 'antd';
import { Field, Formik, Form } from 'formik';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';

import {
    useLazyReadCloudPaymentsQuery,
    useUpdateCloudPaymentsMutation,
} from 'features/shops/services/paymentSettings';
import { notification } from 'shared/ui/GlobalNotification';

import { CloudPaymentsFormProps } from '../../../../interfaces/paymentSettings.interface';

export const Step_2 = () => {
    const [initialState, setInitialState] = useState<CloudPaymentsFormProps>({
        publicId: {},
        apiSecret: {},
    });

    const { id } = useParams();

    const [createCloudPayments, { isSuccess, isLoading: isLoadingCreate }] =
        useUpdateCloudPaymentsMutation();
    const [getCloudPayments] = useLazyReadCloudPaymentsQuery();

    useEffect(() => {
        getCloudPayments(+id, false);
    }, []);

    useEffect(() => {
        if (isSuccess) {
            notification.success({
                message: 'Успешно сохранено',
                placement: 'top',
            });
        }
    }, [isSuccess]);

    useEffect(() => {
        if (id) {
            getCloudPayments(+id, true).then(request => {
                if (request?.data) {
                    const cloudPaymentInitiaValues = (({
                        publicId,
                        apiSecret,
                    }) => ({
                        publicId,
                        apiSecret,
                    }))(request?.data);
                    setInitialState(cloudPaymentInitiaValues);
                }
            });
        }
    }, [id]);

    const onSave = async values => {
        const data = {
            shop_id: +id,
            publicId: values.publicId,
            apiSecret: values.apiSecret,
        };

        await createCloudPayments({ ...data });
    };

    const onValidate = values => {
        const errors = {};
        if (values.publicId === '') {
            errors.publicId = 'Укажите ID';
        }
        if (values.apiSecret === '') {
            errors.apiSecret = 'Введите ключ';
        }
        return errors;
    };

    return (
        <Formik
            initialValues={initialState}
            enableReinitialize={true}
            validate={onValidate}
            onSubmit={onSave}
        >
            <Form>
                <Typography.Text>Шаг 2. Предоставление доступа</Typography.Text>

                <Flex vertical gap={16}>
                    <Field name='publicId'>
                        {({ field, form, meta }) => (
                            <div>
                                <StyledLabel>
                                    Скопируйте Public ID из настроек, вставьте в
                                    поле ниже
                                </StyledLabel>
                                <Input
                                    status={meta.error ? 'error' : ''}
                                    style={{ maxWidth: '284px' }}
                                    {...field}
                                />
                            </div>
                        )}
                    </Field>
                    <Field name='apiSecret'>
                        {({ field, form, meta }) => (
                            <div>
                                <StyledLabel>
                                    Добавьте пароль для API и нажмите
                                    “Сохранить”
                                </StyledLabel>
                                <Input.Password
                                    status={meta.error ? 'error' : ''}
                                    style={{ maxWidth: '284px' }}
                                    {...field}
                                />
                            </div>
                        )}
                    </Field>
                    <Flex>
                        <Button
                            disabled={isLoadingCreate}
                            type='primary'
                            htmlType='submit'
                        >
                            Сохранить
                        </Button>
                    </Flex>
                </Flex>
            </Form>
        </Formik>
    );
};

const StyledLabel = styled.label`
    display: block;
    margin-bottom: 8px;
`;
