import React from 'react';
import styled from 'styled-components';
import { Typography } from 'antd';

const Secondary = props => {
    return <StyledSecondary>{props.children}</StyledSecondary>;
};

export default Secondary;

const StyledSecondary = styled(Typography.Text)`
    color: rgba(37, 33, 41, 0.45);
`;
