import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

import getEnvConfig from 'utils/config/env';
import { IBasePaginationListQueryFilters } from 'features/admin/services/usersTab.service';

export const paymentsTabService = createApi({
    reducerPath: 'adminPaymentsTab',
    baseQuery: fetchBaseQuery({
        baseUrl: getEnvConfig().env.API_URL + '/manager/payments',
        prepareHeaders: headers => {
            const token = localStorage.getItem('accessToken');
            headers.set('Authorization', `Bearer ${token}`);
            return headers;
        },
    }),
    endpoints: build => ({
        paymentsList: build.query<any, IBasePaginationListQueryFilters>({
            query: params => ({
                url: '',
                params,
            }),
            transformResponse: (res: any, meta) => {
                return {
                    list: res?.data || [],
                    total:
                        Number(meta.response.headers.get('items-count')) || 0,
                };
            },
        }),
    }),
});

export const { usePaymentsListQuery } = paymentsTabService;
