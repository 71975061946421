import React, { FC, useState } from 'react';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { Collapse } from 'antd';

import Rectangle from 'static/landing/Rectangle.png';
import Laptop from 'static/landing/laptop.png';
import PayImg from 'static/landing/payImg.png';
import StatisticImg from 'static/landing/statisticImg.png';
import SellImg from 'static/landing/sellImg.png';
import StaticText from 'shared/layouts/LandingLayout/content.json';

import { SubTitle } from '../ui/Title';
import { ScreenProps } from '../types/landing.interface';

export const Collapses: FC<ScreenProps> = ({ refProp }) => {
    const [activePanel, setActivePanel] = useState<number>(0);

    const onSwitch = (key: number) => {
        if (key === activePanel) {
            return;
        } else setActivePanel(key);

        console.log('key', key);
    };

    console.log('activePanel', activePanel);

    const renderImage = (type: number) => {
        switch (type) {
            case 0:
                return <img alt={'users'} src={StatisticImg} />;
            case 1:
                return <img alt={'pay'} src={PayImg} />;
            case 2:
                return <img alt={'sell'} src={SellImg} />;
        }
    };

    return (
        <StyledWrapper ref={refProp}>
            <StyledSectionThird>
                <SectionThirdLeftWrap>
                    <Collapse
                        activeKey={activePanel}
                        ghost
                        accordion
                        expandIcon={({ isActive }) => (
                            <StyledIconWrap>
                                {isActive ? (
                                    <MinusOutlined
                                        style={{
                                            fontSize: 25,
                                            color: '#6257D2',
                                        }}
                                    />
                                ) : (
                                    <PlusOutlined
                                        style={{
                                            fontSize: 25,
                                            color: '#6257D2',
                                        }}
                                    />
                                )}
                            </StyledIconWrap>
                        )}
                        onChange={key => onSwitch(+key)}
                    >
                        {StaticText.collapses.options.map(collapse => (
                            <Collapse.Panel
                                header={
                                    <SubTitle
                                        text={collapse.title}
                                        type={'primary'}
                                    />
                                }
                                key={collapse.key}
                            >
                                <p>{collapse.description}</p>
                            </Collapse.Panel>
                        ))}
                    </Collapse>
                </SectionThirdLeftWrap>
                <SectionThirdRightWrap>
                    {renderImage(activePanel)}
                </SectionThirdRightWrap>
            </StyledSectionThird>
        </StyledWrapper>
    );
};

const StyledWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 80px;
`;

const StyledIconWrap = styled.div`
    padding-top: 15px;
`;

const StyledRectangle = styled.div`
    position: absolute;
    top: -30px;
    left: 30px;
    z-index: -1;

    @media (max-width: 1400px) {
        top: -15px;
        left: 15px;
        img {
            height: 28px;
        }
    }
`;

const SectionThirdLeftWrap = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 50%;
    padding-left: 164px;

    @media (max-width: 992px) {
        padding-left: 130px;
    }

    @media (max-width: 768px) {
        padding-left: 20px;
    }

    @media (max-width: 576px) {
        padding-left: 0;
        width: 100%;
    }
`;

const SectionThirdRightWrap = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    padding-right: 164px;
    z-index: 0;

    @media (max-width: 992px) {
        padding-right: 130px;
    }

    @media (max-width: 768px) {
        padding-right: 0;
    }

    @media (max-width: 576px) {
        width: 100%;
    }

    .laptop {
        z-index: 1;
        height: 356px;

        @media (max-width: 1400px) {
            height: 177px;
        }
    }
`;

const StyledSectionThird = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;

    @media (max-width: 992px) {
        margin-bottom: 76px;
    }

    @media (max-width: 768px) {
        margin-bottom: 40px;
    }

    @media (max-width: 576px) {
        justify-content: start;
        flex-direction: column;
        gap: 24px;
    }
`;
