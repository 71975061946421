import React from 'react';
import { Form, Input, Typography } from 'antd';

import { BannerImageUpload } from '../../components/Banner/BannerImageUploader';
import { BannerFormProps } from '../../interfaces/banners.interface';

export const BannerForm = (props: BannerFormProps) => {
    const { form, isEdit, values } = props;

    return (
        <Form
            style={{
                width: 710,
            }}
            name='banner'
            form={form}
            layout={'vertical'}
            autoComplete='off'
            requiredMark={false}
        >
            <Form.Item label='Ссылка' name='url'>
                <Input placeholder={'Укажите ссылку перехода пользователя'} />
            </Form.Item>

            <Form.Item name={'image'}>
                <BannerImageUpload
                    isEdit={isEdit}
                    form={form}
                    values={values}
                />
            </Form.Item>
        </Form>
    );
};
