import { Navigate, RouteObject } from 'react-router';

import { MainPage } from 'features/admin/pages/mainPage/MainPage';
import { Chats } from 'features/communities/components/Chats';
import { UsersTab } from 'features/admin/components/UsersTab/UsersTab';
import { CommunitiesTab } from 'features/admin/components/CommunitiesTab/CommunitiesTab';
import { PaymentsTab } from 'features/admin/components/PaymentsTab/PaymentsTab';
import { FeedbackTab } from 'features/admin/components/FeedbackTab/FeedbackTab';

import { AnswerPage } from './components/AnswerPage/AnswerPage';

export const AdminRoutes: RouteObject[] = [
    {
        path: 'admin',
        element: <MainPage />,
        children: [
            {
                path: '',
                element: <Navigate to='users' />,
            },
            {
                path: 'users',
                element: <UsersTab />,
            },
            {
                path: 'communities',
                element: <CommunitiesTab />,
            },
            {
                path: 'payments',
                element: <PaymentsTab />,
            },
            {
                path: 'feedback',
                element: <FeedbackTab />,
            },
            {
                path: 'feedback/:id',
                element: <AnswerPage />,
            },
        ],
    },
];
