import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { App } from 'antd';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';

import { LegalForm } from '../views/LegalForm';
import { GenericModal } from '../../../../../shared/ui/GenericModal';
import { LegalEntityValues } from '../../../interfaces/legalEntity.interface';
import { useUpdateLegalInfoMutation } from '../../../services/legalEntity';
import getEnvConfig from '../../../../../utils/config/env';

interface EditLegalContainer extends LegalEntityValues {
    open: boolean;
    readonly successCreateBill?: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    legalBillUrl?: string;
    readonly extraAction?: (data: {
        subscription_id: number;
        legal_id: number;
    }) => void;
    errorCreateBill?: boolean;
}
export const LegalEditContainer: React.FC<EditLegalContainer> = props => {
    const [checked, setChecked] = useState(true);
    const [touched, setTouched] = useState(false);
    const navigate = useNavigate();
    const {
        open,
        setOpen,
        name,
        email,
        phone,
        inn,
        kpp,
        id,
        ogrn,
        address,
        additionally,
        extraAction,
        successCreateBill,
        errorCreateBill,
        legalBillUrl,
    } = props;
    const { notification } = App.useApp();

    const [updateLegal, { isSuccess, isLoading, isError }] =
        useUpdateLegalInfoMutation();

    useEffect(() => {
        if (isSuccess) {
            notification.success({
                message: `Юридическая информация изменена`,
            });

            setOpen(false);
        }
    }, [isSuccess]);

    useEffect(() => {
        if (successCreateBill) {
            navigate(
                `${
                    getEnvConfig().PUBLIC_PATH
                }/legal-success?url=${legalBillUrl}`
            );
            setOpen(false);
        }
    }, [successCreateBill]);

    useEffect(() => {
        if (errorCreateBill) {
            navigate(`${getEnvConfig().PUBLIC_PATH}/legal-error`);
            setOpen(false);
        }
    }, [errorCreateBill]);

    useEffect(() => {
        if (isError) {
            notification.error({
                message: 'Вы некоректно заполнили одно или несколько полей',
            });
        }
    }, [isError]);

    const onSave = async (values: LegalEntityValues) => {
        if (touched) {
            const payload = {
                ...values,
            };

            const res = await updateLegal({ id, ...payload });
            if (res?.data?.code === 200 && extraAction) {
                const data = {
                    subscription_id: 3,
                    legal_id: id,
                };
                extraAction(data);
            }
        }
    };

    const formik = useFormik({
        initialValues: {
            name: name,
            email: email,
            phone: +phone,
            inn: inn,
            kpp: kpp,
            ogrn: ogrn?.toString(),
            address: address,
            additionally: additionally,
        },
        onSubmit: onSave,
    });

    useEffect(() => {
        if (name !== formik.values.name) setTouched(true);
        if (email !== formik.values.email) setTouched(true);
        if (phone !== formik.values.phone) setTouched(true);
        if (inn !== formik.values.inn) setTouched(true);
        if (kpp !== formik.values.kpp) setTouched(true);
        if (ogrn !== formik.values.ogrn) setTouched(true);
        if (address !== formik.values.address) setTouched(true);
        if (additionally !== formik.values.additionally) setTouched(true);
    }, [formik.values]);

    return (
        <GenericModal
            open={open}
            onCancel={() => setOpen(false)}
            title={extraAction ? 'Проверьте сведения' : 'Измените сведения'}
            okButtonProps={{
                disabled: isLoading || !!id,
            }}
            okText={'Подтвердить'}
            destroyOnClose={true}
            onConfirm={() => onSave(formik.values)}
            width={760}
        >
            <LegalForm
                disabled
                formik={formik}
                checked={checked}
                setChecked={setChecked}
            />
        </GenericModal>
    );
};
