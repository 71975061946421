import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
    Avatar,
    Badge,
    Button,
    Card,
    Divider,
    Dropdown,
    Tooltip,
    Typography,
} from 'antd';
import styled from 'styled-components';

import { useTheme } from 'hooks/useTheme';
import { useProfileData } from 'hooks/useProfileData';
import getEnvConfig from 'utils/config/env';
import { VirtualTourProvider } from 'shared/ui/VirtualTour/VirtualTourProvider';

const UserMenu: React.FC = () => {
    const [linkText, setLinkText] = useState('Ваш контент');
    const { user, subscription } = useProfileData();

    const token = useTheme();
    const navigate = useNavigate();

    const hasPaidSubscription = subscription?.price > 0;

    const backgroundColor = hasPaidSubscription
        ? token.colorPrimary
        : token.colorTextBase;

    const handleLogout = () => {
        localStorage.removeItem('accessToken');
        window.location.replace(getEnvConfig().PUBLIC_PATH);
    };

    const location = useLocation();

    useEffect(() => {
        if (location.pathname.split('/')[1].includes('courses')) {
            setLinkText('Ваши сообщества');
        }
    }, []);

    const isAuthor = !!user?.author_fields;

    return (
        <StyledDropdown
            placement='bottomRight'
            dropdownRender={() => {
                return (
                    <StyledDropDownCard style={{ ...token.Card }}>
                        <Tooltip title={user?.email} mouseEnterDelay={1.5}>
                            <Button type='text' block>
                                <Typography.Text ellipsis={true}>
                                    {user?.email}
                                </Typography.Text>
                            </Button>
                        </Tooltip>
                        <Link to={`${getEnvConfig().PUBLIC_PATH}/profile`}>
                            <Button type='text' block>
                                Профиль
                            </Button>
                        </Link>
                        <Link
                            to={`${getEnvConfig().PUBLIC_PATH}/subscriptions`}
                        >
                            <Button type='text' block>
                                {hasPaidSubscription ? (
                                    <StyledBadge
                                        color={'#6257d2'}
                                        text={subscription?.name}
                                    >
                                        Подписка
                                    </StyledBadge>
                                ) : (
                                    'Подписка'
                                )}
                            </Button>
                        </Link>

                        {/* <Button
                            onClick={() =>
                                linkText === 'Ваш контент'
                                    ? navigate(
                                          `/courses/author/${
                                              isAuthor
                                                  ? user.author_fields.id
                                                  : 'create'
                                          }`
                                      )
                                    : navigate('/app/communities/chats')
                            }
                            type='text'
                        >
                            {linkText}
                        </Button>*/}

                        <Divider style={{ margin: 0 }} />
                        <Button type='text' onClick={handleLogout}>
                            Выйти
                        </Button>
                    </StyledDropDownCard>
                );
            }}
        >
            <Avatar
                style={{ backgroundColor }}
                ref={VirtualTourProvider.get()?.profileRef}
            >
                {user?.email.charAt(0)}
            </Avatar>
        </StyledDropdown>
    );
};

export default UserMenu;

const StyledDropdown = styled(Dropdown)`
    cursor: pointer;
`;

const StyledDropDownCard = styled(Card)`
    max-width: 280px;
    z-index: 1000;
    min-width: 200px;

    .ant-card-body {
        padding: 0 8px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        button {
            text-align: left;
        }
    }
`;

const StyledBadge = styled(Badge.Ribbon)`
    top: 0;
    inset-inline-end: -31px !important;
    color: #6257d2;
`;
