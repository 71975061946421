import React, { FC, useLayoutEffect } from 'react';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import { Button, Col, Flex, Row } from 'antd';

import CreateSpodialStoreImage from 'static/landing/create-spodial-store.png';
import StaticText from 'shared/layouts/LandingLayout/content.json';

import { Title } from '../ui/Title';
import { Container } from '../ui/Container';
import { Size, useWindowSize } from '../../../../hooks/useWindowSize';
import { ScreenProps } from '../types/landing.interface';

export const CreateShop: FC<ScreenProps> = ({ refProp }) => {
    const { size } = useWindowSize();
    const { hash } = useLocation();

    useLayoutEffect(() => {
        if (refProp && hash === '#create-store') {
            setTimeout(() => {
                refProp.current.scrollIntoView({
                    block: 'center',
                    inline: 'center',
                });
            }, 500);
        }
    }, [refProp]);

    return (
        <Container>
            <Row id={'header'} style={{ padding: '4rem 0' }} ref={refProp}>
                <Col xs={24} md={9}>
                    <Title
                        id={'create-store'}
                        level={1}
                        text={StaticText.createShop.title}
                    />

                    <Flex vertical={true}>
                        <StyledList>
                            <ul style={{ listStyle: 'disc inside' }}>
                                {StaticText.createShop.options.map(
                                    (option, index) => (
                                        <li key={index}>{option}</li>
                                    )
                                )}
                            </ul>
                        </StyledList>

                        <Link
                            style={{ marginTop: '24px' }}
                            to={'app/auth/sign-up'}
                        >
                            <Button type='primary'>
                                {StaticText.heading.actions.buttonText1}
                            </Button>
                        </Link>
                    </Flex>
                </Col>

                <Col
                    xs={24}
                    md={8}
                    style={{ display: 'flex', alignItems: 'center' }}
                    push={size === Size.small ? 0 : 4}
                >
                    <StyledImage>
                        <img
                            width={320}
                            //height={394}
                            src={CreateSpodialStoreImage}
                            alt={'Создай свой магазин в Spodial'}
                        />
                    </StyledImage>
                </Col>
            </Row>
        </Container>
    );
};

const StyledList = styled.div`
    ul {
        list-style-position: inside;
        margin-block-start: 0;
        margin-block-end: 0;
        margin-inline-start: 0;
        margin-inline-end: 0;
        padding-inline-start: 0;
    }
`;

const StyledImage = styled.div`
    justify-content: flex-end;
    @media (max-width: 768px) {
        margin-top: 1rem;
    }
`;
