import React from 'react';
import styled from 'styled-components';
import { Input } from 'antd';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { FormikProps } from 'formik';

import { Field, Form } from 'shared/ui/Form';
import { AuthForm } from 'features/auth/components';
import { ISignInCredentials } from 'features/auth/interfaces';
import { useSignInMutation } from 'features/auth/services';
import getEnvConfig from 'utils/config/env';

export const SignIn: React.FC = () => {
    const [signIn] = useSignInMutation();
    const navigate = useNavigate();

    /* if (localStorage.getItem('accessToken')) {
        return (
            <Navigate replace to={`${getEnvConfig().PUBLIC_PATH}/profile`} />
        );
    }*/

    const handleSignIn = async (
        credentials: ISignInCredentials,
        formikProps: FormikProps<ISignInCredentials>
    ) => {
        try {
            await signIn({
                ...credentials,
                email: credentials.email.toLowerCase(),
            }).unwrap();

            navigate('/pay/subscription');
            //window.location.replace(`${getEnvConfig().PUBLIC_PATH}/profile`);
        } catch (e) {
            if (e.status === 401) {
                formikProps.setErrors({
                    email: 'Неверный логин или пароль.',
                });
            }
        }
    };

    return (
        <AuthForm
            title='Приветствуем!'
            description='Авторизуйтесь для продолжения'
            meta={{
                text: 'Нет аккаунта?',
                link: '../sign-up',
                linkText: 'Создать аккаунт',
            }}
        >
            <Form
                initialValues={{ email: '', password: '' }}
                validateOnMount
                validateOnBlur
                onSubmit={handleSignIn}
            >
                <Field name='email' label='Почта'>
                    <Input autoFocus placeholder='john@example.com' />
                </Field>
                <PasswordLabel>
                    <div>Пароль</div>
                    <Link to={'../recover'}>Забыли пароль?</Link>
                </PasswordLabel>
                <Field name='password'>
                    <Input.Password />
                </Field>
            </Form>
        </AuthForm>
    );
};

const PasswordLabel = styled.div`
    margin-top: 12px;
    margin-bottom: 8px;
    display: flex;
    justify-content: space-between;
`;
