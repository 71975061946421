import { Card, Dropdown as AntDropdown, DropDownProps } from 'antd';
import React, { Fragment } from 'react';
import styled from 'styled-components';

type TProps = {
    readonly dropdownContent: () => React.ReactNode;
} & DropDownProps;

export const Dropdown: React.FC<TProps> = props => {
    const { dropdownContent, children, ...dropDownProps } = props;
    return (
        <Fragment>
            <AntDropdown
                dropdownRender={() => (
                    <StyledDropDownCard>{dropdownContent()}</StyledDropDownCard>
                )}
                {...dropDownProps}
            >
                <StyledActivator>{children}</StyledActivator>
            </AntDropdown>
        </Fragment>
    );
};

const StyledActivator = styled.div`
    cursor: pointer;
`;

const StyledDropDownCard = styled(Card)`
    .ant-card-body {
        padding: 0px 8px;
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    button {
        text-align: left;
    }
`;
