import React, { FC, useState } from 'react';
import { Button, Tooltip, Typography } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import styled from 'styled-components';

interface IProps {
    text: string;
    ellipsis?: boolean;
}

export const CopyWithTooltip: FC<IProps> = props => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const handleTooltip = () => {
        setIsOpen(true);
        setTimeout(() => {
            setIsOpen(false);
        }, 1000);
    };
    return (
        <Tooltip
            open={isOpen}
            onOpenChange={handleTooltip}
            title='Скопировано'
            trigger={['click']}
        >
            <StyledButton
                onClick={() => {
                    navigator.clipboard.writeText(props.text);
                }}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: 0,
                    width: props?.ellipsis ? '100%' : 'auto',
                }}
            >
                {props?.ellipsis ? (
                    <EllipsisSpan>{props.text}</EllipsisSpan>
                ) : (
                    <>{props.text}</>
                )}
                <CopyOutlined style={{ marginInlineStart: 4 }} />
            </StyledButton>
        </Tooltip>
    );
};

const EllipsisSpan = styled.span`
    width: 70%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-break: keep-all;
`;

const StyledButton = styled(Typography.Link)`
    white-space: normal;
    word-break: break-all;
    text-align: left;
`;
