import { combineReducers } from 'redux';

import { authApi } from 'features/auth/services';
import { userApi } from 'features/user/services';
import { subscriptionsApi } from 'features/subscriptions/services';
import { communitiesAPi } from 'features/communities/services';
import { publicationsApi } from 'features/courses/services/publications';
import { adminReducers } from 'features/admin/services';
import { analyticsApi } from 'features/analytics/services';
import { analyticsAdapterApi } from 'features/analytics/services/adapter';

import { monetizationReducers } from '../features/monetization/services';
import { webinarsApi } from '../features/courses/services/webinars';
import { authorApi } from '../features/courses/services/author';
import { analyticsReadersApi } from '../features/courses/components/Analytics/services/readers';
import { recommendationApi } from '../features/courses/components/Recommendation/services';
import { analyticsPostsApi } from '../features/courses/components/Analytics/services/posts';
import { analyticsWebinarsApi } from '../features/courses/components/Analytics/services/webinars';
import { productsApi } from '../features/shops/services/products';
import { shopsApi } from '../features/shops/services/shops';
import { productsCategoriesApi } from '../features/shops/services/categories';
import { legalEntityApi } from '../features/user/services/legalEntity';
import { promocodeApi } from '../features/shops/services/promocode';
import { ordersApi } from '../features/shops/services/orders';
import { bannersApi } from '../features/shops/services/banners';
import { shopStoreReducers } from '../features/shops/store';
import { paymentSettingsApi } from '../features/shops/services/paymentSettings';

const rootReducer = combineReducers({
    [authApi.reducerPath]: authApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [subscriptionsApi.reducerPath]: subscriptionsApi.reducer,
    [communitiesAPi.reducerPath]: communitiesAPi.reducer,
    [publicationsApi.reducerPath]: publicationsApi.reducer,
    [analyticsApi.reducerPath]: analyticsApi.reducer,
    [analyticsAdapterApi.reducerPath]: analyticsAdapterApi.reducer,
    [webinarsApi.reducerPath]: webinarsApi.reducer,
    [authorApi.reducerPath]: authorApi.reducer,
    [analyticsReadersApi.reducerPath]: analyticsReadersApi.reducer,
    [analyticsPostsApi.reducerPath]: analyticsPostsApi.reducer,
    [recommendationApi.reducerPath]: recommendationApi.reducer,
    [analyticsWebinarsApi.reducerPath]: analyticsWebinarsApi.reducer,
    ...adminReducers,
    ...monetizationReducers,
    [productsApi.reducerPath]: productsApi.reducer,
    [shopsApi.reducerPath]: shopsApi.reducer,
    [productsCategoriesApi.reducerPath]: productsCategoriesApi.reducer,
    [legalEntityApi.reducerPath]: legalEntityApi.reducer,
    [promocodeApi.reducerPath]: promocodeApi.reducer,
    [ordersApi.reducerPath]: ordersApi.reducer,
    [bannersApi.reducerPath]: bannersApi.reducer,
    [paymentSettingsApi.reducerPath]: paymentSettingsApi.reducer,
    ...shopStoreReducers,
});

export default rootReducer;
