import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import qs from 'query-string';

import getEnvConfig from 'utils/config/env';

import type { IEventQueryParams, IEvent, IChatMember } from '../interfaces';
import { PaginatedResponse } from '../interfaces/PaginatedResponse';
import {
    IUpdateOnboardingRequest,
    IOnboarding,
} from '../interfaces/onboarding.interface';
import {
    IAntispam,
    IAntispamCreateRequest,
    IAntispamUpdateRequest,
} from '../interfaces/antispam.interface';
import { QueryRules, Rule } from '../interfaces/rules.interface';
import { RulesDict } from '../interfaces/conditional-rules.interface';
import {
    ICreateBehavioralRequest,
    IBehavioral,
    IUpdateBehaviorRequest,
} from '../interfaces/behavioral.interface';
import { IQueryCategory } from '../interfaces/category.interface';
import {
    IReputation,
    IUpdateReputationRequest,
} from '../interfaces/reputation.interface';
import { scenariosType } from './scenariosType';
import { ICommunity } from '../interfaces';

export const communitiesAPi = createApi({
    reducerPath: 'communities',
    baseQuery: fetchBaseQuery({
        baseUrl: getEnvConfig().env.API_URL,
        prepareHeaders: headers => {
            const token = localStorage.getItem('accessToken');
            headers.append('Authorization', `Bearer ${token}`);
            return headers;
        },
    }),
    tagTypes: [
        'Community',
        'ChatTags',
        'Members',
        'Rules',
        'Knowledge',
        'Question',
        'QuestionCategory',
        'QuestionAi',
        'Scenarios',
        'Chats',
    ],
    endpoints: builder => ({
        readRules: builder.query<
            PaginatedResponse<Rule>,
            QueryRules | Record<string, any>
        >({
            query: args => {
                const queryArgs = qs.stringify(args, {
                    arrayFormat: 'bracket-separator',
                    skipEmptyString: true,
                    skipNull: true,
                });
                return {
                    url: `all_user_rules?${queryArgs}`,
                    method: 'GET',
                };
            },

            providesTags: ['Rules'],
            transformResponse: res => ({
                items: res?.data?.rules,
                total: res?.counts?.all,
            }),
        }),
        updateRule: builder.mutation<any, QueryRules & any>({
            query: ({ id, type, ...payload }) => {
                return {
                    url: `${
                        getEnvConfig().ADAPTER_API
                    }/rules/${id}?type=${type}`,
                    method: 'PUT',
                    body: payload,
                };
            },
            invalidatesTags: ['Rules'],
        }),
        deleteRule: builder.mutation({
            query: params => {
                return scenariosType(params);
            },
            invalidatesTags: ['Rules'],
        }),
        createConditionalRule: builder.mutation<
            any,
            { rules: string; communities_ids: Array<number> }
        >({
            query: payload => ({
                url: `${getEnvConfig().ADAPTER_API}/rules/conditional`,
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: ['Rules'],
        }),
        readConditionalRulesDict: builder.query<RulesDict, void>({
            query: () => ({
                url: `${getEnvConfig().ADAPTER_API}/rules/dict/conditional`,
            }),
            providesTags: ['Rules'],
        }),
        createAntispam: builder.mutation<any, IAntispamCreateRequest>({
            query: body => ({
                url: `/antispam`,
                method: 'POST',
                body,
            }),
            transformResponse: (response: any) => {
                return response;
            },
            invalidatesTags: ['Rules'],
        }),
        readAntispam: builder.query<IAntispam, any>({
            query: id => ({
                url: `/antispam/${id}`,
                method: 'GET',
            }),
            transformResponse: (response: any) => {
                return response?.data;
            },
            providesTags: ['Rules'],
        }),
        updateAntispam: builder.mutation<any, IAntispamUpdateRequest>({
            query: params => ({
                url: `/antispam/${params.uuid}`,
                method: 'PUT',
                body: params.body,
            }),
            transformResponse: (response: any) => {
                return response;
            },
            invalidatesTags: ['Rules'],
        }),
        createOnboarding: builder.mutation<any, FormData>({
            query: body => ({
                url: `/onboarding`,
                method: 'POST',
                body,
            }),
            transformResponse: (response: any) => {
                return response;
            },
            invalidatesTags: ['Rules'],
        }),
        readOnboarding: builder.query<IOnboarding, any>({
            query: id => ({
                url: `/onboarding/${id}`,
                method: 'GET',
            }),
            transformResponse: (response: any) => {
                return response?.data;
            },
            providesTags: ['Rules'],
        }),
        updateOnboarding: builder.mutation<IUpdateOnboardingRequest, any>({
            query: body => ({
                url: `/onboarding/edit`,
                method: 'POST',
                body,
            }),
            transformResponse: (response: any) => {
                return response?.data;
            },
            invalidatesTags: ['Rules'],
        }),
        createReputation: builder.mutation<any, FormData>({
            query: body => ({
                url: `/chats/rate`,
                method: 'POST',
                body,
            }),
            transformResponse: (response: any) => {
                return response;
            },
            invalidatesTags: ['Rules'],
        }),
        readReputation: builder.query<IReputation, any>({
            query: id => ({
                url: `/chats/rate/${id}`,
                method: 'GET',
            }),
            transformResponse: (response: any) => {
                return response?.data;
            },
            providesTags: ['Rules'],
        }),
        updateReputation: builder.mutation<any, IUpdateReputationRequest>({
            query: params => ({
                url: `/chats/rate/${params.uuid}`,
                method: 'POST',
                body: params.body,
            }),
            transformResponse: (response: any) => {
                return response?.data;
            },
            invalidatesTags: ['Rules'],
        }),
        createBehaviorRule: builder.mutation<
            ICreateBehavioralRequest,
            FormData
        >({
            query: body => ({
                url: `/chats/rules`,
                method: 'POST',
                body,
            }),
            transformResponse: (response: any) => {
                return response;
            },
            invalidatesTags: ['Rules'],
        }),
        readBehaviorRule: builder.query<IBehavioral, any>({
            query: id => ({
                url: `/chats/rules/${id}`,
                method: 'GET',
            }),
            transformResponse: (response: any) => {
                return response.data;
            },
            providesTags: ['Rules'],
        }),
        updateBehaviorRule: builder.mutation<any, IUpdateBehaviorRequest>({
            query: params => ({
                url: `/chats/rules/edit/${params.uuid}`,
                method: 'POST',
                body: params.body,
            }),
            transformResponse: (response: any) => {
                return response;
            },
            invalidatesTags: ['Rules'],
        }),

        fetchEvents: builder.query<
            PaginatedResponse<IEvent>,
            IEventQueryParams
        >({
            query: params => {
                const query = qs.stringify(params, {
                    skipNull: true,
                    skipEmptyString: true,
                    arrayFormat: 'bracket-separator',
                });
                return {
                    url: `/user/bot/action-log/filter?${query}`,
                };
            },
            transformResponse: (
                response: { data: IEvent[] },
                meta
            ): PaginatedResponse<IEvent> => {
                return {
                    items: response.data,
                    total: Number(meta.response.headers.get('Items-Count')),
                };
            },
        }),
        readEventTypes: builder.query<Array<any>, void>({
            query: () => {
                return {
                    url: `/event-types`,
                };
            },
        }),

        readChatMembers: builder.query<PaginatedResponse<IChatMember>, any>({
            query: queryArgs => {
                const query = qs.stringify(queryArgs, {
                    skipNull: true,
                    skipEmptyString: true,
                    arrayFormat: 'bracket-separator',
                });
                return {
                    url: `/user/community-users?${query}`,
                };
            },
            transformResponse: (res: any, meta) => {
                return {
                    items: res?.data || [],
                    total:
                        Number(meta.response.headers.get('items-count')) || 0,
                };
            },
            providesTags: ['Members'],
        }),
        removeUserFromChats: builder.mutation<void, number>({
            query: telegram_id => ({
                url: `/user/community-users/detach_all`,
                method: 'DELETE',
                body: { telegram_id },
            }),
            invalidatesTags: ['Members'],
        }),
        removeUserFromChat: builder.mutation<
            void,
            { community_id: number; telegram_id: number }
        >({
            query: ({ telegram_id, community_id }) => ({
                url: `/user/community-users/detach`,
                method: 'DELETE',
                body: { telegram_id, community_id },
            }),
            invalidatesTags: ['Members'],
        }),
        addMemberToList: builder.mutation<void, any>({
            query: args => ({
                url: `/user/community-users/add_to_list`,
                method: 'PUT',
                body: args,
            }),
            invalidatesTags: ['Members'],
        }),
        removeMemberFromList: builder.mutation({
            query: payload => {
                return {
                    url: `/user/community-users/remove_from_list`,
                    method: 'DELETE',
                    body: payload,
                };
            },
            invalidatesTags: ['Members'],
        }),

        readKnowledge: builder.query<any, any>({
            query: queryArgs => {
                const query = qs.stringify(queryArgs, {
                    skipNull: true,
                    skipEmptyString: true,
                    arrayFormat: 'bracket-separator',
                });
                return {
                    url: `/knowledge`,
                };
            },
            transformResponse: (response: { data: [] }) => {
                return {
                    items: response.data,
                    total: Number(response?.data?.length),
                };
            },
            providesTags: ['Knowledge'],
        }),
        readKnowledgeById: builder.query<any, any>({
            query: id => {
                return {
                    url: `/knowledge/${id}`,
                };
            },
            transformResponse: (response: any) => {
                return {
                    items: response?.data,
                };
            },
            providesTags: ['Knowledge'],
        }),
        readKnowledgeByHash: builder.query<any, any>({
            query: hash => {
                return {
                    url: `/public/knowledge/${hash}`,
                };
            },
            transformResponse: (response: any) => {
                return {
                    items: response.data,
                };
            },
            providesTags: ['Knowledge'],
        }),
        createKnowledge: builder.mutation<any, any>({
            query: body => ({
                url: `/knowledge`,
                method: 'POST',
                body,
            }),
            transformResponse: (response: any) => {
                return response;
            },
            invalidatesTags: ['Knowledge'],
        }),
        createKnowledgeBindCommunities: builder.mutation({
            query: body => ({
                url: `/knowledge/bind-communities`,
                method: 'POST',
                body,
            }),
            transformResponse: (response: any) => {
                return response;
            },
            invalidatesTags: ['Knowledge'],
        }),

        updateKnowledge: builder.mutation<any, any>({
            query: payload => ({
                url: `/knowledge/${payload.knowledge_id}`,
                method: 'PUT',
                body: payload,
            }),
            transformResponse: (response: any) => {
                return response;
            },
            invalidatesTags: ['Knowledge'],
        }),

        deleteKnowledge: builder.mutation({
            query: id => {
                return {
                    url: `/knowledge/${id}`,
                    method: 'DELETE',
                    body: { id },
                };
            },
            invalidatesTags: ['Knowledge'],
        }),
        createQuestion: builder.mutation<any, any>({
            query: body => ({
                url: `/question`,
                method: 'POST',
                body,
            }),
            transformResponse: (response: any) => {
                return response;
            },
            invalidatesTags: ['Question', 'Knowledge', 'QuestionCategory'],
        }),
        readQuestionById: builder.query<any, any>({
            query: id => ({
                url: `/question/${id}`,
                method: 'GET',
            }),
            transformResponse: (response: { data: [] }) => {
                return {
                    items: response.data,
                };
            },
            providesTags: ['Question'],
        }),
        createQuestionAi: builder.mutation<any, any>({
            query: ({ id, body }) => ({
                url: `/question/ai`,
                method: 'POST',
                body: body,
            }),
            transformResponse: (response: any) => {
                return response;
            },
            invalidatesTags: ['QuestionAi'],
        }),
        readQuestionAI: builder.query<any, any>({
            query: () => ({
                url: `/question/list`,
                method: 'GET',
            }),
            transformResponse: (response: { data: [] }, meta) => {
                return {
                    items: response,
                    total: Number(meta.response.headers.get('Items-Count')),
                };
            },
            providesTags: ['QuestionAi'],
        }),
        readQuestionAIById: builder.query<any, any>({
            query: id => ({
                url: `/question/ai/${id}`,
                method: 'GET',
            }),
            transformResponse: (response: { data: [] }) => {
                return response;
            },
            providesTags: ['QuestionAi'],
        }),
        readQuestion: builder.query<any, any>({
            query: id => ({
                url: `/question/list/${id}`,
                method: 'GET',
            }),
            transformResponse: (response: { data: [] }, meta) => {
                return {
                    items: response.data,
                    total: Number(response?.data?.length),
                };
            },
            providesTags: ['Question'],
        }),
        updateQuestion: builder.mutation<IQueryCategory, any>({
            query: ({ id, body }) => {
                return {
                    url: `/question/${id}`,
                    method: 'POST',
                    body: body,
                };
            },
            invalidatesTags: ['Question', 'Knowledge'],
        }),
        deleteQuestion: builder.mutation({
            query: id => {
                return {
                    url: `/question/${id}`,
                    method: 'DELETE',
                    body: { id },
                };
            },
            invalidatesTags: ['Question', 'Knowledge'],
        }),
        deleteQuestionAi: builder.mutation({
            query: id => {
                return {
                    url: `/question/ai/${id}`,
                    method: 'DELETE',
                    body: { id },
                };
            },
            invalidatesTags: ['QuestionAi'],
        }),

        readCategory: builder.query<any, any>({
            query: params => {
                const query = qs.stringify(params, {
                    skipNull: true,
                    skipEmptyString: true,
                    arrayFormat: 'bracket-separator',
                });
                return {
                    url: `/question-category?${query}`,
                };
            },
            transformResponse: (response: { data: [] }) => {
                return {
                    items: response.data,
                    total: Number(response.data?.length),
                };
            },
            providesTags: ['QuestionCategory'],
        }),
        readCategoryById: builder.query<any, any>({
            query: id => ({
                url: `/question-category/${id}`,
                method: 'GET',
            }),
            transformResponse: (response: {
                data: [];
            }): PaginatedResponse<IEvent> => {
                return {
                    items: response.data,
                    total: Number(response?.data?.length),
                };
            },
            providesTags: ['QuestionCategory'],
        }),
        createCategory: builder.mutation<any, any>({
            query: body => ({
                url: `/question-category`,
                method: 'POST',
                body,
            }),
            transformResponse: (response: []) => {
                return response;
            },
            invalidatesTags: ['QuestionCategory'],
        }),
        updateCategory: builder.mutation<IQueryCategory, any>({
            query: ({ id, ...payload }) => {
                return {
                    url: `/question-category/${id}`,
                    method: 'PUT',
                    body: payload,
                };
            },
            invalidatesTags: ['QuestionCategory'],
        }),
        deleteCategory: builder.mutation({
            query: id => {
                return {
                    url: `/question-category/${id}`,
                    method: 'DELETE',
                    body: { id },
                };
            },
            invalidatesTags: ['QuestionCategory'],
        }),

        readChats: builder.query<PaginatedResponse<ICommunity>, any>({
            query: params => {
                const query = qs.stringify(params, {
                    skipNull: true,
                    skipEmptyString: true,
                    arrayFormat: 'bracket',
                });
                return { url: `/user/chats?${query}` };
            },
            transformResponse: (data: any) => {
                return {
                    items: data.data,
                    total: data.data.length,
                };
            },
            providesTags: ['Chats'],
        }),

        readChatTags: builder.query<
            Array<{ id: number; user_id: number; name: string }>,
            void
        >({
            query: () => ({
                url: '/chats/tags',
                method: 'GET',
            }),
            transformResponse: (data: {
                data: Array<{ id: number; user_id: number; name: string }>;
            }) => {
                return data.data;
            },
            providesTags: ['ChatTags'],
        }),

        createChatTags: builder.mutation<
            void,
            { tags: string[]; community_id: number }
        >({
            query: ({ community_id, tags }) => ({
                url: '/chat-tags/attach',
                method: 'POST',
                body: {
                    tags,
                    community_id,
                },
            }),
            invalidatesTags: ['Chats', 'ChatTags'],
        }),
    }),
});

export const {
    useReadChatsQuery,
    useReadChatTagsQuery,
    useCreateChatTagsMutation,
    /**
     * Events
     */
    useReadEventTypesQuery,
    useFetchEventsQuery,
    /**
     * Chat members
     */
    useRemoveUserFromChatsMutation,
    useRemoveUserFromChatMutation,
    useAddMemberToListMutation,
    useRemoveMemberFromListMutation,
    /**
     * Rules
     */
    useReadRulesQuery,
    useUpdateRuleMutation,
    useDeleteRuleMutation,
    useCreateBehaviorRuleMutation,
    useCreateOnboardingMutation,
    useCreateAntispamMutation,
    useCreateConditionalRuleMutation,
    useReadConditionalRulesDictQuery,
    useReadOnboardingQuery,
    useReadChatMembersQuery,
    useUpdateOnboardingMutation,
    useReadAntispamQuery,
    useUpdateAntispamMutation,
    useUpdateBehaviorRuleMutation,
    useReadBehaviorRuleQuery,
    /*
     * Knowledge Base
     * */
    useReadKnowledgeQuery,
    useReadKnowledgeByIdQuery,
    useReadKnowledgeByHashQuery,
    useCreateKnowledgeMutation,
    useUpdateKnowledgeMutation,
    useDeleteKnowledgeMutation,
    useCreateKnowledgeBindCommunitiesMutation,
    useReadQuestionAIByIdQuery,
    /*
     * Question
     * */
    useCreateQuestionMutation,
    useReadQuestionQuery,
    useDeleteQuestionMutation,
    useDeleteQuestionAiMutation,
    useUpdateQuestionMutation,
    useReadQuestionByIdQuery,
    useReadQuestionAIQuery,
    useCreateQuestionAiMutation,
    /* Question Category
     * */
    useReadCategoryQuery,
    useReadCategoryByIdQuery,
    useCreateCategoryMutation,
    useUpdateCategoryMutation,
    useDeleteCategoryMutation,
    useCreateReputationMutation,
    useReadReputationQuery,
    useUpdateReputationMutation,
} = communitiesAPi;
