import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

import getEnvConfig from 'utils/config/env';
import { IBasePaginationListQueryFilters } from 'features/admin/services/usersTab.service';

export const feedbackTabService = createApi({
    reducerPath: 'adminFeedbackTab',
    baseQuery: fetchBaseQuery({
        baseUrl: getEnvConfig().env.API_URL + '/manager',
        prepareHeaders: headers => {
            const token = localStorage.getItem('accessToken');
            headers.set('Authorization', `Bearer ${token}`);
            return headers;
        },
    }),
    tagTypes: ['feedbacks'],
    endpoints: build => ({
        feedbackList: build.query<any, IBasePaginationListQueryFilters>({
            query: params => ({
                method: 'GET',
                url: '/feed-backs',
                params,
            }),
            transformResponse: (res: any, meta) => {
                return {
                    list: res?.data || [],
                    total:
                        Number(meta.response.headers.get('items-count')) || 0,
                };
            },
            providesTags: ['feedbacks'],
        }),
        closeFeedback: build.mutation<any, any>({
            query: (id: string) => ({
                url: `/feed-back/close/${id}`,
            }),
            invalidatesTags: ['feedbacks'],
        }),
        getFeedbackById: build.query({
            query: (id: string) => ({
                url: `/feed-back/show/${id}`,
            }),
            transformResponse: (res: { data: any }) => res?.data,
        }),
        answerFeedback: build.mutation({
            query: body => ({
                method: 'POST',
                url: 'feed-back/answer',
                body,
            }),
            invalidatesTags: ['feedbacks'],
        }),
    }),
});

export const {
    useCloseFeedbackMutation,
    useGetFeedbackByIdQuery,
    useFeedbackListQuery,
    useAnswerFeedbackMutation,
} = feedbackTabService;
