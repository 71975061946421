import React, { useEffect, useState } from 'react';
import { Flex, Input } from 'antd';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Page } from '../../../../shared/ui/Page';
import { ShopList } from '../../views/Shops/ShopList';
import { ShopCreateContainer } from './ShopCreateContainer';
import { useLazyReadShopsQuery } from '../../services/shops';
import { shopsFilters } from './utils/shopList.filters';
import { useProfileData } from '../../../../hooks/useProfileData';
import { Shop } from '../../interfaces/shop.interface';
import { OnboardingCard } from '../../views/Onboarding/OnboardingCard';
import { SuccessModal } from '../../../subscriptions/components/Success';
import { useToggle } from '../../../../hooks/useToggle';
import { ExampleShopCard } from '../../views/Shops/ExampleShopCard';

export const ShopsContainer = () => {
    const [getShops, { data: shops, isFetching, isLoading }] =
        useLazyReadShopsQuery();
    const { user } = useProfileData();
    const navigate = useNavigate();

    const [filters, setFilters] = useState<Shop>(shopsFilters);
    const { open, setOpen } = useToggle(false);

    const [searchParams] = useSearchParams();

    useEffect(() => {
        if (user) {
            if (user.subscription?.subscription_id !== 3) {
                return navigate('/pay/subscription');
            }
        }
    }, [user]);

    useEffect(() => {
        if (searchParams) {
            const paid = +searchParams.get('paid') === 1;
            if (paid) {
                return setOpen(true);
            }
        }
    }, [searchParams]);

    const isTelegramAccount = user?.telegram_accounts?.length > 0;

    useEffect(() => {
        if (filters) {
            getShops(filters, true);
        }
    }, [filters]);

    const loading = isLoading || isFetching;
    const isShops = !!shops?.items;

    return (
        <>
            <Page title={'Магазины'}>
                <SuccessModal open={open} setOpen={setOpen} />
                <OnboardingCard />
                <Flex
                    style={{ marginBottom: '1.5rem' }}
                    justify={
                        isTelegramAccount ? 'space-between' : 'space-between'
                    }
                    align={'center'}
                >
                    {isTelegramAccount && (
                        <>
                            <div>
                                <Input
                                    allowClear
                                    onChange={e =>
                                        setFilters({
                                            ...filters,
                                            name: e.target.value,
                                        })
                                    }
                                    placeholder={'Поиск магазина'}
                                />
                            </div>

                            <ShopCreateContainer loading={loading} />
                        </>
                    )}
                </Flex>

                {!isTelegramAccount && isShops && <ExampleShopCard />}

                {!isTelegramAccount && !isShops ? null : (
                    <ShopList
                        filters={filters}
                        setFilters={setFilters}
                        loading={loading}
                        data={shops}
                    />
                )}
            </Page>
        </>
    );
};
