import { App, Button, Flex, Form, Input } from 'antd';
import React, { Fragment, useEffect, useState } from 'react';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';

import { useSignUpMutation } from 'features/auth/services';

import getEnvConfig from '../../../../utils/config/env';
import { AuthForm } from '../../components';

const getDescription = (reason?: string) => {
    switch (reason) {
        case 'price': {
            return 'Зарегистрируйтесь, чтобы приобрести платную версию продукта';
        }
        case 'free': {
            return 'Зарегистрируйтесь, чтобы получить бесплатную версию продукта';
        }
        default: {
            return 'Пароль пришлём на почту';
        }
    }
};

const getRedirectUrl = (reason?: string, postId?: string) => {
    /*switch (reason) {
        case 'price': {
            return `${getEnvConfig().PUBLIC_PATH}/profile?reason=price`;
        }
        case 'free': {
            return `${getEnvConfig().PUBLIC_PATH}/profile?reason=free`;
        }
        case 'guestPostId': {
            return `${getEnvConfig().COURSES_PATH}/member/post/${postId}`;
        }
        default: {
            return `${getEnvConfig().PUBLIC_PATH}/profile`;
        }
    }*/
};

export const SignUp: React.FC = () => {
    const [submittable, setSubmittable] = useState<boolean>(false);
    const [form] = Form.useForm();

    const values = Form.useWatch([], form);

    useEffect(() => {
        form.validateFields({ validateOnly: true })
            .then(() => setSubmittable(true))
            .catch(() => setSubmittable(false));
    }, [form, values]);

    const { notification } = App.useApp();
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const [signUp, { isLoading, isError, error }] = useSignUpMutation();

    useEffect(() => {
        if (isError && error) {
            {
                Object.entries(error?.data?.errors).map(([key, messages]) => (
                    <Fragment key={key}>
                        {messages.map((message, index) =>
                            notification.error({
                                message: message,
                            })
                        )}
                    </Fragment>
                ));
            }
        }
    }, [isError, error]);

    /*if (localStorage.getItem('guestPostId')) {
        return (
            <Navigate
                replace
                to={getRedirectUrl(
                    'guestPostId',
                    localStorage.getItem('guestPostId')
                )}
            />
        );
    }*/

    /*if (localStorage.getItem('accessToken')) {
        return (
            <Navigate replace to={getRedirectUrl(searchParams.get('reason'))} />
        );
    }*/

    const onSubmit = async () => {
        if (values.name?.length >= 101) {
            return notification.error({
                message: `Имя не может быть больше 100 символов`,
            });
        }

        try {
            const data = {
                email: values.email.trim().toLowerCase(),
                name: values.name.trim(),
                phone: `${7}${values.phone}`,
            };

            const response = await signUp(data);

            if (response?.data?.data?.token) {
                return navigate('/pay/subscription');
            }

            //await signUp(data).unwrap();
            const postUuid = sessionStorage.getItem('guestPostId');

            /* if (postUuid) {
                window.location.replace(
                    getRedirectUrl('guestPostId', postUuid)
                );
                return sessionStorage.removeItem('guestPostId');
            } else
                window.location.replace(
                    getRedirectUrl(searchParams.get('reason'))
                );*/
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <AuthForm
            title='Регистрация'
            description={getDescription(searchParams.get('reason'))}
            meta={{
                link: '../sign-in',
                linkText: 'Войти',
                text: 'Уже зарегистрированы?',
            }}
        >
            <Form
                scrollToFirstError
                form={form}
                name='sign-up'
                colon={true}
                layout={'vertical'}
            >
                <Flex vertical gap={'1.5rem'}>
                    <Form.Item
                        validateTrigger='onBlur'
                        label='Почта'
                        name='email'
                        hasFeedback
                        rules={[
                            {
                                pattern: new RegExp(
                                    /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
                                ),
                                message: 'Это не похоже на почту',
                            },
                            {
                                required: true,
                                message: 'Укажите почту',
                            },
                        ]}
                    >
                        <Input placeholder={'ivan@domen.ru'} />
                    </Form.Item>

                    <Form.Item
                        label='Имя'
                        name='name'
                        hasFeedback
                        rules={[
                            {
                                pattern: new RegExp(
                                    /^[a-zA-Zа-яёА-ЯЁ'][a-zA-Zа-яёА-ЯЁ' ]+[a-zA-Zа-яёА-ЯЁ']?$/u
                                ),
                                message: 'Это не похоже имя',
                            },
                            {
                                required: true,
                                message: 'Укажите ваше имя',
                            },
                            {
                                max: 100,
                                message:
                                    'Имя не может быть больше 100 символов',
                            },
                            {
                                min: 2,
                                message: 'Имя не может быть короче 2 символов',
                            },
                        ]}
                    >
                        <Input min={2} max={100} placeholder={'Ivan'} />
                    </Form.Item>

                    <Form.Item
                        label='Номер телефона'
                        name='phone'
                        hasFeedback
                        rules={[
                            {
                                pattern: new RegExp(/^\d+$/),
                                message: 'Нужно указать только цифры',
                            },
                            {
                                required: true,
                                message: 'Укажите телефон',
                            },
                        ]}
                    >
                        <Input
                            maxLength={10}
                            prefix={'+7'}
                            placeholder={'9060000000'}
                        />
                    </Form.Item>
                </Flex>
            </Form>

            <p>
                Нажатием на кнопку «Зарегистрироваться» подтверждаю, что я
                ознакомлен с условиями{' '}
                <Link to={`/user_agreement`}>Пользовательского соглашения</Link>
                ,
                <Link to={`/privacy_policy`}>
                    {' '}
                    Политика обработки персональных данных и файлов Cookie
                </Link>{' '}
                и даю
                <Link to={'/personal-data-agreement'}>
                    {' '}
                    Согласие на обработку моих персональных данных
                </Link>
            </p>

            <Button
                block
                disabled={!submittable}
                loading={isLoading}
                type='primary'
                htmlType='submit'
                onClick={onSubmit}
            >
                Зарегистрироваться
            </Button>
        </AuthForm>
    );
};
