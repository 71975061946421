import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import qs from 'query-string';

import getEnvConfig from 'utils/config/env';

import { IReadersResponse, TFilters } from './interfaces';

export const analyticsReadersApi = createApi({
    reducerPath: 'analyticsReaders',
    baseQuery: fetchBaseQuery({
        baseUrl: getEnvConfig().env.API_URL,
        prepareHeaders: headers => {
            const token = localStorage.getItem('accessToken');
            headers.set('Authorization', `Bearer ${token}`);
            return headers;
        },
    }),
    tagTypes: ['AnalyticsReaders'],
    endpoints: builder => ({
        readReaders: builder.query<IReadersResponse, TFilters>({
            query: args => {
                const queryArgs = qs.stringify(args, {
                    arrayFormat: 'bracket-separator',
                    skipNull: true,
                });
                return {
                    url: `/content/analytics/readers/?${queryArgs}`,
                    method: 'GET',
                };
            },
            transformResponse: (response: { data: IReadersResponse }) => {
                return response.data;
            },
            providesTags: ['AnalyticsReaders'],
        }),
    }),
});
export const { useReadReadersQuery, useLazyReadReadersQuery } =
    analyticsReadersApi;
