import React, { FC } from 'react';
import { Tabs } from 'antd';
import { Tab } from 'rc-tabs/lib/interface';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { Page } from 'shared/ui/Page';
import getEnvConfig from 'utils/config/env';

export const MainPage: FC = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const tabs: Tab[] = [
        {
            label: 'Пользователи',
            key: 'users',
        },
        {
            label: 'Сообщества',
            key: 'communities',
        },
        {
            label: 'Платежи',
            key: 'payments',
        },
        {
            label: 'Обратная связь',
            key: 'feedback',
        },
    ];

    const onChange = (key: string) => {
        navigate(`${getEnvConfig().PUBLIC_PATH}/admin/${key}`);
    };

    const activeTabKey =
        tabs.find(t => location.pathname.includes(t.key))?.key || tabs[0].key;

    return (
        <Page title='Администрирование'>
            <Tabs activeKey={activeTabKey} items={tabs} onChange={onChange} />
            <Outlet />
        </Page>
    );
};
