import { createSlice } from '@reduxjs/toolkit';

import { arrayUnflatten } from 'utils/common/arrayUnflatten';

import { productsCategoriesApi } from '../services/categories';
import { RootState } from '../../../store';
import { CategoryState } from '../interfaces/categories.interface';

const initialState: CategoryState = {
    nestedCategoryList: [],
};

const allCatrgoriesItem = {
    id: null,
    name: 'Все категории',
    parent_id: 0,
};

export const categorySlice = createSlice({
    name: 'category',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addMatcher(
            productsCategoriesApi.endpoints.readProductCategory.matchFulfilled,
            (state, { payload }) => {
                const flatCategoryList = structuredClone(payload.items);

                const nestedCategoryList = arrayUnflatten(flatCategoryList);
                nestedCategoryList.unshift(allCatrgoriesItem);

                state.nestedCategoryList = nestedCategoryList;
            }
        );
    },
});

export const getCategoryState = (state: RootState): CategoryState =>
    state.category;

export const categoryActions = categorySlice.actions;
export const categoryReducer = categorySlice.reducer;
